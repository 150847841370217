import useMediaQuery from '@mui/material/useMediaQuery';

export type ScreenSizeNames = "Max300" | "Max600" | "Max900" | "Max1200" | "Max1600" | "MoreThan1600";

export const useScreenWidth: () => ScreenSizeNames = () => {
    const sizeMax300 = useMediaQuery('(max-width:300px)');
    const sizeMax600 = useMediaQuery('(max-width:600px)');
    const sizeMax900 = useMediaQuery('(max-width:900px)');
    const sizeMax1200 = useMediaQuery('(max-width:1200px)');
    const sizeMax1600 = useMediaQuery('(max-width:1600px)');

    if (sizeMax1600 === false)
        return "MoreThan1600";
    else if (sizeMax1200 === false)
        return "Max1600";
    else if (sizeMax900 === false)
        return "Max1200";
    else if (sizeMax600 === false)
        return "Max900";
    else if (sizeMax300 === false)
        return "Max600";
    else
        return "Max300";
};