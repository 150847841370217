/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { MetaSearchField } from "../../helpers/MetaSearchField";
import StringHelper from "../../library/helpers/StringHelper";
import CsmGenericListMultiSelectField from "../../library/components/mui/CsmGenericListMultiSelectField";
import { CsmText } from "../../library/components/mui/CsmText";
import CsmNumericField from "../../library/components/mui/CsmNumericField";
import CsmTextField from "../../library/components/mui/CsmTextField";
import CsmThreeWaySelectField from "../../library/components/mui/CsmThreeWaySelectField";
import Cell from "../../library/components/layout/Cell";
import Columns from "../../library/components/layout/Columns";
import Rows from "../../library/components/layout/Rows";
import VatanSanatciField from "./VatanSanatciField";
import VatanSesGrupMultiSelectField from "./VatanSesGrupMultiSelectField";
import VatanSezonMultiSelectField from "./VatanSezonMultiSelectField";

export interface IVatanMetaFilterEditorProps<T extends MetaSearchField> {
    metaField: T,
    setFilter: (m: T, value: any) => void,
    genelDurumHelper: GenelDurumHelper,
}

function VatanMetaFilterEditor<T extends MetaSearchField>(props: IVatanMetaFilterEditorProps<T>): JSX.Element {
    const { metaField, setFilter } = props;

    if (metaField.fieldType === "SesGruplari")
        return <Cell width={390} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <VatanSesGrupMultiSelectField
                label="Ses Grupları"
                disabled={false}
                sx={{ height: "55px", alignContent: "flex-start", justifyItems: "flex-start" }}
                style={{ marginTop: "0px" }}
                value={metaField.value ?? []}
                tumSesGruplari={metaField.possibleValues}
                onChange={e => setFilter(metaField, e.length === 0 ? undefined : e)}
                id={metaField.isim} />
        </Cell>
    else if (metaField.fieldType === "sezon")
        return <Cell width={390} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <VatanSezonMultiSelectField
                style={{ height: 55 }}
                value={metaField.value ?? []}
                label={metaField.isim}
                tumSezonlar={props.genelDurumHelper.SeciliKoroModel.tumSezonlar}
                onChange={e => setFilter(metaField, e)} />
        </Cell>
    else if (metaField.fieldType === "Tarz")
        return <Cell width={390} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <CsmGenericListMultiSelectField
                style={{ height: 55 }}
                value={metaField.value ?? []}
                label={metaField.isim}
                list={metaField.possibleValues}
                labelGetter={e => e.isim}
                valueGetter={e => e.id}
                onChange={e => setFilter(metaField, e)} />
        </Cell>
    else if (metaField.fieldType === "freeText")
        return <Cell width={190} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <CsmTextField
                label={metaField.isim}
                value={StringHelper.nullToEmpty(metaField.value)}
                onChange={e => setFilter(metaField, e)} />
        </Cell>
    else if (metaField.fieldType === "sanatci")
        return <Cell width={190} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <VatanSanatciField
                label={metaField.isim}
                genelDurumHelper={props.genelDurumHelper}
                value={StringHelper.nullToEmpty(metaField.value)}
                onChange={e => setFilter(metaField, e)} />
        </Cell>
    else if (metaField.fieldType === "itemsFromList")
        return <Cell width={390} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <CsmGenericListMultiSelectField
                style={{ height: 55 }}
                value={metaField.value ?? []}
                label={metaField.isim}
                list={metaField.possibleValues}
                labelGetter={e => e}
                valueGetter={e => e}
                onChange={e => setFilter(metaField, e)} />
        </Cell>
    else if (metaField.fieldType === "number")
        return <Cell width={190} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            {metaField.comparison === "numberRange" ?
                <Rows>
                    <CsmText note>{metaField.isim}</CsmText>
                    <Columns>
                        <CsmNumericField
                            narrow
                            label={`en az`}
                            onChange={e => setFilter(metaField, { min: e, max: metaField.value?.max })}
                            value={metaField.value?.min ?? null} />
                        <CsmNumericField
                            narrow
                            label={`en fazla`}
                            onChange={e => setFilter(metaField, { min: metaField.value?.min, max: e })}
                            value={metaField.value?.max ?? null} />
                    </Columns>
                </Rows>
                :
                <CsmNumericField
                    label={metaField.isim}
                    onChange={e => setFilter(metaField, { min: e, max: e })}
                    value={metaField.value?.min ?? null} />
            }
        </Cell>
    else if (metaField.fieldType === "stringList")
        return <Cell width={390} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <CsmGenericListMultiSelectField
                value={metaField.value ?? []}
                label={metaField.isim}
                list={metaField.possibleValues}
                labelGetter={e => e}
                valueGetter={e => e}
                onChange={e => setFilter(metaField, e)} />
        </Cell>
    else if (metaField.fieldType === "trueFalse")
        return <Cell width={190} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <CsmThreeWaySelectField
                value={metaField.value}
                label={metaField.isim}
                onChange={e => setFilter(metaField, e)}
                trueText="Evet"
                falseText="Hayır"
                emptyText=""
            />
        </Cell>
    else
        return <Cell width={390} style={{ marginLeft: "10px", marginBottom: "10px" }}>
            <CsmText>İşlenmemiş metaField.fieldType {(metaField as any).fieldType} </CsmText>
        </Cell>
}

export default VatanMetaFilterEditor;