/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
// cihan
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import appColors from "../../../app/appColors";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import { CsmText } from "../../../library/components/mui/CsmText";
import { useState } from "react";
import Cell from "../../../library/components/layout/Cell";

interface ISecmeBasvuruTopluEklemeOnizlemePopupEditorProps {
    secmeBasvuruModelList: { eklenmis: boolean, hata: string, model: SecmeBasvuruModel | null }[],
    genelDurumHelper: GenelDurumHelper,
    closer: (model: boolean) => void,
}

function SecmeBasvuruTopluEklemeOnizlemePopupEditor(props: ISecmeBasvuruTopluEklemeOnizlemePopupEditorProps) {
    const { secmeBasvuruModelList, closer, genelDurumHelper } = props;
    const [eklenenleriGoster, setEklenenleriGoster] = useState<boolean>(true);

    const eklenecek = secmeBasvuruModelList.where(e => e.hata === "").length;
    const eklenmis = secmeBasvuruModelList.where(e => e.hata.indexOf("başvurusu zaten bulunmaktadır") > -1).length;
    const hatali = secmeBasvuruModelList.where(e => e.hata !== "" && e.hata.indexOf("başvurusu zaten bulunmaktadır") === -1).length;

    return (
        <Rows height={520} style={{ display: "flex" }}>
            <Columns style={{ alignItems: "center" }}>
                <CsmText>Eklecek Kayıt: {eklenecek}, Ekleniş Kayıt: {eklenmis}, Hatalı Kayıt: {hatali}</CsmText>
                <Cell flex></Cell>
                <Switch checked={eklenenleriGoster} onChange={(e) => setEklenenleriGoster(e.target.checked)} />
                <Typography sx={{ fontSize: 12, width: "200px" }}>{eklenenleriGoster ? "Eklenenleri Göster" : "Eklenenleri Gösterme"}</Typography>
            </Columns>
            <TableContainer sx={{ flex: 1, width: "100%"}}>
                <Table size="small" sx={{ flex: 1, width: "100%" }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center", fontWeight: "bold" }} >Sıra</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Durum</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Ad</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Soyad</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Cinsiyet</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Doğum Yılı</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Eposta Adresi</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Telefon</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Meslek</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Eğitim Durumu</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Yaşadığı Şehir</TableCell>
                            <TableCell size="small" style={{ fontWeight: "bold" }}>Ses Grubu</TableCell>
                            <TableCell style={{ fontWeight: "bold", flex: 1 }}>Koro Tecrübesi</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {secmeBasvuruModelList.where(e => eklenenleriGoster === true || e.eklenmis === false)
                            .map((secmeBasvuru, i) => {
                                const eklenmis = secmeBasvuru.eklenmis === true;
                                const hataVar = secmeBasvuru.eklenmis === false && secmeBasvuru.hata !== "";
                                const mesajGoster = secmeBasvuru.hata !== "";
                                return <TableRow
                                    key={i}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                                    }}
                                    style={{ background: hataVar ? appColors.LIGHTRED : eklenmis ? appColors.SEMILIGHTYELLOW : undefined }}
                                >
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{i + 1}</TableCell>
                                    <TableCell size="small" colSpan={mesajGoster ? 5 : undefined} style={{ fontSize: "10px" }}>{!mesajGoster ? "Tamam" : secmeBasvuru.hata}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.ad}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.soyad}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.cinsiyet ? EnmCinsiyet[secmeBasvuru.model!.cinsiyet!] : ""}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.dogumYili}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.eposta}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.telefon}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.meslek}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.egitimDurumu}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.yasadigiIl}</TableCell>
                                    <TableCell size="small" style={{ fontSize: "10px" }}>{secmeBasvuru.model?.bilinenSesGrubu}</TableCell>
                                    <TableCell style={{ fontSize: "10px" }}>{secmeBasvuru.model?.koroTecrubesi}</TableCell>
                                </TableRow>
                            }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(false)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(true)} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default SecmeBasvuruTopluEklemeOnizlemePopupEditor;