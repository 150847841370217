import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

type LoadingMessageStatus = { message: string, percent: number };
export interface IApplicationState {
    isLoading: boolean,
    loadingMessages: LoadingMessageStatus[],
    error: string | null,
    genelDurumString: string | null,
}

const initialState: IApplicationState = {
    isLoading: false,
    loadingMessages: [],
    error: null,
    genelDurumString: null,
};

export const applicationStateSlice = createSlice({
    name: 'LoadingMessage',
    initialState,
    reducers: {
        showLoading: (state, action: PayloadAction<string>) => {
            const foundItem = state.loadingMessages.firstOrDefault(e => e.message === action.payload);
            if (!foundItem)
                state.loadingMessages.push({ message: action.payload, percent: 0 });
        },
        showLoadingWithPercent: (state, action: PayloadAction<LoadingMessageStatus>) => {
            const foundItem = state.loadingMessages.firstOrDefault(e => e.message === action.payload.message);
            if (foundItem)
                foundItem.percent = action.payload.percent;
            else if (action.payload.percent === 0)
                state.loadingMessages.push(action.payload);
        },
        hideLoading: (state, action: PayloadAction<string>) => {
            const items = state.loadingMessages.where(e => e.message === action.payload);
            let list = state.loadingMessages;
            for (const item of items)
                list = list.remove(item);
            state.loadingMessages = list;
        },
        sonDurumGeldi: (state, action: PayloadAction<string>) => {
            state.genelDurumString = action.payload;
        },
        sonDurumTemizlendi: (state) => {
            state.isLoading = false;
            state.loadingMessages = [];
            state.error = null;
            state.genelDurumString = null;
        }
    },
});

export const getLoadingMessage = (state: RootState) => state.loadingMessage.loadingMessages;
export const getGenelDurumString = (state: RootState) => state.loadingMessage.genelDurumString;

export const { showLoading, showLoadingWithPercent, hideLoading, sonDurumGeldi, sonDurumTemizlendi } = applicationStateSlice.actions;

export default applicationStateSlice.reducer;
