import { BusinessHelper } from "../../helpers/BusinessHelper";
import { KadroModel } from "../../models/businessModels/KadroModel";
import CsmGenericListMultiSelectField, { ICsmGenericListMultiSelectFieldProps } from "../../library/components/mui/CsmGenericListMultiSelectField";

export type IVatanKadroMultiSelectFieldProps = Omit<ICsmGenericListMultiSelectFieldProps<KadroModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumKadroList: KadroModel[] };

function VatanKadroMultiSelectField(props: IVatanKadroMultiSelectFieldProps) {
    const { tumKadroList, ...rest } = props;
    const orderedKadroList = tumKadroList.orderBy(BusinessHelper.Kadro.SortFunc);

    return (
        <CsmGenericListMultiSelectField
            {...rest}
            list={orderedKadroList}
            labelGetter={e => e.isim}
            valueGetter={e => e.id}
            isItemInactive={e => !e.aktif}
        />
    )
}

export default VatanKadroMultiSelectField;