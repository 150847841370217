import { LinearProgress, LinearProgressProps, useTheme } from "@mui/material";
import { ColorCalculationHelper } from "../../helpers/ColorCalculationHelper";

export type ICsmGradientProgressProps = Omit<LinearProgressProps, "variant"> & { height: number, width: number, vertical?: boolean }

function CsmGradientProgress(props: ICsmGradientProgressProps) {
    const { height, width, vertical, sx, style, ...rest } = props;

    const theme = useTheme();
    const colorAtPoint = ColorCalculationHelper.GetColorAtPoint(props.value, "GreenYellowRed") ?? theme.palette.grey.A400;
    
    const valueForCalculations = props.value ?? 0;

    const startPercent = (100 - valueForCalculations).toFixed();
    const middlePercent = (150 - valueForCalculations).toFixed();
    const backString = valueForCalculations <= 50 ?
        `linear-gradient(90deg, rgba(20,255,20,1) ${startPercent}%, ${colorAtPoint} 100%)` :
        `linear-gradient(90deg, rgba(20,255,20,1) ${startPercent}%, rgba(255,255,20,1) ${middlePercent}%, ${colorAtPoint} 100%)`;

    const transformString = vertical ? "translateY(42px) translateX(-42px) rotate(270deg)" : undefined;

    const heightToUse = vertical ? width : height;
    const widthToUse = vertical ? height : width;

    return <LinearProgress
        sx={{
            background: "linear-gradient(90deg, rgba(220,255,220,1) 0%, rgba(255,255,220,1) 50%, rgba(255,220,220,1) 100%)",
            'span': {
                //                    background: "rgba(0,0,0, 1)",
                background: backString,
                color: undefined,
            },
            ...sx
        }}
        variant="determinate"
        style={{ transform: transformString, width: widthToUse, height: heightToUse, borderRadius: heightToUse / 2, ...style }}
        {...rest}
    />

}

export default CsmGradientProgress;