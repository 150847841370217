import Columns, { ColumnsCSSProperties } from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";
import { CalismaModel } from "../../models/businessModels/CalismaModel";
import appColors from "../../app/appColors";
import { Button } from "@mui/material";
import Rows from "../../library/components/layout/Rows";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import DateTimeToStringHelper from "../../library/helpers/DateTimeToStringHelper";

interface ICalismaAvatarListControlProps {
    calismalar: CalismaModel[],
    onClick?: (calisma: CalismaModel) => void,
    style?: ColumnsCSSProperties,
    genelDurumHelper: GenelDurumHelper
}

function CalismaAvatarListControl(props: ICalismaAvatarListControlProps) {
    const getInnerContent = (calisma: CalismaModel) => {
        return (
            <div key={calisma.id} style={{ borderRadius: 5, width: 165, padding: 10, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <CsmText style={{ textAlign: "center", fontSize: 13 }}>{DateTimeToStringHelper.toMonthDayAndYearAndDayOfWeekString(calisma.tarihBaslangic, props.genelDurumHelper.translate)}</CsmText>
                <CsmText style={{ textAlign: "center", fontSize: 11 }} note>{calisma.icerik}</CsmText>
            </div>
        );
    }

    const getButtonContent = (calisma: CalismaModel) => {
        return (
            <div key={calisma.id} style={{ borderRadius: 5, width: 187, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <Button fullWidth key={calisma.id} variant="text" onClick={() => props.onClick!(calisma)} sx={{ padding: "10px", textTransform: "none" }}>
                    <Rows>
                        <CsmText style={{ textAlign: "center", fontSize: 13 }}>{DateTimeToStringHelper.toMonthDayAndYearAndDayOfWeekString(calisma.tarihBaslangic, props.genelDurumHelper.translate)}</CsmText>
                        <CsmText style={{ textAlign: "center", fontSize: 11 }} note>{calisma.icerik}</CsmText>
                    </Rows>
                </Button>
            </div>
        );
    }

    return (
        <Columns wrap style={props.style}>
            {props.calismalar.map(item => props.onClick ? getButtonContent(item) : getInnerContent(item))}
        </Columns>
    )
}

export default CalismaAvatarListControl;