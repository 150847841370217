// AutoGenerated
import { EnmYetki } from "../enums/EnmYetki"

export class RolModel {
    isim!: string;
    ontanimli!: boolean;
    yetkiList!: EnmYetki[];
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}