import CssType from "csstype";
import React, { CSSProperties } from 'react';
import { Button, ButtonPropsColorOverrides, ListItem, useTheme } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import Columns from '../layout/Columns';
import Cell from "../layout/Cell";

export interface ICustomSwipeButton {
    icon: JSX.Element,
    text?: string,
    color: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>
    onClick?: () => void,
    disabled?: boolean,
}

interface ICsmSwipableListItemProps {
    children?: React.ReactElement | React.ReactNode,
    itemDividerText?: string,
    hasLightBottomBorder?: boolean,
    leftButtons?: ICustomSwipeButton[],
    rightButtons?: ICustomSwipeButton[],
    seperatorBorderColor?: string,
    selectButton?: boolean,
    style?: CSSProperties,
    width?: CssType.Property.Width<string | number>,
}

export function CsmSwipableListItem(props: ICsmSwipableListItemProps) {
    const theme = useTheme();

    const generateButton = (item: ICustomSwipeButton, i: number) => {
        return <Button key={i} disabled={item.disabled} variant="text" title={item.text} color={item.color} style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => item.onClick!()}>
            {item.icon}
        </Button>;
    };

    const leftButtonContent = props.leftButtons ? props.leftButtons.map(generateButton) : null;
    const rightButtonContent = props.rightButtons ? props.rightButtons.map(generateButton) : null;

    if (props.itemDividerText) {
        return <ListItem divider style={{ fontWeight: "bold", marginTop: "10px", ...props.style }}>
            {leftButtonContent}
            {props.itemDividerText}
            <Cell flex></Cell>
            {rightButtonContent}
        </ListItem>;
    }

    const listItemAddStyle: React.CSSProperties =
    {
        borderBottom: props.hasLightBottomBorder ? ("1px solid " + theme.palette.grey.A400) : undefined,
        width: props.width,
    };

    return (
        <ListItem dense style={{ ...listItemAddStyle, ...props.style }}>
            <Columns flex>
                {leftButtonContent}
                {props.children}
                {rightButtonContent}
            </Columns>
        </ListItem>
    );
}