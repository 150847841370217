// AutoGenerated
export class GeriIzlemeAraRequestModel {
    constructor(sonDakika: number | null, koroModelId: string | null, kullaniciAdiIceren: string | null, aciklamaIceren: string | null) {
        this.sonDakika = sonDakika;
        this.koroModelId = koroModelId;
        this.kullaniciAdiIceren = kullaniciAdiIceren;
        this.aciklamaIceren = aciklamaIceren;
    }

    sonDakika: number | null;
    koroModelId: string | null;
    kullaniciAdiIceren: string | null;
    aciklamaIceren: string | null;
}