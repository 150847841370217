import { CSSProperties, ReactNode } from "react";
import { Typography, useTheme } from "@mui/material";

interface IMyTextProps {
    children?: ReactNode,
    style?: CSSProperties,
    small?: boolean,
    verySmall?: boolean,
    note?: boolean,
    smallNote?: boolean,
    middleSmall?: boolean,
    italic?: boolean,
    lightColor?: boolean,
    bold?: boolean,
    header?: boolean,
    hasLightBottomBorder?: boolean,
    hasLightTopBorder?: boolean,
    listItem?: boolean,
}

export function CsmText(props: IMyTextProps) {
    const { listItem, hasLightTopBorder, hasLightBottomBorder, bold, verySmall, small, italic, lightColor, middleSmall, smallNote, note, header, style, children } = props;

    const theme = useTheme();

    const addTextStyle: CSSProperties = { ...style };

    if (note) {
        addTextStyle.fontSize = 14;
        addTextStyle.color = theme.palette.text.secondary;
        addTextStyle.fontWeight = "200";
    }

    if (smallNote) {
        addTextStyle.fontSize = 12;
        addTextStyle.color = theme.palette.text.secondary;
        addTextStyle.fontWeight = "200";
    }
    else if (middleSmall) {
        addTextStyle.fontSize = 12;
        addTextStyle.fontWeight = "200";
    }
    else if (small) {
        addTextStyle.fontSize = 11;
        addTextStyle.fontWeight = "200";
    } else if (verySmall) {
        addTextStyle.fontSize = 10;
        addTextStyle.fontWeight = "200";
    }
    else if (header) {
        addTextStyle.fontSize = 20;
        addTextStyle.fontWeight = "bold";
    }

    if (lightColor)
        addTextStyle.color = theme.palette.text.secondary;

    if (bold)
        addTextStyle.fontWeight = "bold";

    if (italic)
        addTextStyle.fontStyle = "italic";

    if (listItem)
        return (
            <li style={{
                borderBottom: hasLightBottomBorder ? ("1px solid " + theme.palette.grey.A400) : undefined,
                borderTop: hasLightTopBorder ? ("1px solid " + theme.palette.grey.A400) : undefined,
                fontSize: "inherit",
                ...addTextStyle,
                ...style
            }}>
                {children}
            </li>)
    else
        return (
            <Typography sx={{
                borderBottom: hasLightBottomBorder ? ("1px solid " + theme.palette.grey.A400) : undefined,
                borderTop: hasLightTopBorder ? ("1px solid " + theme.palette.grey.A400) : undefined,
                fontSize: "inherit",
                ...addTextStyle,
                ...style
            }}>
                {children}
            </Typography>
        );
}