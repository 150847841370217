import { Slider } from "@mui/material";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import Columns from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";

type IVatanZorlukSliderProps = {
    onChange: (value: number | null) => void,
    value: number | null,
    disabled?: boolean,
    allowNull?: boolean,
};

function VatanZorlukSlider(props: IVatanZorlukSliderProps) {

    return <Columns>
        <Slider
            disabled={props.disabled}
            min={props.allowNull ? -1 : 0}
            step={1}
            max={10}
            marks
            sx={{
                '& .MuiSlider-rail': {
                    background: BusinessHelper.SarkiZorluk.GrandientColor,
                    opacity: 1,
                    height: 3,
                },
                '& .MuiSlider-track': {
                    background: BusinessHelper.SarkiZorluk.GetColorAtPoint10Based(props.value),
                    opacity: 1,
                    height: 3,
                },
            }}
            slotProps={{
                thumb: { style: { background: BusinessHelper.SarkiZorluk.GetColorAtPoint10Based(props.value) } }
            }}
            value={props.value ?? (props.allowNull ? -1 : 0)}
            onChange={(e, v) => props.onChange((v as number === -1 ? null : v as number))}
        />
        <CsmText note style={{ width: "20px", marginTop: "3px", marginLeft: "10px" }}>{props.value == null ? "-" : props.value.toFixed()}</CsmText>
    </Columns>
}

export default VatanZorlukSlider;