/* eslint-disable @typescript-eslint/no-explicit-any */
import DateTimeHelper from "./DateTimeHelper";

// cihan
export const _aylar = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık'
];

const _gunIsimler = [
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
    'Pazar'
];

type translatableKeysType =
    'Ocak' | 'Şubat' | 'Mart' | 'Nisan' | 'Mayıs' | 'Haziran' | 'Temmuz' | 'Ağustos' | 'Eylül' | 'Ekim' | 'Kasım' | 'Aralık' |
    'Pazartesi' | 'Salı' | 'Çarşamba' | 'Perşembe' | 'Cuma' | 'Cumartesi' | 'Pazar';

type CsmDateTimeTranslateFuncType = (a: translatableKeysType, v?: { [key: string]: string }) => string;

const monthIndexBase = 1;

const gunIsimGetirFromZeroBasedDay = (zeroBasedDayNumber: number, translate?: CsmDateTimeTranslateFuncType) => {
    const gunIsim = _gunIsimler[(zeroBasedDayNumber + 6) % 7];
    return translate ? translate(gunIsim as any) : gunIsim;
}

const DateTimeToStringHelper = {
    // GET METHODS
    getMonthName: (ay: number): string => {
        return _aylar[ay - 1];
    },
    getNamesOfDays: (translate?: CsmDateTimeTranslateFuncType): string[] => {
        return _gunIsimler.select(e => translate ? translate(e as any) : e);
    },
    toDDMMYYStringWithDot: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const mm = (dateTime.getMonth() + monthIndexBase).toString().padStart(2, '0'); // getMonth() is zero-based
        const dd = dateTime.getDate().toString().padStart(2, '0');
        const yy = (dateTime.getFullYear() % 100).toString().padStart(2, '0');
        return `${dd}.${mm}.${yy}`;
    },

    /// 2022-02-18 13:42
    toDatetimeString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        return DateTimeToStringHelper.toDateString(dateTime)! + " " + DateTimeToStringHelper.toTimeString(dateTime);
    },

    /// 2022-02-18 13:42:55
    toDatetimeWithSecondsString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        return DateTimeToStringHelper.toDateString(dateTime)! + " " + DateTimeToStringHelper.toTimeWithSecondsString(dateTime);
    },

    /// 2022-02-18
    toDateString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const mm = (dateTime.getMonth() + monthIndexBase).toString().padStart(2, '0'); // getMonth() is zero-based
        const dd = dateTime.getDate().toString().padStart(2, '0');
        return `${dateTime.getFullYear()}-${mm}-${dd}`;
    },

    /// Ocak, Şubat, ...
    toMonthString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];
        return ayString;
    },

    /// 2022 Ocak
    toYearAndMonthString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];
        return `${dateTime.getFullYear()} ${ayString}`;
    },

    /// Ocak 2022
    toMonthAndYearString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];
        return `${ayString} ${dateTime.getFullYear()}`;
    },

    /// 30 Ocak
    toMonthDayString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";

        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const dd = dateTime.getDate();

        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];

        return `${dd} ${ayString}`;
    },

    /// 30 Ocak (2020)
    toMonthDayStringOptionalYear: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";

        if (new Date().getFullYear() === dateTime?.getFullYear())
            return DateTimeToStringHelper.toMonthDayString(dateTime, translate);

        return `${DateTimeToStringHelper.toMonthDayString(dateTime, translate)} (${dateTime?.getFullYear()})`;
    },

    /// 30 Ocak Pazartesi 2019, 30 Ocak Pazartesi (2022)
    toMonthDayAndDayOfWeekString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const yearString = dateTime.getFullYear() !== DateTimeHelper.today().getFullYear() ? (" " + dateTime.getFullYear().toString()) : "";
        const monthDayAndDayOfWeekString = DateTimeToStringHelper.toMonthDayAndDayOfWeekStringWithoutYear(dateTime, translate);

        return `${monthDayAndDayOfWeekString}${yearString}`;
    },

    /// 30 Ocak 2019 Pazartesi, 30 Ocak 2022 Pazartesi
    toMonthDayYearAndDayOfWeekString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const yearString = dateTime.getFullYear().toString();
        const monthDayString = DateTimeToStringHelper.toMonthDayString(dateTime, translate);
        const gunString = gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate);

        return `${monthDayString} ${yearString} ${gunString}`;
    },

    /// 30 Ocak Pazartesi
    toMonthDayAndDayOfWeekStringWithoutYear: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const monthDayString = DateTimeToStringHelper.toMonthDayString(dateTime, translate);
        const gunString = gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate);

        return `${monthDayString} ${gunString}`;
    },

    /// 30 Pazartesi
    toDayAndDayOfWeekString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        return `${dateTime.getDate()} - ${gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate)}`;
    },

    /// 30 Ocak 2022
    toMonthDayAndYearString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const monthDayString = DateTimeToStringHelper.toMonthDayString(dateTime, translate);
        const yearString = dateTime.getFullYear().toString();

        return `${monthDayString} ${yearString}`;
    },

    /// 30 Ocak 2022 Pazartesi
    toMonthDayAndYearAndDayOfWeekString: (dateTime: Date | null, translate?: CsmDateTimeTranslateFuncType): string => {
        if (dateTime == null) return "";
        const monthDayString = DateTimeToStringHelper.toMonthDayString(dateTime, translate);
        const yearString = dateTime.getFullYear().toString();
        const dayOfWeekString = gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate);

        return `${monthDayString} ${yearString} ${dayOfWeekString}`;
    },

    /// 10:02
    toTimeString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const hh = dateTime.getHours().toString().padStart(2, '0');
        const mm = dateTime.getMinutes().toString().padStart(2, '0');

        return `${hh}:${mm}`;
    },

    /// 23:59:59
    toTimeWithSecondsString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const hh = dateTime.getHours().toString().padStart(2, '0');
        const mm = dateTime.getMinutes().toString().padStart(2, '0');
        const ss = dateTime.getSeconds().toString().padStart(2, '0');

        return `${hh}:${mm}:${ss}`;
    },

    // 1:23
    convertSecondsToDurationString: (seconds: number): string => {
        const mergeArray: string[] = [];

        if (seconds > 60 * 60) {
            const hours = Math.floor(seconds / (60 * 60));
            mergeArray.push(hours.toString());
            seconds = seconds % (60 * 60);
        }

        const minutes = Math.floor(seconds / 60);
        if (mergeArray.length === 0 || minutes > 9) {
            mergeArray.push(minutes.toString());
        } else {
            mergeArray.push(`0${minutes.toString()}`);
        }
        seconds = seconds % 60;

        if (mergeArray.length === 0 || seconds > 9) {
            mergeArray.push(seconds.toString());
        } else {
            mergeArray.push(`0${seconds.toString()}`);
        }

        return mergeArray.join(":");
    },
}

export default DateTimeToStringHelper;