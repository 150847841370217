import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import appColors from "../../../app/appColors";
import StringHelper from "../../../library/helpers/StringHelper";
import SarkiZorlukInfoBox from "./SarkiZorlukInfoBox";
import CsmIcerikHelper from "../../../library/helpers/CsmIcerikHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";


interface ISarkiKunyeDisplayerProps {
    sarkiModel: SarkiModel,
    genelDurumHelper: GenelDurumHelper,
    closer: () => void,
}

function SarkiKunyeDisplayer(props: ISarkiKunyeDisplayerProps) {
    const { sarkiModel, closer, genelDurumHelper } = props;

    const screenSize = useScreenWidth();
    const isSmallScreen = screenSize === "Max300";
    const isMediumScreen = screenSize === "Max600";
    const isLargeScreen = screenSize === "Max900";
    const isXLargeScreen = screenSize === "Max1200";

    const minWidth = isSmallScreen ? 250 : isMediumScreen ? 250 : isLargeScreen ? 550 : isXLargeScreen ? 850 : 1150;

    const konserlerKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.konserlerKullanilsin;
    const sarkiZorlukArabirimiKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.sarkiZorlukArabirimiKullanilsin;

    function generateZorlukContent() {
        if (!sarkiZorlukArabirimiKullanilsin)
            return null;

        const zorlukModel = genelDurumHelper.SeciliKoroModel.sarkiZorlukModelList.singleOrDefault(e => e.sarkiModelId === sarkiModel.id);

        if (zorlukModel === null)
            return <CsmText note>Eserin zorluk bilgisi bulunmamaktadır</CsmText>

        return <SarkiZorlukInfoBox sarkiModel={sarkiModel} sarkiZorlukModel={zorlukModel} genelDurumHelper={genelDurumHelper} />
    }

    function temelBilgiItem(title: string, getter: (e: SarkiModel) => string | undefined | null) {
        const value = getter(sarkiModel) ?? "-";

        return <Columns width={300}>
            <CsmText>{title}:</CsmText>
            <CsmText style={{ marginLeft: "5px", color: appColors.SECONDARY }}>{value.length === 0 ? "-" : value}</CsmText>
        </Columns>
    }

    function generateMuzikalBilgilerContent() {
        return <Columns wrap>
            {temelBilgiItem("Ses Grupları", e => BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, e.sesGrupIdList))}
            {temelBilgiItem("Tarz", e => BusinessHelper.Tarz.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumTarzlar, e.tarzModelIdList))}
            {temelBilgiItem("Süre", e => DateTimeToStringHelper.convertSecondsToDurationString(e.sure))}
            {temelBilgiItem("Tempo", e => e.tempo)}
            {temelBilgiItem("Dönem", e => e.donem)}
            {temelBilgiItem("Tarih", e => e.tarih)}
            {temelBilgiItem("Söz Yazarı", e => e.sozYazari)}
            {temelBilgiItem("Besteci", e => e.besteci)}
            {temelBilgiItem("Düzenleme", e => e.duzenleme)}
            {temelBilgiItem("Ölçü Sayısı", e => e.olcuSayisi)}
            {temelBilgiItem("Dil", e => e.dil)}
            {temelBilgiItem("Eşlik", e => e.eslik)}
        </Columns>
    }

    function generateKonserBilgileriContent() {
        if (!konserlerKullanilsin)
            return null;

        const konserLinkler = genelDurumHelper.SeciliKoroModel.tumKonserler
            .select(e => ({ konser: e, konserSarki: e.konserSarkiModelList.where(t => t.sarkiModelId === sarkiModel.id).firstOrDefault() }))
            .where(e => e.konserSarki != null);

        if (konserLinkler.length === 0)
            return <CsmText note>Eserin söylendiği konser bulunmamaktadır</CsmText>

        const konserLinklerVideolu = konserLinkler
            .where(e => StringHelper.nullToEmpty(e.konserSarki!.videoUrl).length > 0);

        const videolarContent = <Columns wrap>
            {konserLinklerVideolu.map(link =>
                <Rows key={link.konser.id} width={224} height={168} style={{ marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                    <div className="video-responsive">
                        <iframe
                            width="224"
                            height="168"
                            src={CsmIcerikHelper.findEmbedLink(link.konserSarki!.videoUrl!)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                    <CsmText note>{link.konser.isim}</CsmText>
                </Rows>
            )}
        </Columns>

        const konserIsimlerContent = konserLinkler.map(e => <li key={e.konser.id}>
            <Columns style={{ display: "inline-flex", placeItems: "center", marginRight: "20px" }}>
                <CsmText>{e.konser.isim}</CsmText>
                <CsmText style={{ marginLeft: "5px" }} smallNote>{DateTimeToStringHelper.toMonthDayAndDayOfWeekString(e.konser.tarih, genelDurumHelper.translate)}</CsmText>
            </Columns>
        </li>);

        return <Rows>
            <CsmText bold>Söylendiği Konserler</CsmText>
            <Columns wrap>
                {konserIsimlerContent}
            </Columns>
            <CsmText bold>Konser Videoları</CsmText>
            {videolarContent}
        </Rows>
    }

    const muzikalBilgilerContent = generateMuzikalBilgilerContent();
    const konserBilgileriContent = generateKonserBilgileriContent();
    const zorlukContent = generateZorlukContent();

    return <Rows flex style={{ minWidth: minWidth }}>
        <Rows>
            <Rows hasLightBottomBorder style={{ marginBlock: "10px", paddingBottom: "10px" }}>
                <CsmText header>Müzikal Bilgiler</CsmText>
                {muzikalBilgilerContent}
            </Rows>
            {!konserlerKullanilsin ? null :
                <Rows hasLightBottomBorder style={{ marginBlock: "10px", paddingBottom: "10px" }}>
                    <CsmText header>Konser Bilgileri</CsmText>
                    {konserBilgileriContent}
                </Rows>
            }
            {!sarkiZorlukArabirimiKullanilsin ? null :
                <Rows hasLightBottomBorder style={{ marginBlock: "10px", paddingBottom: "10px" }}>
                    <CsmText header>Zorluk Bilgileri</CsmText>
                    {zorlukContent}
                </Rows>
            }
        </Rows>
        <Columns style={{ marginTop: 10 }}>
            <Button color="info" variant="contained" onClick={() => closer()} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.tamam}
                {genelDurumHelper.translate("Tamam")}</Button>
        </Columns>

    </Rows>
}


export default SarkiKunyeDisplayer;