// AutoGenerated
export class KonserSarkiModel {
    konserModelId!: string;
    sarkiModelId!: string;
    siraNo!: number;
    videoUrl!: string | null;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}