// AutoGenerated
export class TarzModel {
    isim!: string;
    aciklama!: string | null;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}