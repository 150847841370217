import React, { useState } from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Columns from "../../library/components/layout/Columns";
import Rows from "../../library/components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { MainScreenInfo, ScreenDefContainer, mainScreenLocations } from "../../app/mainScreens";
import { Box, Button, Switch, Typography } from "@mui/material";
import CsmAccordionSimple from "../../library/components/mui/CsmAccordionSimple";
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';
import { EnmYetki } from '../../models/enums/EnmYetki';

function RaporlarScreen() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null || !user.seciliKoroId) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <RaporlarScreenInner genelDurumHelper={genelDurumHelper} />
}

function RaporlarScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const [showDebug, setShowDebug] = useState(false);

    const veryGlobalAdmin = (genelDurumHelper.SeciliKoroModel.koroVeHesapModelList?.length ?? 0) >= 1;

    const generateAnaYonetimList = (genelDurumHelper: GenelDurumHelper, ekranlar: MainScreenInfo[]) => {
        const anaActionList: { altBaslik: string, dugmeler: MainScreenInfo[] }[] = [];

        for (const screen of ekranlar) {
            if (screen.inUse && screen.inUse(genelDurumHelper) === false)
                continue;
            if (screen.yetkiler!) {
                if (screen.yetkiler.length > 0 && genelDurumHelper.HasYetkiAny(screen.yetkiler) === false)
                    continue;
            }
            else {
                if ((genelDurumHelper.SeciliKoroModel.koroVeHesapModelList?.length ?? 0) < 1)
                    continue;
            }

            let foundAnaBaslik = anaActionList.singleOrDefault(e => e.altBaslik === screen.kategori);
            if (foundAnaBaslik == null) {
                foundAnaBaslik = { altBaslik: screen.kategori, dugmeler: [] };
                anaActionList.push(foundAnaBaslik);
            }

            foundAnaBaslik.dugmeler.push(screen);
        }

        return anaActionList;
    }

    const screenDefContainer = ScreenDefContainer(props.genelDurumHelper.translate);
    const yonetimMenuList = generateAnaYonetimList(genelDurumHelper, screenDefContainer.raporlarScreens.where(e => e.kategori !== ""));

    return (
        <MainLayout
            location={mainScreenLocations.raporlar}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <div>
                <Rows>
                    {veryGlobalAdmin && <Columns style={{ alignItems: "center" }}>
                        <Switch checked={showDebug} onChange={(e) => setShowDebug(e.target.checked)} />
                        <Typography sx={{ fontSize: 12, width: "100px" }}>{showDebug ? "Debug Göster" : "Debug Gösterme"}</Typography>
                    </Columns>}
                    {yonetimMenuList.map(anaMenu =>
                        <CsmAccordionSimple
                            key={anaMenu.altBaslik}
                            title={anaMenu.altBaslik}
                            alwaysOpen>
                            <Columns flex wrap>
                                {anaMenu.dugmeler.map(page =>
                                    <Box key={page.location}>
                                        <Button size="small" sx={{ my: 1, mx: 1, py: 1, px: 1 }} variant="contained" onClick={() => props.genelDurumHelper.navigate(page.location)}>
                                            {page.icon}
                                            <Typography sx={{ ml: 1 }}>{page.menuText}</Typography>
                                        </Button>
                                        {showDebug && <Typography sx={{ ml: 1, fontSize: "10px" }}>{page.yetkiler === null ? "GlobalAdmin" : page.yetkiler.select(e=> EnmYetki[e]).join(", ") }</Typography>}
                                    </Box>
                                )}
                            </Columns>
                        </CsmAccordionSimple>
                    )}
                </Rows>
            </div>
        </MainLayout>
    );
}

export default RaporlarScreen;