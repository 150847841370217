/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { createRef, useEffect, useState } from 'react';
import IcerikHelper from '../../helpers/IcerikHelper';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Columns, { Columns2 } from '../../library/components/layout/Columns';
import { MAIN_LAYOUT } from 'react-h5-audio-player/lib/constants';
import { IconButton } from '@mui/material';
import { appIcons } from '../../app/appIcons';
import CsmNumericField from '../../library/components/mui/CsmNumericField';
import { useObserveElementWidthGroup } from '../../library/components/hooks/useObserveElementWidthGroup';
import AlertHelper from '../../library/helpers/AlertHelper';
import Cell from '../../library/components/layout/Cell';
import { CsmText } from '../../library/components/mui/CsmText';
import appColors from '../../app/appColors';
import GenelDurumHelper from '../../helpers/GenelDurumHelper';
import CsmIcerikHelper from '../../library/helpers/CsmIcerikHelper';

export interface IRegularAudioPlayerProps {
    icerikId: string,
    title: string,
    layout2?: MAIN_LAYOUT | "auto",
    autoloadAudio?: boolean,
    genelDurumHelper: GenelDurumHelper,
}

export function RegularAudioPlayer(props: IRegularAudioPlayerProps) {
    const [base64UrlIcerik, setBase64UrlIcerik] = useState<string | null>(null);
    const [loop, setLoop] = useState<boolean>(false);
    const [loopStart, setLoopStart] = useState<number>(0);
    const [loopEnd, setLoopEnd] = useState<number>(0);
    const [speed, setSpeed] = useState<number>(100);
    const [enableLoopDetails, setEnableLoopDetails] = useState<boolean>(false);

    const { sizeName, ref } = useObserveElementWidthGroup<HTMLDivElement, MAIN_LAYOUT>({ 799: "stacked", 10000: "horizontal" });

    const isMidiAdmin = (props.genelDurumHelper.SeciliKoroModel.koroVeHesapModelList?.length ?? 0) >= 1;
    const apRef = createRef<AudioPlayer>();

    useEffect(() => {
        if (props.autoloadAudio !== false)
            veriGetir();
        else
            setBase64UrlIcerik("");
    }, [props.icerikId])

    const veriGetirVeCal = async () => {
        await veriGetir();
    }

    const veriGetir = async () => {
        // içerik base64 ise, direkt bas ekrana
        const meta = IcerikHelper.IcerikMetaGetir(props.icerikId);
        const webResponse = await props.genelDurumHelper.requestHelper.Genel.IcerikGetirDirect(props.icerikId, meta.uzanti, "DosyaIsim Yükleniyor", { dosyaIsim: props.title });

        if (!webResponse.successfull)
            return;

        try {
            const base64Url = await CsmIcerikHelper.GetAudioSourceFromBlob(webResponse.data!, meta.uzanti);
            setBase64UrlIcerik(base64Url);
        }
        catch (error) {
            setBase64UrlIcerik("");
        }
    }

    function openCloseLoop() {
        const newState = !loop
        setLoop(newState);
        if (!newState && loopDetails)
            setEnableLoopDetails(false);
    }

    function openCloseLoopDetails() {
        const newState = !enableLoopDetails
        setEnableLoopDetails(newState);
        if (newState && !loop)
            setLoop(true);
    }

    function playbackrateChange(newSpeed: number) {
        if (!apRef.current?.audio.current)
            return;
        apRef.current.audio.current.playbackRate = newSpeed / 100;
        setSpeed(newSpeed);
    }

    const indirButton = (icerik: string) => <IconButton title={`${props.title} indir`} onClick={() => CsmIcerikHelper.DownloadToClient(props.title + IcerikHelper.IcerikMetaGetir(props.icerikId).uzanti, icerik)} >{appIcons.Genel.indirOrtaBoy}</IconButton>
    const loopButton = <IconButton title="Tekrar" onClick={() => openCloseLoop()} >{loop ? appIcons.Mixer.repeatOn : appIcons.Mixer.repeatOff}</IconButton>
    const loopDetailsButton = <IconButton title="Tekrar Süreleri" onClick={() => openCloseLoopDetails()} >{enableLoopDetails ? appIcons.Mixer.trimOn : appIcons.Mixer.trimOff}</IconButton>
    const loopDetails = <Columns style={{ marginRight: "5px" }}>
        <CsmNumericField disabled={!loop || !enableLoopDetails} inputProps={{ style: { fontSize: "14px", paddingTop: 0 } }} style={{ marginTop: "5px", width: "40px" }} title="Tekrar Başlangıç (sny)" onChange={e => setLoopStart(e ?? 0)} value={Math.floor(loopStart)} narrow />
        <CsmNumericField disabled={!loop || !enableLoopDetails} inputProps={{ style: { fontSize: "14px", paddingTop: 0 } }} style={{ marginTop: "5px", width: "40px" }} title="Tekrar Bitiş (sny)" onChange={e => setLoopEnd(e ?? 0)} value={Math.floor(loopEnd)} narrow />
    </Columns>

    const speedDetailsButton = <Cell key="speed_label" width={40}><IconButton title="Çalma Hızı" onClick={() => playbackrateChange(100)}>{appIcons.Mixer.speedOn}</IconButton></Cell>;
    const speedControls = <Columns width={130}>
        <Cell key="speed_slider" flex style={{ marginLeft: "5px", paddingTop: "5px" }}><input disabled={!base64UrlIcerik} className='mixerPlaybackRate mixerRange slider gricubuk' type='range' min={50} max={!isMidiAdmin ? 150 : 200} step={10} value={speed} onChange={e => playbackrateChange(parseInt(e.target.value))} onInput={e => playbackrateChange(parseInt((e.target as any).value))} /></Cell>
        <Cell key="speed_text" width={45}><CsmText style={{ marginTop: "9px", marginLeft: "10px", color: appColors.TEXT_PRIMARY }} note>x{(speed / 100).toFixed(1)}</CsmText></Cell>
    </Columns>


    function onListen(): void {
        if (!apRef.current || !apRef.current.audio.current) return
        if (!loop) return;

        const currentTime = apRef.current.audio.current.currentTime ?? 0;

        if (currentTime >= loopEnd - 1)
            apRef.current.audio.current.currentTime = loopStart;
    }

    const additionalDownloadButtons = (hasContent: boolean) =>
        !hasContent ? <Columns width={65}></Columns>
            :
            <Columns width={65} style={{ alignItems: "start" }}>
                {indirButton(base64UrlIcerik!)}
            </Columns>;

    const additionalButtons = (hasContent: boolean) =>
        !hasContent ? <Columns key="additionalEmpty"></Columns>
            :
            <Columns2 key="additionalnonEmpty" style={{ marginLeft: "-5px", marginRight: "-5px", alignItems: "start" }}>
                <Columns style={{ borderRadius: "10px", paddingRight: "0px", marginRight: "5px", background: appColors.GRAY_LIGHT4 }}>
                    {loopButton}
                    {loopDetailsButton}
                    {loopDetails}
                </Columns>
                <Cell flex></Cell>
                <Columns style={{ borderRadius: "10px", paddingRight: "0px", background: appColors.GRAY_LIGHT4 }}>
                    {speedControls}
                    {speedDetailsButton}
                </Columns>
            </Columns2>

    const actualLayout = sizeName;

    return <div ref={ref} style={{ minWidth: "350px", maxWidth: "900px", height: actualLayout === "stacked" ? 137 : 110, flexGrow: 1 }}>
        <AudioPlayer
            ref={apRef}
            onPlayError={e => {
                if (!base64UrlIcerik)
                    veriGetirVeCal();
                else if (e.message === 'NotSupportedError: The element has no supported sources.' || e.message.startsWith('NotSupportedError:'))
                    AlertHelper.ShowMessage("Dikkat", `${IcerikHelper.IcerikMetaGetir(props.icerikId).uzanti} dosyaları tarayıcınız tarafından desteklenmemektedir.`)
                else
                    alert(e.message);
            }}
            loop={false}
            onListen={() => onListen()}
            onCanPlay={() => { if (loopEnd === 0) setLoopEnd(apRef.current?.audio.current?.duration ?? 0); }}
            customAdditionalControls={[additionalDownloadButtons(!!base64UrlIcerik)]}
            footer={[additionalButtons(!!base64UrlIcerik)]}
            layout={actualLayout}
            src={base64UrlIcerik ?? undefined}
            autoPlayAfterSrcChange={false}
        />
    </div>;
    /* return (base64UrlIcerik === null ? <></> :
        <div ref={ref} style={{ minWidth: "350px", maxWidth: "800px", flexGrow: 1 }}>
            <AudioPlayer
                ref={apRef}
                onPlayError={e => {
                    if (e.message === 'NotSupportedError: The element has no supported sources.' || e.message.startsWith('NotSupportedError:')) veriGetirVeCal();
                    //else alert(e.message);
                }}
                loop
                onListen={(e) => onListen()}
                onCanPlay={() => { if (loopEnd === 0) setLoopEnd(apRef.current?.audio.current?.duration ?? 0); }}
                customAdditionalControls={!!base64UrlIcerik ? [indirButton(base64UrlIcerik), loopButton(), loopDetailsButton(), loopDetails()] : []}
                layout={actualLayout}
                src={base64UrlIcerik}
                autoPlayAfterSrcChange={false}
            />
        </div>
    ); */
}

export default RegularAudioPlayer;