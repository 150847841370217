/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, AutocompleteProps, Box, TextField } from "@mui/material";
import StringHelper from "../../helpers/StringHelper";

export type ICsmFreeAutoCompleteFieldProps = Omit<AutocompleteProps<string, false, false, true>, "freeSolo" | "options" | "renderInput" | "onChange">
    & {
        label: string,
        onChange: (e: string) => void,
        sourceList: string[]
    };

function CsmFreeAutoCompleteField(props: ICsmFreeAutoCompleteFieldProps) {
    const { label, onChange, sourceList, ...rest } = props;

    return (
        <Autocomplete
            freeSolo
            options={sourceList}
            renderOption={(props, option) => {
                const { ...optionProps } = props;
                return (
                    <Box
                        component="li"
                        sx={{ fontSize: "14px" }}
                        {...optionProps}
                    >
                        {option}
                    </Box>
                );
            }}
            onChange={e => {
                const innerText: string = (e.target as any).innerText;
                const value: string = (e.target as any).value;
                const innerTextEmpty = !innerText || innerText.length === 0;
                const valueEmpty = !value || value.length === 0;

                if (innerTextEmpty && valueEmpty)
                    onChange("");
                else if (innerTextEmpty)
                    onChange(value);
                else
                    onChange(innerText);
            }}
            renderInput={(params) => <TextField onChange={e => { onChange(StringHelper.nullToEmpty(e.target.value)) }} label={label} {...params} />}
            {...rest}
        />
    )
}

export default CsmFreeAutoCompleteField;