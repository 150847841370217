import { Button } from "@mui/material";
import { appIcons } from "../../app/appIcons";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { HesapSarkiSesKayitModel } from "../../models/businessModels/HesapSarkiSesKayitModel";
import { EnmKayitDurum } from "../../models/enums/EnmKayitDurum";
import { CsmText } from "../../library/components/mui/CsmText";
import Columns from "../../library/components/layout/Columns";
import Rows from "../../library/components/layout/Rows";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import DateTimeToStringHelper from "../../library/helpers/DateTimeToStringHelper";

interface IVatanKayitRowKistItemProps {
    kayit: HesapSarkiSesKayitModel,
    siraNo: number,
    kayitSec: (kayitModel: HesapSarkiSesKayitModel) => Promise<void>,
    kayitSil?: (kayitModel: HesapSarkiSesKayitModel) => Promise<void>,
    kayitSesGrupGuncelle?: (kayitModel: HesapSarkiSesKayitModel) => Promise<void>,
    genelDurumHelper: GenelDurumHelper,
    style?: CSSProperties,
    sesGrubununSonKaydiMi: boolean,
    narrow?: boolean,
}

export function VatanKayitRowInnerPropsHazirla(kayitList: HesapSarkiSesKayitModel[], sesGrupList: SesGrupModel[]) {
    const result: { kayit: HesapSarkiSesKayitModel, siraNo: number, sesGrubununSonKaydiMi: boolean, sesGrup: SesGrupModel }[] = [];

    const seciliHesabinKayitSesGruplari = kayitList.select(e => e.sesGrupId).distinct().select(e => sesGrupGetir(e)).orderBy(e => e.sira);
    const seciliHesabinKayitVeSesGruplari = seciliHesabinKayitSesGruplari.select(e => {
        return {
            sesGrup: e,
            kayitlar: kayitList.where(t => t.sesGrupId === e.id).orderBy(e => e.olusturmaTarih),
        }
    });

    function sesGrupGetir(sesGrupId: string) {
        return sesGrupList.single(e => e.id === sesGrupId);
    }

    for (let i = 0; i < seciliHesabinKayitVeSesGruplari.length; i++)
        for (let sira = 0; sira < seciliHesabinKayitVeSesGruplari[i].kayitlar.length; sira++)
            result.push({
                kayit: seciliHesabinKayitVeSesGruplari[i].kayitlar[sira],
                siraNo: sira,
                sesGrubununSonKaydiMi: seciliHesabinKayitVeSesGruplari[i].kayitlar.length === (sira + 1),
                sesGrup: seciliHesabinKayitVeSesGruplari[i].sesGrup,
            });

    return result;
}

export function VatanKayitRowListItem(props: IVatanKayitRowKistItemProps) {
    const { narrow, sesGrubununSonKaydiMi, style, kayitSesGrupGuncelle, kayitSil, kayit, siraNo, kayitSec, genelDurumHelper } = props;

    function yorumYapanIsimGetir(kayit: HesapSarkiSesKayitModel): string {
        if (!kayit.yorumlar)
            return "";

        const kullanici = genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.kullaniciModel.kullaniciAdi === (kayit.guncelleyen ?? kayit.olusturan));
        return `(${kullanici.kullaniciModel.ad} ${kullanici.kullaniciModel.soyad})`;
    }

    const yorumContent = narrow ? <div style={{ display: "inline" }}>
        <CsmText note style={{ marginTop: "2px", marginLeft: "5px" }}><b>Yorumlar: </b>{kayit.yorumlar} <i>{yorumYapanIsimGetir(kayit)}</i></CsmText>
    </div>
        :
        <Columns>
            <CsmText>Yorum: </CsmText>
            <CsmText note style={{ marginTop: "2px", marginLeft: "5px" }}>{kayit.yorumlar} <i>{yorumYapanIsimGetir(kayit)}</i></CsmText>
        </Columns>;

    const buttonContent = <div style={{ justifyContent: "space-around", width: "65px" }}>
        <Rows>
            <Button variant="text" style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => kayitSec(kayit)}>
                {sesGrubununSonKaydiMi ? appIcons.Sarki.oynat2("60px") : appIcons.Sarki.oynat}
            </Button>
            {!kayitSil ? null :
                <Button variant="text" color="error" style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => kayitSil(kayit)}>
                    {appIcons.CrudIcons.sil}
                </Button>
            }
            {!kayitSesGrupGuncelle ? null :
                <Button variant="text" style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => kayitSesGrupGuncelle(kayit)}>
                    {appIcons.CrudIcons.guncelle}
                </Button>
            }
        </Rows>
    </div>;

    const isimContent = <CsmText bold>
        {genelDurumHelper.SesGruplariniGetir([kayit.sesGrupId]).singleOrDefault()?.isim ?? "-"} - {siraNo + 1}. Kayıt ({DateTimeToStringHelper.toMonthDayStringOptionalYear(kayit.olusturmaTarih, genelDurumHelper.translate)} {DateTimeToStringHelper.toTimeString(kayit.olusturmaTarih)})</CsmText>;

    const sesGrupContent = <Columns>
        <CsmText>Ses Grubu: </CsmText>
        <CsmText note style={{ marginTop: "2px", marginLeft: "5px" }}>{genelDurumHelper.SesGruplariniGetir([kayit.sesGrupId]).singleOrDefault()?.isim ?? "-"}</CsmText>
    </Columns>;

    const durumContent = <Columns>
        <CsmText>Durum: </CsmText>
        <CsmText note style={{ marginTop: "2px", marginLeft: "5px", color: BusinessHelper.SesKayit.KayitDurumPaletteRenkGetir(kayit.kayitDurum)?.main }}>{EnmKayitDurum[kayit.kayitDurum]}</CsmText>
    </Columns>;

    return narrow ? (
        <Rows hasLightBottomBorder key={kayit.id} style={{ padding: "5px", ...style }}>
            <Columns>
                {buttonContent}
                <Rows flex>
                    {isimContent}
                    {sesGrupContent}
                    {durumContent}
                </Rows>
            </Columns>
            {yorumContent}
        </Rows>
    ) : (
        <Columns hasLightBottomBorder key={kayit.id} style={{ padding: "5px", ...style }}>
            {buttonContent}
            <Rows flex>
                {isimContent}
                {sesGrupContent}
                {durumContent}
                {yorumContent}
            </Rows>
        </Columns>
    );
}

export default VatanKayitRowListItem;
