import PdfDisplayer from "../../../components/media/PdfDisplayer";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import AudioMixer from "../../../components/media/AudioMixer";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";

function MidiWithPdfPopupEditor() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);

    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <MidiWithPdfPopupEditorInner genelDurumHelper={genelDurumHelper} />
}

function MidiWithPdfPopupEditorInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;
    const { sarkiId } = useParams();

    /* -- END OF STANDARD -- */

    const sarki = genelDurumHelper.SeciliKoroModel.tumSarkilar.single(e => e.id === sarkiId);

    const {pdfVariMi, eskiPdfMi, pdfIcerikId} = BusinessHelper.Icerik.SarkiLinktenPdfLinkGetir(sarki?.sarkiLinkModelList);

    return <SubPageLayout
        title={sarki.isim}
        previousLocations={[{ location: mainScreenLocations.sarkilar }]}
        genelDurumHelper={genelDurumHelper}
        hideHeader={false}
        hideMenu={false}>

        <Rows flex dontGrowForChild style={{ overflow: "hidden" }}>
            <AudioMixer genelDurumHelper={genelDurumHelper} sarkiId={sarkiId!} />

            <Columns flex style={{ display: "flex", justifyContent: "center" }} dontGrowForChild>
                {!pdfIcerikId ? <CsmText>PDF Bulunmamaktadır</CsmText> : <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={pdfIcerikId} />}
            </Columns>
        </Rows>
    </SubPageLayout>
}

export default MidiWithPdfPopupEditor;