import { useState, useRef, useEffect } from "react";


export const useObserveElementWidth = <T extends HTMLElement>() => {
    const ref = useRef<T>(null);
    const [containerSize, setContainerSize] = useState<number>(0);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setContainerSize(entries[0].contentRect.width);
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            ref.current && observer.unobserve(ref.current);
        };
    }, []);

    return {
        containerSize,
        ref
    };
};