import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface ICurrentUserState {
    //isLoggedIn: boolean,
    token: string;
    //tokenExpireDate: Date | null;
    seciliKoroId: string,
}

const initialState: ICurrentUserState = {
    //isLoggedIn: false,
    token: "",
    //tokenExpireDate: null,
    seciliKoroId: "",
};

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        userLoggedOut: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            
            //state.isLoggedIn = false;
            state.token = "";
            //state.tokenExpireDate = null;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        tokenGeldi: (state, action: PayloadAction<{ token: string/*, tokenExpireDate: Date*/}>) => {
            //state.isLoggedIn = false;
            state.token = action.payload.token
            //state.tokenExpireDate = action.payload.tokenExpireDate;
        },
        // userLoggedIn: (state, action: PayloadAction<string>) => {
        //     //state.isLoggedIn = true;
        //     state.seciliKoroId = action.payload;
        // },
        seciliKoroDegistir: (state, action: PayloadAction<string>) => {
            state.seciliKoroId = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading';
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle';
    //             state.value += action.payload;
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed';
    //         });
    // },
});

export const { userLoggedOut, tokenGeldi, seciliKoroDegistir } = currentUserSlice.actions;

export const getCurrentUser = (state: RootState) => state.currentUser;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.currentUser.value)`
// export const selectCount = (state: RootState) => state.currentUser.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//         (dispatch, getState) => {
//             const currentValue = selectCount(getState());
//             if (currentValue % 2 === 1) {
//                 dispatch(incrementByAmount(amount));
//             }
//         };

export default currentUserSlice.reducer;