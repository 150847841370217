/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { Button, FormControl, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import CalismaAvatarListControl from "../../../components/business/CalismaAvatarListControl";
import DuyuruAvatarListControl from "../../../components/business/DuyuruAvatarListControl";
import { CsmAccordion, CsmAccordionGroup } from "../../../library/components/mui/CsmAccordion";
import JsonHelper from "../../../library/helpers/JsonHelper";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import { KadroModel } from "../../../models/businessModels/KadroModel";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { KadroEkleGuncelleRequestModel } from "../../../models/requestModels/KadroEkleGuncelleRequestModel";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { HesapVeSesGrubuBilgiModel } from "../../../models/businessModels/HesapVeSesGrubuBilgiModel";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { HesapBilgiModel } from "../../../models/businessModels/HesapBilgiModel";

interface IKadroPopupEditorProps {
    kadroModel: KadroModel,
    isimGuncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: KadroEkleGuncelleRequestModel | null) => void,
    initialEklenecekHesapList?: HesapVeSesGrubuBilgiModel[],
}

function KadroPopupEditor(props: IKadroPopupEditorProps) {
    const { initialEklenecekHesapList, closer, isimGuncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [kadroModel, setKadroModel] = useState<KadroModel>(JsonHelper.deepCopy(props.kadroModel));

    const [finalHesapSesGrupList, setFinalHesapSesGrupList] = useState<HesapVeSesGrubuBilgiModel[]>(
        [...(initialEklenecekHesapList ?? []),
        ...findHesapKadroListFromGenelDurum(kadroModel.id, genelDurumHelper)
        ]);
    const [cikartilacakHesapList, setCikartilacakHesapList] = useState<HesapVeSesGrubuBilgiModel[]>([]);

    const [eklenecekCalismaIdList, setEklenecekCalismaIdList] = useState<string[]>([]);
    const [cikartilacakCalismaIdList, setCikartilacakCalismaIdList] = useState<string[]>([]);

    const tumKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar;
    const finalHesapSesGrupHesapIdList = finalHesapSesGrupList.select(e => e.hesapModelId);

    const updateKadroModel = (action: (model: KadroModel) => void) => {
        const result = JsonHelper.deepCopy(kadroModel);
        action(result);
        setKadroModel(result);
    }

    const hata = tumKadrolar.any(e => e.id !== kadroModel.id && e.isim === kadroModel.isim) ? "Bu isimde başka bir kadro bulunmaktadır" : "";
    const hataVar = !!hata || !kadroModel.isim || !kadroModel.sezonModelId;

    const kaydet = async () => {
        const model = new KadroEkleGuncelleRequestModel(kadroModel,
            finalHesapSesGrupList,
            kullananOrijinalCalismalar.select(e => e.id).except(cikartilacakCalismaIdList).union(eklenecekCalismaIdList));

        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Kadro.KadroGuncelle(model, "Kadro güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Kadro.KadroEkle(model, "Kadro ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(model);
    }
    function hesapVeSesGrubuBilgiModelOlustur(hesapModelId: string) {
        const result = new HesapVeSesGrubuBilgiModel();
        const alakaliHesap = genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.id === hesapModelId);

        result.id = GuidHelper.NewGuid();
        result.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        result.olusturmaTarih = DateTimeHelper.today();
        result.hesapModelId = hesapModelId;
        result.altSesGrupModelId = BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(genelDurumHelper.SesGruplariniGetir(alakaliHesap.sesGrupIdList))!.id;

        return result;
    }
    const hesapSec = (hesap: HesapBilgiVeKullaniciModel, eklemeMi: boolean) => {
        const newCikartilacakHesapList = cikartilacakHesapList.slice();
        const newFinalHesapSesGrupList = finalHesapSesGrupList.slice();

        if (eklemeMi === true) {
            const cikartilmisOlan = newCikartilacakHesapList.singleOrDefault(e => e.hesapModelId === hesap.id);

            // eskiden listedeymiş ama çıkartılmış, şimdi geri ekleniyor
            if (cikartilmisOlan) {
                newCikartilacakHesapList.remove(cikartilmisOlan);
                newFinalHesapSesGrupList.push(cikartilmisOlan);
            }
            // eskiden listeden yokmuş, ilk defa ekleniyor
            else {
                newFinalHesapSesGrupList.push(hesapVeSesGrubuBilgiModelOlustur(hesap.id));
            }
        }
        else {
            const eklenmisOlan = newFinalHesapSesGrupList.single(e => e.hesapModelId === hesap.id);

            newFinalHesapSesGrupList.remove(eklenmisOlan);
            newCikartilacakHesapList.push(eklenmisOlan);
        }

        setFinalHesapSesGrupList(newFinalHesapSesGrupList);
        setCikartilacakHesapList(newCikartilacakHesapList);
    }

    const calismaSec = (calisma: CalismaModel, yeniDurum: boolean) => {
        const newCikartilacakCalismaIdList = cikartilacakCalismaIdList.slice();
        const newEklenecekCalismaIdList = eklenecekCalismaIdList.slice();

        if (yeniDurum === true) {
            if (newCikartilacakCalismaIdList.contains(calisma.id))
                newCikartilacakCalismaIdList.remove(calisma.id);
            if (kullananOrijinalCalismalar.all(e => e.id !== calisma.id))
                newEklenecekCalismaIdList.push(calisma.id);
        }
        else {
            if (newEklenecekCalismaIdList.contains(calisma.id))
                newEklenecekCalismaIdList.remove(calisma.id);
            if (kullananOrijinalCalismalar.any(e => e.id === calisma.id))
                newCikartilacakCalismaIdList.push(calisma.id);
        }

        setEklenecekCalismaIdList(newEklenecekCalismaIdList);
        setCikartilacakCalismaIdList(newCikartilacakCalismaIdList);
    }


    function findSesGrupOfHesap(hesap: HesapBilgiModel, genelDurumHelper: GenelDurumHelper): string[] {
        const foundHesapSesGrup = finalHesapSesGrupList.singleOrDefault(e => e.hesapModelId === hesap.id);
        if (foundHesapSesGrup === null)
            return hesap.sesGrupIdList;
        else
            return BusinessHelper.SesGrubu.AltSesGrubununListesiniGetir(foundHesapSesGrup.altSesGrupModelId, genelDurumHelper.SeciliKoroModel.tumSesGruplari);
    }

    const tumKoristHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e));
    const tumCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar;

    const kullananOrijinalCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.kadroIdList.contains(props.kadroModel.id));

    const kadroyaDahilCalismaIdlerSonDurum = kullananOrijinalCalismalar.select(e => e.id).except(cikartilacakCalismaIdList).union(eklenecekCalismaIdList);
    const kadroyaHaricCalismaIdlerSonDurum = tumCalismalar.select(e => e.id).except(kadroyaDahilCalismaIdlerSonDurum);

    const kadroyaDahilHesaplar = tumKoristHesaplar.where(e => finalHesapSesGrupHesapIdList.contains(e.id));
    const kadroyaHaricAktifHesaplar = tumKoristHesaplar.where(e => !finalHesapSesGrupHesapIdList.contains(e.id) && e.aktif);
    const kadroyaHaricPasifHesaplar = tumKoristHesaplar.where(e => !finalHesapSesGrupHesapIdList.contains(e.id) && !e.aktif);

    const kadroyaDahilCalismalar = tumCalismalar.where(e => kadroyaDahilCalismaIdlerSonDurum.contains(e.id)).orderByDesc(e => e.tarihBaslangic);
    const kadroyaHaricCalismalar = tumCalismalar.where(e => kadroyaHaricCalismaIdlerSonDurum.contains(e.id)).orderByDesc(e => e.tarihBaslangic);

    const kullananDuyurular = genelDurumHelper.SeciliKoroModel.tumDuyurular.where(e => e.kadroIdList.contains(props.kadroModel.id));
    // const kullananAnketler = genelDurumHelper.SeciliKoroModel.tumAnketler.where(e => e.kadroIdList.contains(props.kadroModel.id));

    const hesaplarVeCalismalarContent = <Rows>
        <CsmAccordionSimple
            title="Kadroya Dahil Aktif Hesaplar"
            emptyNote="Kadroya dahil aktif hesap bulunmamaktadır"
            children={() => kadroyaDahilHesaplar.length === 0 ? null : <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKoristHesaplar}
                gosterilecekHesaplar={kadroyaDahilHesaplar}
                onClick={(hesap) => hesapSec(hesap, false)}
                hesapColorizeMethod="Durum"
                hesapSesGrupGetir={e => findSesGrupOfHesap(e, genelDurumHelper)}
                genelDurumHelper={genelDurumHelper} />}
            alwaysOpen
        />
        <CsmAccordionSimple
            title="Kadroya Dahil Olmayan Aktif Hesaplar"
            emptyNote="Kadroya dahil olmayan aktif hesap bulunmamaktadır"
            children={() => kadroyaHaricAktifHesaplar.length === 0 ? null : <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKoristHesaplar}
                gosterilecekHesaplar={kadroyaHaricAktifHesaplar}
                onClick={(hesap) => hesapSec(hesap, true)}
                hesapColorizeMethod="Durum"
                genelDurumHelper={genelDurumHelper} />}
            alwaysOpen
        />
        <CsmAccordionSimple
            title="Kadroya Dahil Olmayan Pasif Hesaplar"
            emptyNote="Kadroya dahil olmayan pasif hesap bulunmamaktadır"
            children={() => kadroyaHaricPasifHesaplar.length === 0 ? null : <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKoristHesaplar}
                gosterilecekHesaplar={kadroyaHaricPasifHesaplar}
                onClick={(hesap) => hesapSec(hesap, true)}
                genelDurumHelper={genelDurumHelper} />}
        />
        <CsmAccordionSimple
            title="Kadroya Dahil Çalışmalar"
            emptyNote="Kadroya dahil çalışma bulunmamaktadır"
            children={() => kadroyaDahilCalismalar.length === 0 ? null : <CalismaAvatarListControl calismalar={kadroyaDahilCalismalar} onClick={e => calismaSec(e, false)} genelDurumHelper={genelDurumHelper} />}
        />
        <CsmAccordionSimple
            title="Kadroya Dahil Olmayan Çalışmalar"
            emptyNote="Kadroya dahil olmayan çalışma bulunmamaktadır"
            children={() => kadroyaHaricCalismalar.length === 0 ? null : <CalismaAvatarListControl calismalar={kadroyaHaricCalismalar} onClick={e => calismaSec(e, true)} genelDurumHelper={genelDurumHelper} />}
        />
    </Rows>;

    const ozetContent = `Seçilen: ${kadroyaDahilHesaplar.length} korist ve ${kadroyaDahilCalismalar.length} çalışma`;

    return (
        <Rows height={guncellemeMi ? 550 : 550} style={{ minWidth: 850, paddingTop: 20, display: "flex" }}>
            <Columns>
                <CsmTextField
                    fullWidth
                    disabled={!isimGuncellenebilir}
                    label="Kadro İsmi"
                    error={hataVar}
                    helperText={hata}
                    value={kadroModel.isim}
                    onChange={(e) => updateKadroModel(t => t.isim = e)} />

                <VatanSezonSelectField
                    error={!kadroModel.sezonModelId}
                    disabled={!isimGuncellenebilir}
                    style={{ marginLeft: "10px", marginRight: "10px", width: "350px" }}
                    value={kadroModel.sezonModelId}
                    label="Bağlı Olduğu Sezon"
                    tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
                    onChange={value => updateKadroModel(e => e.sezonModelId = value)} />

                <FormControl style={{ width: 195, marginRight: 5, display: "flex", flexDirection: "row", marginTop: "15px" }}>
                    <Switch checked={kadroModel.aktif} onChange={(e) => updateKadroModel(t => t.aktif = e.target.checked)} />
                    <Typography sx={{ fontSize: 12, marginTop: "8px" }}>{kadroModel.aktif ? "Aktif" : "Pasif"}</Typography>
                </FormControl>
            </Columns>
            <CsmText note>{ozetContent}</CsmText>
            <Rows flex style={{ marginTop: 10, overflowY: "auto" }}>
                <CsmAccordionGroup initialExpandedKey="hesap" children={(onChange, expandedKey) => (
                    <>
                        {hesaplarVeCalismalarContent}
                        {kullananDuyurular.length > 0 ?
                            <CsmAccordion expandedKey="duyuru" title={`Kadroyu İçeren Duyurular (${kullananDuyurular.length} duyuru)`} expanded={expandedKey === "duyuru"} onExpandedChanged={onChange}>
                                <DuyuruAvatarListControl duyurular={kullananDuyurular} genelDurumHelper={genelDurumHelper} />
                            </CsmAccordion>
                            :
                            <CsmText note italic>Kadroyu içeren duyuru bulunmamaktadır</CsmText>
                        }
                        {/* {kullananAnketler.length > 0 ?
                                <VatanAccordion expandedKey="anket" title={`Kadroyu İçeren Anketler (${kullananAnketler.length} anket)`} expanded={expandedKey === "anket"} onExpandedChanged={onChange}>
                                    <AnketAvatarListControl anketler={kullananAnketler} />
                                </VatanAccordion>
                                :
                                <MyText note italic>Kadroyu içeren Anket bulunmamaktadır</MyText>
                            } */}
                    </>
                )} />
            </Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default KadroPopupEditor;

function findHesapKadroListFromGenelDurum(kadroId: string, genelDurumHelper: GenelDurumHelper) {
    const hesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.kadroVeSesGrubuBilgiModelList.any(t => t.kadroModelId === kadroId));

    return hesaplar.select(hesap => {
        const kadroVeSesGrubuModel = hesap.kadroVeSesGrubuBilgiModelList.single(e => e.kadroModelId === kadroId);
        const result = new HesapVeSesGrubuBilgiModel();
        result.id = kadroVeSesGrubuModel.id;
        result.olusturan = kadroVeSesGrubuModel.id;
        result.olusturmaTarih = kadroVeSesGrubuModel.olusturmaTarih;
        result.guncelleyen = kadroVeSesGrubuModel.guncelleyen;
        result.guncellemeTarih = kadroVeSesGrubuModel.guncellemeTarih;
        result.altSesGrupModelId = kadroVeSesGrubuModel.altSesGrupModelId;
        result.hesapModelId = hesap.id;

        return result;
    });
}


