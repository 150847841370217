import CsmFreeAutoCompleteField, { ICsmFreeAutoCompleteFieldProps } from "../../library/components/mui/CsmFreeAutoCompleteField";

export type IVatanDonemFieldProps = Omit<ICsmFreeAutoCompleteFieldProps, "sourceList">;

const donemlerOrdered: { isim: string, tarih: string }[] = [
    { isim: "Orta Çağ", tarih: "(400-1600)" },
    { isim: "Rönesans", tarih: "(1400-1600)" },
    { isim: "Barok", tarih: "(1600-1750)" },
    { isim: "Klasik", tarih: "(1750-1830)" },
    { isim: "Romantik", tarih: "(1830-1900)" },
    { isim: "20. Yüzyıl", tarih: "(1900-2000)" },
    { isim: "Çağdaş", tarih: "(2000-...)" },
];

const sourceList = donemlerOrdered.map(e => `${e.isim} ${e.tarih}`);

function VatanDonemField(props: IVatanDonemFieldProps) {

    return <CsmFreeAutoCompleteField
        sourceList={sourceList}
        {...props}
    />
}

export default VatanDonemField;