import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Cell from "../../../library/components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import Columns from "../../../library/components/layout/Columns";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import VatanLineChart, { IVatanLineSeriesData } from "../../../components/charts/VatanLineChart";
import CsmGenericGroupBox from "../../../library/components/mui/CsmGenericGroupBox";
import { AxisValueFormatterContext } from "@mui/x-charts/internals";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import StringHelper from "../../../library/helpers/StringHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

function KonserStatScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <Inner genelDurumHelper={genelDurumHelper} />
}

function Inner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
  const tumKonserler = genelDurumHelper.SeciliKoroModel.tumKonserler.orderBy(e => e.tarih);

  const konserlerVeKoristler = tumKonserler.select(konser => {
    return {
      konser: konser,
      sarkilar: tumSarkilar.where(sarki => konser.konserSarkiModelList.any(konserSarki => konserSarki.sarkiModelId === sarki.id)),
      kisiler: genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(hesap => BusinessHelper.Kadro.HesapKadroyaDahilMi(hesap, konser.kadroModelId))
    };
  });

  const koristlerVeKonserler = genelDurumHelper.SeciliKoroModel.hesapBilgiList.select(hesap => {
    return {
      kisi: hesap,
      konserler: tumKonserler.where(konser => BusinessHelper.Kadro.HesapKadroyaDahilMi(hesap, konser.kadroModelId)),
    };
  }).where(e => e.konserler.length > 0);

  const sarkilarVeKonserler = tumSarkilar.select(sarki => {
    return {
      sarki: sarki,
      konserler: tumKonserler.where(konser => konser.konserSarkiModelList.any(konserSarki => konserSarki.sarkiModelId === sarki.id)),
    };
  }).where(e => e.konserler.length > 0);

  const sarkilarVeSarkiKonserler = sarkilarVeKonserler.select(sarkiVeKonser => {
    return {
      sarki: sarkiVeKonser.sarki,
      sarkiKonserler: sarkiVeKonser.konserler.selectMany(e => e.konserSarkiModelList.where(t => t.sarkiModelId === sarkiVeKonser.sarki.id && StringHelper.isNotEmpty(t.videoUrl)))
    };
  }).where(e => e.sarkiKonserler.length > 0);


  // serilerim ses grupları, anahtarlarım konser

  const spreadKoristSesGrupKonserData = tumKonserler.selectMany(konser => {
    const konserdekiKoristler = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId).contains(konser.kadroModelId));
    const result = konserdekiKoristler.select(e => ({ sesgrup: StringHelper.nullToEmpty(koristinSesGrupIsminiGetirByKadroId(e, konser.kadroModelId, genelDurumHelper)) })).groupBy(e => e.sesgrup).select(group => {
      return {
        sesgrup: StringHelper.isNotEmpty(group.key) ? group.key! : "Bilinmiyor",
        koristSayisi: group.list.length,
        konserIsim: konser.isim,
      }
    });
    return result;
  });

  const koristSesGrupKonserData: IVatanLineSeriesData<string>[] = spreadKoristSesGrupKonserData.groupBy(e => e.sesgrup).select(group => {
    return {
      seriesLabel: group.key,
      data: group.list.select(e => { return { key: e.konserIsim, value: e.koristSayisi } }),
    };
  }).orderByDesc(e => e.data.sum(t => t.value));

  function konserOrderer(isimler: string[]): string[] {
    return tumKonserler.where(e => isimler.contains(e.isim)).orderBy(e => e.tarih).select(e => e.isim);
  }

  function konserKeyFormatter(isim: string, c: AxisValueFormatterContext): string {
    const konser = tumKonserler.single(e => e.isim === isim);
    const konserIndex = tumKonserler.indexOf(konser);
    const prevKonser = konserIndex === 0 ? null : tumKonserler[konserIndex - 1];

    const date = konser.tarih;

    if (c.location === "tick")
      if (prevKonser === null || prevKonser.tarih.getFullYear() !== konser.tarih.getFullYear())
        return `${date.getFullYear()}\n${DateTimeToStringHelper.toMonthString(date, genelDurumHelper.translate)}`;
      else
        return `${DateTimeToStringHelper.toMonthString(date, genelDurumHelper.translate)}`;
    else {
      const sayi = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId).contains(konser.kadroModelId)).length;
      return `${DateTimeToStringHelper.toMonthDayAndYearString(date, genelDurumHelper.translate)} - ${konser.isim} (${sayi} Korist)`
    }
  }

  return (
    <MainLayout
      location={mainScreenLocations.konserStat}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows width="100%">
        <CsmAccordionSimple bigCenteredTitle
          style={{ marginTop: "15px" }}
          title="Çizelgeler"
          alwaysOpen>
          <Rows flex>
            <Columns>
              <Cell flex style={{ minHeight: "400px" }}>
                <CsmGenericGroupBox label="">
                  <VatanLineChart chartLabel="Konsere Göre Korist Sayıları ve Ses Grupları" isStack data={koristSesGrupKonserData} xAxisValueFormatter={konserKeyFormatter} marginForLegend={130} xAxisValueOrderrer={konserOrderer} />
                </CsmGenericGroupBox>
              </Cell>
              <Cell flex style={{ minHeight: "400px" }}>
                <CsmGenericGroupBox label="">
                  <VatanLineChart chartLabel="Konsere Göre Ses Grupları (Konsere içi Dağılım)" isStack data={koristSesGrupKonserData} xAxisValueFormatter={konserKeyFormatter} marginForLegend={130} useSeriesAsPercent xAxisValueOrderrer={konserOrderer} />
                </CsmGenericGroupBox>
              </Cell>
            </Columns>
          </Rows>
        </CsmAccordionSimple>
        <CsmAccordionSimple
          bigCenteredTitle
          style={{ marginTop: "15px" }}
          title="İstatistikler"
          alwaysOpen>
          <Columns>
            <Cell style={{ marginRight: "10px" }}>
              <TableContainer>
                <Table size="small" sx={{ flex: 0, minWidth: 200 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }} >En Kalabalık Konserler</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>{genelDurumHelper.translate("Konser")}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Kadro Sayısı")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {konserlerVeKoristler.orderByDesc(e => e.kisiler.length)
                      .map((konserInfo, i) =>
                        <TableRow
                          key={konserInfo.konser.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Rows>
                              <CsmText bold>{`${(i + 1).toFixed()}. ${konserInfo.konser.isim}`}</CsmText>
                              <CsmText italic small>{DateTimeToStringHelper.toMonthDayAndDayOfWeekString(konserInfo.konser.tarih, genelDurumHelper.translate)} - {konserInfo.konser.yer} - {konserInfo.konser.sehirUlke}</CsmText>
                            </Rows>
                          </TableCell>
                          <TableCell align="right">{`${konserInfo.kisiler.length.toFixed()} korist`}</TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Cell>
            <Cell style={{ marginRight: "10px" }}>
              <TableContainer>
                <Table size="small" sx={{ flex: 0, minWidth: 200 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }} >Konserlerde En Çok Söylenen Şarkılar</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }} >{genelDurumHelper.translate("Şarkı")}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Konser Sayısı")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sarkilarVeKonserler.orderByDesc(e => e.konserler.length)
                      .map((sarkiInfo, i) =>
                        <TableRow
                          key={sarkiInfo.sarki.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                          }}
                        >
                          <TableCell component="th" scope="row">{`${(i + 1).toFixed()}. ${sarkiInfo.sarki.isim}`}</TableCell>
                          <TableCell align="right">{`${sarkiInfo.konserler.length.toFixed()} konser`}</TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Cell>
            <Cell style={{ marginRight: "10px" }}>
              <TableContainer>
                <Table size="small" sx={{ flex: 0, minWidth: 200 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }} >Videosu En Çok Yüklenen Şarkılar</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }} >{genelDurumHelper.translate("Şarkı")}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Yükleme Sayısı")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sarkilarVeSarkiKonserler.orderByDesc(e => e.sarkiKonserler.length)
                      .map((sarkiInfo, i) =>
                        <TableRow
                          key={sarkiInfo.sarki.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                          }}
                        >
                          <TableCell component="th" scope="row">{`${(i + 1).toFixed()}. ${sarkiInfo.sarki.isim}`}</TableCell>
                          <TableCell align="right">{`${sarkiInfo.sarkiKonserler.length.toFixed()} yükleme`}</TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Cell>
            <Cell style={{ marginRight: "10px" }}>
              <TableContainer>
                <Table size="small" sx={{ flex: 0, minWidth: 200 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }} >En Çok Konser Verenler</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }} >{genelDurumHelper.translate("Korist")}</TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Konser Sayısı")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {koristlerVeKonserler.orderByDesc(e => e.konserler.length)
                      .map((koristInfo, i) =>
                        <TableRow
                          key={koristInfo.kisi.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                          }}
                        >
                          <TableCell component="th" scope="row">{`${(i + 1).toFixed()}. ${koristInfo.kisi.kullaniciModel.ad} ${koristInfo.kisi.kullaniciModel.soyad}`}</TableCell>
                          <TableCell align="right">{`${koristInfo.konserler.length.toFixed()} konser`}</TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Cell>
          </Columns>
        </CsmAccordionSimple>
      </Rows>
    </MainLayout>
  );
}

export default KonserStatScreen;

function koristinSesGrupIsminiGetirByKadroId(hesap: HesapBilgiVeKullaniciModel, kadroId: string, genelDurumHelper: GenelDurumHelper): string {
  const hesapKadro = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === kadroId);

  if (!hesapKadro) return "";

  const altSesGrupId = hesap.kadroVeSesGrubuBilgiModelList.single(e => e.kadroModelId === hesapKadro.kadroModelId).altSesGrupModelId;
  const anaSesGrupId = BusinessHelper.SesGrubu.AltSesGrubununAnaSesGrupIdGetir(altSesGrupId, genelDurumHelper.SeciliKoroModel.tumSesGruplari);
  return genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === anaSesGrupId).isim;
}
