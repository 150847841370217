/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";
import Cell from "../../../library/components/layout/Cell";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import { SecmeBasvuruWithContentRequestModel } from "../../../models/requestModels/SecmeBasvuruWithContentRequestModel";
import CsmNumericField from "../../../library/components/mui/CsmNumericField";
import CsmCstField from "../../../library/components/mui/CsmCstField";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import CsmTimePicker from "../../../library/components/mui/CsmTimePicker";
import StringHelper from "../../../library/helpers/StringHelper";

interface ISecmeBasvuruPopupEditorProps {
    secmeBasvuruModel: SecmeBasvuruModel,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: SecmeBasvuruWithContentRequestModel | null) => void,
}

function SecmeBasvuruPopupEditor(props: ISecmeBasvuruPopupEditorProps) {
    const { closer, guncellemeMi, genelDurumHelper } = props;

    const [secmeBasvuruModel, setSecmeBasvuruModel] = useState<SecmeBasvuruModel>(JsonHelper.deepCopy(props.secmeBasvuruModel));
    const [icerikEklemeRequestModel, setIcerikEklemeRequestModel] = useState<IcerikEklemeRequestModel | null>(null);

    const sezonBasvurulari = genelDurumHelper.SeciliKoroModel.tumSecmeBasvurular.where(e => e.sezonModelId === secmeBasvuruModel.sezonModelId);

    // update methods

    const updateSecmeBasvuruModel = (action: (model: SecmeBasvuruModel) => void) => {
        const result = JsonHelper.deepCopy(secmeBasvuruModel);
        action(result);
        setSecmeBasvuruModel(result);
    }

    const updateTarih = (newDate: Date | null): void => {
        updateSecmeBasvuruModel((e) => {
            if (newDate === null) {
                e.secmeTarihi = (null as any);
            }
            else {
                const rawDate = DateTimeHelper.getDateWithoutTime(newDate);
                e.secmeTarihi = DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(e.secmeTarihi ?? newDate));
            }
        });
    };

    const updateSaat = (newTime: Date): void => {
        updateSecmeBasvuruModel((e) => {
            if (newTime == null) {
                e.secmeTarihi = (null as any);
            }
            else {
                const rawDate = DateTimeHelper.getDateWithoutTime(e.secmeTarihi ?? newTime);
                e.secmeTarihi = DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(newTime));
            }
        });
    };

    const handleProfilResimUpdate = (model: IcerikEklemeRequestModel | null) => {
        setIcerikEklemeRequestModel(model);
        updateSecmeBasvuruModel(e => e.resimIcerikId = model?.id ?? null);
    }

    // others

    const epostaHataString = sezonBasvurulari.any(e => e.id !== secmeBasvuruModel.id && e.eposta === secmeBasvuruModel.eposta) ? "Bu eposta adresine sahip başka bir başvuru bulunmaktadır" : "";
    const epostaHata = epostaHataString.length > 0 || StringHelper.isEmpty(secmeBasvuruModel.eposta) || secmeBasvuruModel.eposta.length > 50;
    const adHata = StringHelper.isEmpty(secmeBasvuruModel.ad) || secmeBasvuruModel.ad.length > 50;
    const soyadHata = StringHelper.isEmpty(secmeBasvuruModel.soyad) || secmeBasvuruModel.soyad.length > 50;
    const sezonHata = StringHelper.isEmpty(secmeBasvuruModel.sezonModelId);

    const hataVar = epostaHata || adHata || soyadHata || sezonHata;

    const kaydet = async () => {
        const model = new SecmeBasvuruWithContentRequestModel(secmeBasvuruModel, icerikEklemeRequestModel ? [icerikEklemeRequestModel] : []);

        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Secme.BasvuruGuncelle(model, "Başvuru güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Secme.BasvuruEkle(model, "Başvuru ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(model);
    }

    return (
        <Rows style={{ display: "flex" }}>
            <Columns dontGrowForChild wrap style={{ marginTop: 8 }}>
                <Cell width={195} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <VatanSezonSelectField
                        error={sezonHata}
                        value={secmeBasvuruModel.sezonModelId}
                        label="Başvuru Sezonu"
                        tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
                        onChange={value => updateSecmeBasvuruModel(e => e.sezonModelId = value)} />
                </Cell>
                <Cell width={345} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        fullWidth
                        label="E-Posta Adresi"
                        error={epostaHata}
                        helperText={epostaHataString}
                        value={secmeBasvuruModel.eposta}
                        appendMaxlenOnLabel={50}
                        onChange={(e) => updateSecmeBasvuruModel(t => t.eposta = e)} />
                </Cell>
            </Columns>
            <Columns wrap style={{ marginTop: 8 }} dontGrowForChild>
                <Cell width={345} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        fullWidth
                        label="Ad"
                        error={adHata}
                        value={secmeBasvuruModel.ad}
                        appendMaxlenOnLabel={50}
                        onChange={(e) => updateSecmeBasvuruModel(t => t.ad = e)} />
                </Cell>
                <Cell width={345} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        fullWidth
                        label="Soyad"
                        error={soyadHata}
                        value={secmeBasvuruModel.soyad}
                        appendMaxlenOnLabel={50}
                        onChange={(e) => updateSecmeBasvuruModel(t => t.soyad = e)} />
                </Cell>

                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmCstField
                        cstType={EnmCinsiyet}
                        label="Cinsiyet"
                        value={secmeBasvuruModel.cinsiyet}
                        onChange={(e) => updateSecmeBasvuruModel(t => t.cinsiyet = e!)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmNumericField
                        fullWidth
                        label="Doğum Yılı"
                        value={secmeBasvuruModel.dogumYili}
                        onChange={(e) => updateSecmeBasvuruModel(t => t.dogumYili = e)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        label={"Telefon"}
                        value={secmeBasvuruModel.telefon}
                        appendMaxlenOnLabel={50}
                        onChange={value => updateSecmeBasvuruModel(e => e.telefon = value)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        label={"Meslek"}
                        value={secmeBasvuruModel.meslek}
                        appendMaxlenOnLabel={150}
                        onChange={value => updateSecmeBasvuruModel(e => e.meslek = value)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        label={"Eğitim Durumu"}
                        value={secmeBasvuruModel.egitimDurumu}
                        appendMaxlenOnLabel={150}
                        onChange={value => updateSecmeBasvuruModel(e => e.egitimDurumu = value)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        label={"Yaşadığı İl"}
                        value={secmeBasvuruModel.yasadigiIl}
                        appendMaxlenOnLabel={50}
                        onChange={value => updateSecmeBasvuruModel(e => e.yasadigiIl = value)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        label={"Bilinen Ses Grubu"}
                        value={secmeBasvuruModel.bilinenSesGrubu}
                        appendMaxlenOnLabel={50}
                        onChange={value => updateSecmeBasvuruModel(e => e.bilinenSesGrubu = value)} />
                </Cell>
                <Cell width={345} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTextField
                        rows={3}
                        showStats
                        label={"Koro Tecrübesi"}
                        value={secmeBasvuruModel.koroTecrubesi}
                        appendMaxlenOnLabel={4000}
                        onChange={value => updateSecmeBasvuruModel(e => e.koroTecrubesi = value)} />
                </Cell>
            </Columns>
            <Columns wrap style={{ marginTop: 8 }} dontGrowForChild>
                <Cell width={195} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmDatePicker
                        canClean
                        label={"Seçme Tarihi"}
                        value={secmeBasvuruModel.secmeTarihi}
                        onChange={value => updateTarih(value)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <CsmTimePicker
                        label={"Seçme Saati"}
                        value={secmeBasvuruModel.secmeTarihi}
                        onChange={value => updateSaat(value)} />
                </Cell>
                <Cell width={145} style={{ marginRight: "5px", marginBottom: "10px" }}>
                    <VatanIcerikUploadField
                        useAvatarCrop
                        fullWidth={false}
                        label="Profil Resmi"
                        showClearButton
                        onChange={handleProfilResimUpdate}
                        genelDurumHelper={genelDurumHelper}
                        icerikId={secmeBasvuruModel.resimIcerikId}
                        icerikModel={icerikEklemeRequestModel ?? null}
                        icerikTip={EnmIcerikTip.Avatar}
                    />
                </Cell>
            </Columns>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default SecmeBasvuruPopupEditor;