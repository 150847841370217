import React from 'react';
import './App.css';

import { LoginPage } from './pages/main/LoginPage';
import { AxiosProvider } from './components/core/AxiosProvider';
import { SnackbarProvider } from 'notistack';
import { } from './library/helpers/ArrayHelper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DialogDisplayers from './library/components/dialogs/DialogDisplayers';
import { LoadingPanelProvider } from './components/core/LoadingPanelProvider';
import { ThemeProvider } from '@emotion/react';
import theme from './app/theme';
import GuncelScreen from './pages/main/GuncelScreen';
import SarkiYonetimScreen from './pages/yonetim/sarki/SarkiYonetimScreen';

import { pdfjs } from 'react-pdf';
import KadroYonetimScreen from './pages/yonetim/kadro/KadroYonetimScreen';
import SesGrupYonetimScreen from './pages/yonetim/sesgrup/SesGrupYonetimScreen';
import RolYonetimScreen from './pages/yonetim/rol/RolYonetimScreen';
import DuyuruYonetimScreen from './pages/yonetim/duyuru/DuyuruYonetimScreen';
import CalismaYonetimScreen from './pages/yonetim/calisma/CalismaYonetimScreen';
import HesapYonetimScreen from './pages/yonetim/korist/HesapYonetimScreen';
import YoklamaCizelgeScreen from './pages/rapor/cizelge/YoklamaCizelgeScreen';
import SarkiKayitCizelgeScreen from './pages/rapor/cizelge/SarkiKayitCizelgeScreen';
import KuartetCizelgeScreen from './pages/rapor/cizelge/KuartetCizelgeScreen';
import YoklamaYonetimScreen from './pages/yonetim/yoklama/YoklamaYonetimScreen';
import KuartetYonetimScreen from './pages/yonetim/kuartet/KuartetYonetimScreen';
import KayitSarkiYorumYonetimScreen from './pages/yonetim/kayityorum/KayitSarkiYorumScreen';
import KayitSarkiYorumSarkiYonetimScreen from './pages/yonetim/kayityorum/KayitSarkiYorumSarkiScreen';
import IconsYonetimScreen from './pages/yonetim/admin/IconsYonetimScreen';
import { mainScreenLocations } from './app/mainScreens';
import SarkilarScreen from './pages/main/SarkilarScreen';
import { LogoutPage } from './pages/main/LogoutPage';
import SarkilarDetaylariScreen from './pages/main/sarki/SarkilarDetaylariScreen';
import MidiWithPdfPopupEditor from './pages/main/sarki/MidiWithPdfScreen';
import SarkiLinkAudioWithPdfScreen from './pages/main/sarki/SarkiLinkAudioWithPdfScreen';
import ProfilScreen from './pages/main/ProfilScreen';
import TakvimScreen from './pages/main/TakvimScreen';
import KoroAyarlariScreen from './pages/yonetim/koroYonetim/KoroAyarlariScreen';
import KoroYonetimScreen from './pages/yonetim/admin/KoroYonetimScreen';
import HataYonetimScreen from './pages/yonetim/admin/HataYonetimScreen';
import KoristSarkiYorumScreen from './pages/yonetim/kayityorum/KoristSarkiYorumScreen';
import KoristSarkiYorumKoristScreen from './pages/yonetim/kayityorum/KoristSarkiYorumKoristScreen';
import YonetimScreen from './pages/main/YonetimScreen';
import KoristRaporScreen from './pages/rapor/GenelKoristRaporScreen';
import RepertuarRaporScreen from './pages/rapor/raporlar/RepertuarRaporScreen';
import GeriIzlemeYonetimScreen from './pages/yonetim/admin/GeriIzlemeYonetimScreen';
import KoristYorumScreen from './pages/yonetim/koristyorum/KoristYorumScreen';
import GenelKullaniciYonetimScreen from './pages/yonetim/admin/GenelKullaniciYonetimScreen';
import KayitlarimScreen from './pages/main/KayitlarimScreen';
import RaporlarScreen from './pages/main/RaporlarScreen';
import KadroRaporScreen from './pages/rapor/raporlar/KadroRaporScreen';
import YeniSifreScreen from './pages/main/YeniSifre';
import OtpYonetimScreen from './pages/yonetim/admin/OtpYonetimScreen';
import TatilYonetimScreen from './pages/yonetim/admin/TatilYonetimScreen';
import SarkiYorumScreen from './pages/yonetim/sarkiyorum/SarkiYorumScreen';
import SarkiMetaRaporScreen from './pages/rapor/sarkiMeta/SarkiMetaRaporScreen';
import SarkiMetaAramaScreen from './pages/rapor/sarkiMeta/SarkiMetaAnalizScreen';
import KonserYonetimScreen from './pages/yonetim/konser/KonserYonetimScreen';
import KonserRaporScreen from './pages/rapor/konser/KonserRaporScreen';
import KonserStatScreen from './pages/rapor/istatistik/KonserStatScreen';
import OzgecmisYonetimScreen from './pages/yonetim/ozgecmis/OzgecmisYonetimScreen';
import AdaySarkiYonetimScreen from './pages/yonetim/sarki/AdaySarkiYonetimScreen';
import SezonStatScreen from './pages/rapor/istatistik/SezonStatScreen';
import KayitStatScreen from './pages/rapor/istatistik/KayitStatScreen';
import TarzYonetimScreen from './pages/yonetim/tarz/TarzYonetimScreen';
import SarkiZorlukYonetimScreen from './pages/yonetim/sarkiZorluk/SarkiZorlukYonetimScreen';
import SarkiZorlukRaporScreen from './pages/rapor/sarkiZorlukRapor/SarkiZorlukRaporScreen';
import SecmeBasvuruYonetimScreen from './pages/yonetim/secmeBasvuru/SecmeBasvuruYonetimScreen';
import SecmeYonetimScreen from './pages/yonetim/secmeBasvuru/SecmeYonetimScreen';
import SecmeSonucScreen from './pages/yonetim/secmeBasvuru/SecmeSonucScreen';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

function App() {
  const router = createBrowserRouter([
    {
      path: mainScreenLocations.login,
      element: <LoginPage />,
    },
    {
      path: mainScreenLocations.logout,
      element: <LogoutPage />,
    },
    {
      path: mainScreenLocations.guncel,
      element: <GuncelScreen />,
    },
    {
      path: mainScreenLocations.sarkilar,
      element: <SarkilarScreen />,
    },
    {
      path: mainScreenLocations.kayitlarim,
      element: <KayitlarimScreen />,
    },
    {
      path: mainScreenLocations.yonetim,
      element: <YonetimScreen />,
    },
    {
      path: mainScreenLocations.raporlar,
      element: <RaporlarScreen />,
    },
    {
      path: mainScreenLocations.sarkilarDetay,
      element: <SarkilarDetaylariScreen />,
    },
    {
      path: mainScreenLocations.kayitDinle,
      element: <SarkiLinkAudioWithPdfScreen />,
    },
    {
      path: mainScreenLocations.midiDinle,
      element: <MidiWithPdfPopupEditor />,
    },
    {
      path: mainScreenLocations.profil,
      element: <ProfilScreen />,
    },
    {
      path: mainScreenLocations.takvim,
      element: <TakvimScreen />,
    },
    {
      path: mainScreenLocations.sarkiYonetim,
      element: <SarkiYonetimScreen />,
    },
    {
      path: mainScreenLocations.adaySarkiYonetim,
      element: <AdaySarkiYonetimScreen />,
    },
    {
      path: "/yonetim/icons",
      element: <IconsYonetimScreen />,
    },
    {
      path: mainScreenLocations.kadroYonetim,
      element: <KadroYonetimScreen />,
    },
    {
      path: mainScreenLocations.sesgrupYonetim,
      element: <SesGrupYonetimScreen />,
    },
    {
      path: mainScreenLocations.rolYonetim,
      element: <RolYonetimScreen />,
    },
    {
      path: mainScreenLocations.duyuruYonetim,
      element: <DuyuruYonetimScreen />,
    },
    {
      path: mainScreenLocations.calismaYonetim,
      element: <CalismaYonetimScreen />,
    },
    {
      path: mainScreenLocations.hesapYonetim,
      element: <HesapYonetimScreen />,
    },
    {
      path: mainScreenLocations.yoklamaCizelge,
      element: <YoklamaCizelgeScreen />,
    },
    {
      path: mainScreenLocations.sarkiKayitCizelge,
      element: <SarkiKayitCizelgeScreen />,
    },
    {
      path: mainScreenLocations.kuartetCizelge,
      element: <KuartetCizelgeScreen />,
    },
    {
      path: mainScreenLocations.yoklamaGiris,
      element: <YoklamaYonetimScreen />,
    },
    {
      path: mainScreenLocations.kuartetGiris,
      element: <KuartetYonetimScreen />,
    },
    {
      path: mainScreenLocations.kayitSarkiYorum,
      element: <KayitSarkiYorumYonetimScreen />,
    },
    {
      path: mainScreenLocations.kayitSarkiYorumSarki,
      element: <KayitSarkiYorumSarkiYonetimScreen />,
    },
    {
      path: mainScreenLocations.koristSarkiYorumYonetim,
      element: <KoristSarkiYorumScreen />,
    },
    {
      path: mainScreenLocations.koristSarkiYorumKoristScreen,
      element: <KoristSarkiYorumKoristScreen />,
    },
    {
      path: mainScreenLocations.koroayarlari,
      element: <KoroAyarlariScreen />,
    },
    {
      path: mainScreenLocations.koroYonetim,
      element: <KoroYonetimScreen />,
    },
    {
      path: mainScreenLocations.hataYonetim,
      element: <HataYonetimScreen />,
    },
    {
      path: mainScreenLocations.geriIzlemeYonetim,
      element: <GeriIzlemeYonetimScreen />,
    },
    {
      path: mainScreenLocations.genelKoristRapor,
      element: <KoristRaporScreen />,
    },
    {
      path: mainScreenLocations.repertuarRapor,
      element: <RepertuarRaporScreen />,
    },
    {
      path: mainScreenLocations.sarkiMetaRapor,
      element: <SarkiMetaRaporScreen />
    },
    {
      path: mainScreenLocations.sarkiZorlukRaporu,
      element: <SarkiZorlukRaporScreen />
    },
    {
      path: mainScreenLocations.konserRapor,
      element: <KonserRaporScreen />,
    },
    {
      path: mainScreenLocations.kadroRapor,
      element: <KadroRaporScreen />,
    },
    {
      path: mainScreenLocations.sezonStat,
      element: <SezonStatScreen />,
    },
    {
      path: mainScreenLocations.kayitStat,
      element: <KayitStatScreen />,
    },
    {
      path: mainScreenLocations.sarkiMetaAnaliz,
      element: <SarkiMetaAramaScreen />
    },
    {
      path: mainScreenLocations.koristYorumYonetim,
      element: <KoristYorumScreen />,
    },
    {
      path: mainScreenLocations.genelKullaniciYonetim,
      element: <GenelKullaniciYonetimScreen />,
    },

    {
      path: mainScreenLocations.otpYonetim,
      element: <OtpYonetimScreen />,
    },
    {
      path: mainScreenLocations.tatilYonetim,
      element: <TatilYonetimScreen />,
    },
    {
      path: mainScreenLocations.sarkiYorumYonetim,
      element: <SarkiYorumScreen />,
    },
    {
      path: mainScreenLocations.sarkiZorlukYonetim,
      element: <SarkiZorlukYonetimScreen />,
    },
    {
      path: mainScreenLocations.konserYonetim,
      element: <KonserYonetimScreen />,
    },
    {
      path: mainScreenLocations.konserStat,
      element: <KonserStatScreen />,
    },
    {
      path: mainScreenLocations.ozgecmisYonetim,
      element: <OzgecmisYonetimScreen />,
    },
    {
      path: mainScreenLocations.tarzYonetim,
      element: <TarzYonetimScreen />,
    },
    {
      path: mainScreenLocations.secmeBasvuruYonetim,
      element: <SecmeBasvuruYonetimScreen />,
    },
    {
      path: mainScreenLocations.secmeYonetim,
      element: <SecmeYonetimScreen />,
    },
    {
      path: mainScreenLocations.secmeSonuc,
      element: <SecmeSonucScreen />,
    },
    {
      path: 'yenisifre/:otp',
      element: <YeniSifreScreen />
    }
    // // {
    // //   path: "/userManagement",
    // //   element: <UserManagementPage />,
    // // },
    // // {
    // //   path: "/kanban",
    // //   element: <KanbanPage />,
    // // },
    // // {
    // //   path: "/branches",
    // //   element: <BranchesPage />,
    // // },
    // // {
    // //   path: "/iterations",
    // //   element: <IterationsPage />,
    // // }
  ]);

  return (
    <React.Fragment>
      {/* Alttaki Provider, x-date-pickers componentlerinin düzgün çalışması için gerekli */}
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <ThemeProvider theme={theme}>
          <DialogDisplayers />
          <SnackbarProvider>
            <LoadingPanelProvider>
              <AxiosProvider>
                <RouterProvider router={router} />
              </AxiosProvider>
            </LoadingPanelProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default App;
