import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import KorocanThumbnailIcerikId from "./KorocanThumbnailIcerikId";
import { IBaseKorocanThumbnailProps } from "./KorocanThumbnail";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

interface IKorocanThumbnailForHesapProps extends IBaseKorocanThumbnailProps {
    hesap: HesapBilgiVeKullaniciModel,
    genelDurumHelper: GenelDurumHelper,
}

function KorocanThumbnailForHesap(props: IKorocanThumbnailForHesapProps) {
    const { genelDurumHelper, hesap, ...others } = props;

    return <KorocanThumbnailIcerikId
        genelDurumHelper={genelDurumHelper}
        title={`${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad}`}
        icerikId={hesap.resimIcerikId}
        saveToStorage={hesap.aktif ? "Normal" : "None"}
        {...others}
    />
}

export default KorocanThumbnailForHesap;
