// AutoGenerated
import { SecmeBasvuruSonucModel } from "../businessModels/SecmeBasvuruSonucModel"

export class SecmeBasvuruSonucRequestModel {
    constructor(model: SecmeBasvuruSonucModel, parentId: string) {
        this.model = model;
        this.parentId = parentId;
    }

    model: SecmeBasvuruSonucModel;
    parentId: string;
}