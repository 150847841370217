// AutoGenerated
import { HesapAnketCevapModel } from "../businessModels/HesapAnketCevapModel"

export class AnketSonucGonderRequestModel {
    constructor(anketId: string, hesapAnketSecenekList: HesapAnketCevapModel[]) {
        this.anketId = anketId;
        this.hesapAnketSecenekList = hesapAnketSecenekList;
    }

    anketId: string;
    hesapAnketSecenekList: HesapAnketCevapModel[];
}