import { BusinessHelper } from "../../helpers/BusinessHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import StringHelper from "../../library/helpers/StringHelper";
import { CalismaModel } from "../../models/businessModels/CalismaModel";
import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import { HesapCalismaModel } from "../../models/businessModels/HesapCalismaModel";
import { HesapSarkiSesKayitModel } from "../../models/businessModels/HesapSarkiSesKayitModel";
import { KadroModel } from "../../models/businessModels/KadroModel";
import { SarkiModel } from "../../models/businessModels/SarkiModel";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { SezonModel } from "../../models/businessModels/SezonModel";
import { EnmKatilimDurum } from "../../models/enums/EnmKatilimDurum";
import { EnmKayitDurum } from "../../models/enums/EnmKayitDurum";
import { EnmSesGrupTip } from "../../models/enums/EnmSesGrupTip";


export interface IGrupMainData {
    kadroIsim: string,
    data: ISesGrubuMainData[],
    sezonList: SezonModel[],
}

export interface ISesGrubuMainData {
    sesGrupIsim: string,
    data: IKullaniciData[],
    sezonOrtalama: IKullaniciSezonData[],
    genelOrtalama: IKullaniciSezonData,
}

export interface IKullaniciData {
    kullanici: string,
    sesGrupId: string,
    sezonlar: IKullaniciSezonData[],
    genelOrtalama: IKullaniciSezonData,
}

export interface IKullaniciSezonData {
    userId: string,
    sezonId: string,
    sezonName: string,
    calismaOranNormal: number,
    calismaOranGecKalmali: number,
    calismaGeldigi: number,
    calismaGecKaldigi: number,
    calismaToplam: number,
    sarkiOran: number,
    sarkiBasarili: number,
    sarkiToplam: number,
}

export function GenerateKoristRaporData(genelDurumHelper: GenelDurumHelper): IGrupMainData[] {
    const tumKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e));
    const aktifKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e) && e.aktif);
    const eskiKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e) && !e.aktif);

    const sezonlar: SezonModel[] = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderByDesc(e => e.id === genelDurumHelper.AktifSezon.id ? "_" : e.isim);

    const kadrolar = genelDurumHelper.KadrolariniGetir(aktifKullanicilar.selectMany(e => e.kadroVeSesGrubuBilgiModelList)).where(e => e.aktif).orderBy(e => e.aktif ? `_${e.isim}` : e.isim);
    const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.length > 0);
    const tumCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar;
    const anaSesGruplari = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]).orderBy(e => e.sira);

    const calismalarBySezon: { [key: string]: CalismaModel[] } = sezonlar.reduce((obj, sezon) => ({ ...obj, [sezon.id]: tumCalismalar.where(e => sezon == null || e.sezonModelId === sezon!.id) }), {});
    calismalarBySezon[""] = tumCalismalar;
    const sarkilarBySezon: { [key: string]: SarkiModel[] } = sezonlar.reduce((obj, sezon) => ({ ...obj, [sezon.id]: tumSarkilar.where(e => sezon == null || e.sezonSarkiModelList.any(t => t.sezonModelId === sezon!.id)) }), {});
    sarkilarBySezon[""] = tumSarkilar;

    const hesapCalismaByHesap: { [key: string]: HesapCalismaModel[] } = tumKullanicilar.reduce((obj, hesap) => ({ ...obj, [hesap.id]: genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.where(e => e.hesapModelId === hesap.id) }), {});
    const hesapSesKayitByHesap: { [key: string]: HesapSarkiSesKayitModel[] } = tumKullanicilar.reduce((obj, hesap) => ({ ...obj, [hesap.id]: genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === hesap.id) }), {});

    function getKullaniciGenelOzetState(userId: string, sezonDataList: IKullaniciSezonData[]): IKullaniciSezonData {
        const kullanilabilirData = sezonDataList.where(e => e.calismaOranNormal > 0);

        return {
            userId: userId,
            sezonName: "Genel",
            sezonId: "",
            calismaOranNormal: Math.ceil(kullanilabilirData.sum(e => e.calismaOranNormal) / kullanilabilirData.length),
            calismaOranGecKalmali: Math.ceil(kullanilabilirData.sum(e => e.calismaOranGecKalmali) / kullanilabilirData.length),
            calismaGeldigi: 0,
            calismaGecKaldigi: 0,
            calismaToplam: 0,
            sarkiOran: Math.ceil(kullanilabilirData.sum(e => e.sarkiOran) / kullanilabilirData.length),
            sarkiBasarili: 0,
            sarkiToplam: 0,
        }
    }

    function getKullaniciSezonState(hesap: HesapBilgiVeKullaniciModel, sezonId: string): IKullaniciSezonData {
        const kullaniciCalismaHesapList = hesapCalismaByHesap[hesap.id] ?? [];
        const alakaliCalismalar = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(calismalarBySezon[StringHelper.nullToEmpty(sezonId)], hesap, kullaniciCalismaHesapList, false).select(e => e.id);
        const kullanicininGirildigiYoklamalar = kullaniciCalismaHesapList.where(e => alakaliCalismalar.contains(e.calismaModelId));

        const kullanicininKayitlari = hesapSesKayitByHesap[hesap.id] ?? [];
        const sezonSarkiKayitlari = sarkilarBySezon[sezonId].select(sarki => BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kullanicininKayitlari.where(e => e.sarkiModelId === sarki.id)));

        const basariliSarki = sezonSarkiKayitlari.where(e => e === EnmKayitDurum.Onaylandı || e === EnmKayitDurum["Yorum Bekleniyor"]).length;

        const katildigiCalisma = kullanicininGirildigiYoklamalar.where(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı).length;
        const gecKatildigiCalisma = kullanicininGirildigiYoklamalar.where(e => e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]).length;
        const gelmedigiCalisma = kullanicininGirildigiYoklamalar.where(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı).length;

        return {
            userId: hesap.id,
            sezonName: sezonlar.singleOrDefault(e => e?.id === sezonId)?.isim ?? "Genel",
            sezonId: sezonId,
            calismaOranNormal: Math.ceil(100 * (katildigiCalisma + gecKatildigiCalisma) / (katildigiCalisma + gecKatildigiCalisma + gelmedigiCalisma)),
            calismaOranGecKalmali: Math.ceil((100 * katildigiCalisma + 77 * gecKatildigiCalisma) / (katildigiCalisma + gecKatildigiCalisma + gelmedigiCalisma)),
            calismaGeldigi: katildigiCalisma,
            calismaGecKaldigi: gecKatildigiCalisma,
            calismaToplam: katildigiCalisma + gecKatildigiCalisma + gelmedigiCalisma,
            sarkiOran: Math.ceil(100 * basariliSarki / sarkilarBySezon[sezonId].length),
            sarkiBasarili: basariliSarki,
            sarkiToplam: sarkilarBySezon[sezonId].length,
        }
    }

    function generateIKullaniciSezonDataFromIKullaniciSezonDataList(userId: string, sezonId: string, baseSezonDataList: IKullaniciSezonData[]): IKullaniciSezonData {
        return {
            userId: userId,
            sezonName: "Genel",
            sezonId: sezonId,
            calismaOranNormal: Math.ceil(baseSezonDataList.sum(e => e.calismaOranNormal) / baseSezonDataList.length),
            calismaOranGecKalmali: Math.ceil(baseSezonDataList.sum(e => e.calismaOranGecKalmali) / baseSezonDataList.length),
            calismaGeldigi: 0,
            calismaGecKaldigi: 0,
            calismaToplam: 0,
            sarkiOran: Math.ceil(baseSezonDataList.sum(e => e.sarkiOran) / baseSezonDataList.length),
            sarkiBasarili: 0,
            sarkiToplam: 0,
        }
    }

    function generateSesGrupGenelOrtalama(userId: string, baseDataList: IKullaniciData[]): IKullaniciSezonData {
        const genelOrtalamalar = baseDataList.where(e => (e.genelOrtalama.calismaGeldigi + e.genelOrtalama.calismaGecKaldigi) !== 0).select(e => e.genelOrtalama);
        return generateIKullaniciSezonDataFromIKullaniciSezonDataList(userId, "genel", genelOrtalamalar);
    }

    function generateSesGrupSezonOrtalamalari(userId: string, baseDataList: IKullaniciData[], sezonlar: SezonModel[]): IKullaniciSezonData[] {
        return sezonlar.map(sezon => {
            const sezonOrtalamalar = baseDataList.where(e => e.sezonlar.any(t => t.sezonId === sezon.id)).select(e => e.sezonlar.single(t => t.sezonId === sezon.id)).where(e => e != null && (e.calismaGeldigi > 0 || e.calismaGecKaldigi > 0));
            return generateIKullaniciSezonDataFromIKullaniciSezonDataList(userId, sezon.id, sezonOrtalamalar);
        })
    }

    function generateKullaniciDataList(kadro: KadroModel | null, sesGrup: SesGrupModel): IKullaniciData[] {
        const userToUse = kadro == null ? eskiKullanicilar : aktifKullanicilar.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, kadro.id));

        return userToUse.where(e => e.sesGrupIdList.contains(sesGrup.id)).map(hesap => {
            const kullaniciSezonDataList = sezonlar.map(sezon => getKullaniciSezonState(hesap, sezon.id));
            return {
                kullanici: `${hesap!.kullaniciModel.ad} ${hesap!.kullaniciModel.soyad}`,
                sesGrupId: BusinessHelper.SesGrubu.HesabinAnaSesGrubunuGetir(genelDurumHelper.SesGruplariniGetir(hesap.sesGrupIdList))!.id,
                sezonlar: kullaniciSezonDataList,
                genelOrtalama: getKullaniciGenelOzetState(hesap!.id, kullaniciSezonDataList)
            };
        });
    }

    function generateSesGrubuData(kadro: KadroModel | null, sezonlar: SezonModel[]): ISesGrubuMainData[] {
        return anaSesGruplari.map(sesGrup => {
            const baseDataList = generateKullaniciDataList(kadro, sesGrup);
            return {
                sesGrupIsim: sesGrup.isim,
                data: baseDataList,
                genelOrtalama: generateSesGrupGenelOrtalama(sesGrup.isim, baseDataList),
                sezonOrtalama: generateSesGrupSezonOrtalamalari(sesGrup.isim, baseDataList, sezonlar),
            }
        })
    }

    return [...kadrolar, null].map(kadro => {
        return {
            kadroIsim: kadro?.isim ?? "Eski Koristler",
            data: generateSesGrubuData(kadro, sezonlar),
            sezonList: sezonlar,
        }
    })
}

