import { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { List, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import Cell from "../../../library/components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import Columns from "../../../library/components/layout/Columns";
import appColors from "../../../app/appColors";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

function RepertuarRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <RepertuarRaporScreenInner genelDurumHelper={genelDurumHelper} />
}

function RepertuarRaporScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const [searchText, setSearchText] = useState<string>("");

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
  const sarkilar = tumSarkilar.orderByTurkish(e => e.isim);

  const sadeceAktifSezonMu = genelDurumHelper.SeciliKoroModel.tumSarkilar ? false : true;

  const sezonlar: (SezonModel | null)[] = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderByDesc(e => e.id === genelDurumHelper.AktifSezon.id ? "_" : e.isim);
  sezonlar.push(null);

  const genelRepSezonStyle: CSSProperties = {
    marginLeft: "5px",
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    borderRightColor: appColors.LIGHTGRAY,
    alignItems: "start",
  };

  const genelRepSezonIsimTextStyle: CSSProperties = {
    alignSelf: "center",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
  };

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
  }
  const arananSarkiMiStyle = (sarki: SarkiModel): CSSProperties | undefined => {
    if (searchText.length < 3)
      return undefined;

    return includesSearchText(sarki.isim) ? { background: appColors.LIGHTYELLOW } : undefined;
  }

  return (
    <MainLayout
      location={mainScreenLocations.repertuarRapor}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <Columns>
          <CsmText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Arama")}: </CsmText>
          <Cell style={{ marginLeft: "10px", marginTop: "5px" }}>
            <CsmTextField narrow value={searchText} onChange={(e) => setSearchText(e)} label={genelDurumHelper.translate("Arama Anahtarı")} eventOnKeyPress />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <CsmAccordionSimple
          title={genelDurumHelper.translate("Genel Repertuvar")}
          visible={!sadeceAktifSezonMu}
          alwaysOpen>
          <Columns>
            {sezonlar.where(e => !!e).map(sezon =>
              <Rows key={sezon?.id} flex style={genelRepSezonStyle}>
                <Cell style={genelRepSezonIsimTextStyle}><CsmText bold>{sezon!.isim}</CsmText></Cell>
                {sarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon!.id)).orderByTurkish(e => e.isim).map(sarki =>
                  <Cell key={sarki.id}><CsmText note style={arananSarkiMiStyle(sarki)}>{sarki.isim}</CsmText></Cell>
                )}
              </Rows>
            )}
          </Columns>
        </CsmAccordionSimple>
        {sezonlar.map(sezon =>
          <CsmAccordionSimple
            key={sezon?.id ?? "digerler"}
            style={{ marginTop: "15px" }}
            title={sezon ? genelDurumHelper.translate("NNN Sezonu", { sezonIsim: sezon.isim }) : genelDurumHelper.translate("Diğer Şarkılar")}
            alwaysOpen={sezon?.id === genelDurumHelper.AktifSezon.id}
            visible={sezon?.id === genelDurumHelper.AktifSezon.id || !sadeceAktifSezonMu}
          >
            <TableContainer>
              <Table size="small" sx={{ flex: 0, minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }} >{genelDurumHelper.translate("Şarkı İsmi")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Süresi")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Divizi")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("MIDI")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Örnek Kayıt")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Dönem")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Tarz")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Ölçü Sayısı")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Dil")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Eşlik")}</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">{genelDurumHelper.translate("Zorluk")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sarkilar.where(e => (sezon == null && e.sezonSarkiModelList.length === 0) || e.sezonSarkiModelList.any(t => t.sezonModelId === StringHelper.nullToEmpty(sezon?.id)))
                    .orderByTurkish(e => sezon?.id === genelDurumHelper.AktifSezon.id ? e.sira.toFixed() : e.isim)
                    .map(sarki =>
                      <TableRow
                        style={arananSarkiMiStyle(sarki)}
                        key={sarki.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                        }}
                      >
                        <TableCell component="th" scope="row">{sezon?.id === genelDurumHelper.AktifSezon.id ? `${sarki.sira}. ${sarki.isim}` : sarki.isim}</TableCell>
                        <TableCell align="right">{(sarki.sure ?? 0) === 0 ? "" : DateTimeToStringHelper.convertSecondsToDurationString(sarki.sure)}</TableCell>
                        <TableCell align="right">{BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, sarki.sesGrupIdList)}</TableCell>
                        <TableCell align="right">{BusinessHelper.SarkiMeta.midiTamMi(sarki) ? "Evet" : "Hayır"}</TableCell>
                        <TableCell align="right">{BusinessHelper.SarkiMeta.sesKayitTamMi(sarki) ? "Evet" : "Hayır"}</TableCell>
                        <TableCell align="right">{sarki.donem}</TableCell>
                        <TableCell align="right">{BusinessHelper.Tarz.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumTarzlar, sarki.tarzModelIdList)}</TableCell>
                        <TableCell align="right">{sarki.olcuSayisi}</TableCell>
                        <TableCell align="right">{sarki.dil}</TableCell>
                        <TableCell align="right">{sarki.eslik}</TableCell>
                        <TableCell align="right">{sarki.zorluk}</TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </CsmAccordionSimple>
        )}
      </List>
    </MainLayout>
  );
}

export default RepertuarRaporScreen;