// AutoGenerated
export class KadroModel {
    isim!: string;
    aktif!: boolean;
    sezonModelId!: string;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}