import appColors from "../../../app/appColors";
import { CsmText } from "../../../library/components/mui/CsmText";
import Cell from "../../../library/components/layout/Cell";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import CsmGradientProgress from "../../../library/components/mui/CsmGradientProgress";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { SarkiZorlukModel } from "../../../models/businessModels/SarkiZorlukModel";

interface ISarkiZorlukInfoBoxProps {
    sarkiModel: SarkiModel,
    sarkiZorlukModel: SarkiZorlukModel | null,
    genelDurumHelper: GenelDurumHelper,
}

export default function SarkiZorlukInfoBox(props: ISarkiZorlukInfoBoxProps) {
    const { sarkiModel, sarkiZorlukModel, genelDurumHelper } = props;

    const currentStats = BusinessHelper.SarkiZorluk.StatOlustur(sarkiModel, sarkiZorlukModel);
 
    const ogrenmeZorlukStatContent = <Rows style={{ marginRight: "10px" }}>
        <CsmText bold>Öğrenme Zorlukları</CsmText>
        <StatLine title="Eser Uzunluğu" note={StringHelper.isEmpty(sarkiModel.olcuSayisi) ? "" : `(${sarkiModel.olcuSayisi} Ölçü)`} value={currentStats.eserUzunluk} />
        <StatLine title="Eser Dili" note={StringHelper.isEmpty(sarkiModel.dil) ? "" : `(${sarkiModel.dil})`} value={currentStats.eserDil} />
        <StatLine title="Armoni/Ton Değişiklikleri" value={currentStats.armoni} />
        <StatLine title="Melodi/Yatay Zorluk" value={currentStats.melodi} />
        <StatLine title="Divizi" value={currentStats.divizi} note={sarkiModel.sesGrupIdList.length === 0 ? "" : `(${BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, sarkiModel.sesGrupIdList)})`} />
    </Rows>

    const soylemeZorlukStatContent = <Rows style={{ marginRight: "10px" }}>
        <CsmText bold>Söyleme Zorlukları</CsmText>
        <StatLine title="Ses Sınırı" value={currentStats.sesSinir} />
        <StatLine title="Nüanslar" value={currentStats.nuans} />
        <StatLine title="Duygu" value={currentStats.duygu} />
        <StatLine title="Nefes/Teknik" value={currentStats.nefesTeknik} />
    </Rows>

    const bars = [
        generateVerticalBar(currentStats?.eserUzunluk, "Eser Uzunluk"),
        generateVerticalBar(currentStats?.eserDil, "Eser Dil"),
        generateVerticalBar(currentStats?.armoni, "Armoni"),
        generateVerticalBar(currentStats?.melodi, "Melodi"),
        generateVerticalBar(currentStats?.divizi, "Divizi"),
        generateVerticalBar(currentStats?.sesSinir, "Ses Sınırı"),
        generateVerticalBar(currentStats?.nuans, "Nüans"),
        generateVerticalBar(currentStats?.duygu, "Duygu"),
    ];

    const genelOrtalamalar = <Rows width={120}>
        <Columns style={{ marginTop: "10px" }}>
            <Rows flex style={{ alignItems: "center" }}>
                <span style={{ borderRadius: "20px", padding: "8px", background: BusinessHelper.SarkiZorluk.GetColorAtPoint10Based(currentStats?.ortalamaOgrenme ?? null) }}>{currentStats?.ortalamaOgrenme?.toFixed(1) ?? "-"}</span>
                <CsmText smallNote>Öğrenme</CsmText>
            </Rows>
            <Rows flex style={{ alignItems: "center" }}>
                <span style={{ borderRadius: "20px", padding: "8px", background: BusinessHelper.SarkiZorluk.GetColorAtPoint10Based(currentStats?.ortalamaSoyleme ?? null) }}>{currentStats?.ortalamaSoyleme?.toFixed(1) ?? "-"}</span>
                <CsmText smallNote>Söyleme</CsmText>
            </Rows>
        </Columns>
    </Rows>

    return <Columns>
        {ogrenmeZorlukStatContent}
        {soylemeZorlukStatContent}
        <Rows>
            <Columns>
                {bars}
            </Columns>
            {genelOrtalamalar}
        </Rows>
    </Columns>
}


function StatLine(props: { title: string, note?: string, value: number | undefined, ortalama?: boolean }) {
    const hasValue = !(props.value === undefined || Number.isNaN(props.value));

    return <Columns>
        <Cell style={{ textAlign: "right" }}>
            <CsmText bold={props.ortalama}>{props.title}: </CsmText>
        </Cell>
        <Cell style={{ marginLeft: "5px" }}>
            <CsmText bold style={{ color: appColors.SECONDARY }}>{!hasValue ? "-" : props.value!.toFixed(1)}</CsmText>
        </Cell>
        <Cell style={{ marginLeft: "5px", marginTop: "1px" }}>
            <CsmText bold style={{ color: hasValue ? appColors.SECONDARY : undefined }} note>{props.note}</CsmText>
        </Cell>
    </Columns>
}


function generateVerticalBar(value: number | undefined, name: string) {
    return <Cell key={name} width={16} height={100}>
        <CsmGradientProgress width={16} height={100} style={{ borderRadius: 0 }} vertical value={(value ?? 0) * 10} />
    </Cell>;
}