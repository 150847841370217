import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import Cell from "../../../library/components/layout/Cell";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import { SarkiYorumModel } from "../../../models/businessModels/SarkiYorumModel";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { WithParentIdRequestModel } from "../../../models/requestModels/WithParentIdRequestModel";
import StringHelper from "../../../library/helpers/StringHelper";

interface ISarkiYorumSarkiPopupEditorProps {
    sarkiModel: SarkiModel,
    sarkiYorumModel: SarkiYorumModel | null,
    closer: (model: SarkiYorumModel | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function SarkiYorumSarkiPopupEditor(props: ISarkiYorumSarkiPopupEditorProps) {
    const [baslik, setBaslik] = useState<string>(StringHelper.nullToEmpty(props.sarkiYorumModel?.baslik));
    const [icerik, setIcerik] = useState<string>(StringHelper.nullToEmpty(props.sarkiYorumModel?.icerik));

    const yorumYapan = props.sarkiYorumModel?.olusturan ?? props.genelDurumHelper.SeciliKullanici.kullaniciAdi;

    const yorumKaydet = async () => {

        const guncellemeMi = props.sarkiYorumModel !== null;

        const sarkiYorumModel = new SarkiYorumModel();
        sarkiYorumModel.id = props.sarkiYorumModel?.id ?? GuidHelper.NewGuid();
        sarkiYorumModel.baslik = baslik;
        sarkiYorumModel.icerik = icerik;
        sarkiYorumModel.olusturan = yorumYapan;
        sarkiYorumModel.olusturmaTarih = props.sarkiYorumModel?.olusturmaTarih ?? DateTimeHelper.now();
        sarkiYorumModel.sarkiModelId = props.sarkiModel.id;

        const requestModel = new WithParentIdRequestModel<SarkiYorumModel>(sarkiYorumModel, props.sarkiModel.id);
        const webResponse = guncellemeMi ?
            await props.genelDurumHelper.requestHelper.SarkiYorum.SarkiYorumGuncelle(requestModel, "Şarkı yorumu güncelleniyor") :
            await props.genelDurumHelper.requestHelper.SarkiYorum.SarkiYorumEkle(requestModel, "Şarkı yorumu ekleniyor");
        if (!webResponse.successfull)
            return;

        props.closer(sarkiYorumModel);
    }

    const screenSize = useScreenWidth();
    const isSmallScreen = screenSize === "Max300";
    const isMediumScreen = screenSize === "Max600";
    const isLargeScreen = screenSize === "Max900";
    const isXLargeScreen = screenSize === "Max1200";

    const minWidth = isSmallScreen ? 250 : isMediumScreen ? 250 : isLargeScreen ? 550 : isXLargeScreen ? 850 : 1150;

    return <Rows flex style={{ minWidth: minWidth }}>
        <Cell style={{ margin: "5px", flexGrow: 1 }}>
            <CsmTextField
                label="Başlık"
                value={baslik}
                showStats
                onChange={e => setBaslik(e)} />
        </Cell>
        <Cell style={{ margin: "5px", flexGrow: 1 }}>
            <CsmTextField
                multiline
                rows={10}
                label="İçerik"
                value={icerik}
                showStats
                eventOnKeyPress
                onChange={e => setIcerik(e)} />
        </Cell>
        <Columns style={{ marginTop: 10 }}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" onClick={() => yorumKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
}

export default SarkiYorumSarkiPopupEditor;