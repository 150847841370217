// AutoGenerated
import { EnmKatilimBildirmeDurum } from "../enums/EnmKatilimBildirmeDurum"

export class SecmeKatilimBildirmeDurumGuncelleRequestModel {
    constructor(secmeBasvuruModelId: string, katilimBildirmeDurum: EnmKatilimBildirmeDurum | null, katilmamaGerekce: string | null) {
        this.secmeBasvuruModelId = secmeBasvuruModelId;
        this.katilimBildirmeDurum = katilimBildirmeDurum;
        this.katilmamaGerekce = katilmamaGerekce;
    }

    secmeBasvuruModelId: string;
    katilimBildirmeDurum: EnmKatilimBildirmeDurum | null;
    katilmamaGerekce: string | null;
}