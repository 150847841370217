// AutoGenerated
import { AltSezonModel } from "../businessModels/AltSezonModel"

export class SezonModel {
    isim!: string;
    altSezonlar!: AltSezonModel[];
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}