// AutoGenerated
import { EnmKayitDurum } from "../enums/EnmKayitDurum"

export class HesapSarkiSesKayitModel {
    hesapModelId!: string;
    sarkiModelId!: string;
    sesGrupId!: string;
    midiEslikVarMi!: boolean;
    midiEslikHizi!: number | null;
    kayitDurum!: EnmKayitDurum;
    yorumlar!: string | null;
    resimIcerikId!: string | null;
    sesIcerikId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}