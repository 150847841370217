/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { alpha, Button } from "@mui/material";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { appIcons } from "../../app/appIcons";
import Columns from "../../library/components/layout/Columns";
import Rows from "../../library/components/layout/Rows";
import { MouseEvent, TouchEvent, useEffect, useRef, useState, WheelEvent } from "react";
import appColors from "../../app/appColors";

interface IImageCropPopupEditorProps {
    base64ImageSource: string,
    genelDurumHelper: GenelDurumHelper,
    closer: (base64ImageSource: string | null) => void,
}

const unitPerPixel = 2;
const cropViewDelikWidth = 200;
const cropViewDelikHeight = 200;
const finalImageWidth = 200;
const finalImageHeight = 200;

type InnerLocationType = { x: number, y: number, id: number };

function ImageCropPopupEditor(props: IImageCropPopupEditorProps) {
    const { closer, base64ImageSource, genelDurumHelper } = props;

    useEffect(() => {
        // if (scale === 0)
        //     myRender(0, 0, 0);
    })

    const dummyRef = useRef<HTMLCanvasElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const mainRef = useRef<HTMLImageElement>(null);
    const boundRef = useRef<HTMLDivElement>(null);
    const prevTouchRef = useRef<HTMLInputElement>(null);

    const [transformX, setTransformX] = useState<number>(0);
    const [transformY, setTransformY] = useState<number>(0);
    const [dragging, setDragging] = useState<boolean>(false);
    const [scale, setScale] = useState<number>(0);

    const cropHataVar = false;

    const kaydet = () => {
        myRender(transformX, transformY, scale, true);
        const ctx = canvasRef.current?.getContext("2d");
        const myCanvas = canvasRef.current!;

        if (!ctx) return;

        const dummyCanvas = dummyRef.current!;
        const newCtx = dummyCanvas.getContext("2d")

        const canvasW = myCanvas.clientWidth;
        const canvasH = myCanvas.clientHeight;

        myCanvas.toDataURL();
        ctx.fillStyle = alpha(appColors.GRAY_LIGHT2, 0.8);

        const x1 = (canvasW / 2) - cropViewDelikWidth / 2;
        const x2 = (canvasW / 2) + cropViewDelikWidth / 2;
        const y1 = (canvasH / 2) - cropViewDelikHeight / 2;
        const y2 = (canvasH / 2) + cropViewDelikHeight / 2;

        newCtx!.drawImage(myCanvas, x1, y1, cropViewDelikWidth, cropViewDelikHeight, 0, 0, finalImageWidth, finalImageHeight);

        const response = dummyCanvas.toDataURL()
        closer(response);

    }

    function scaleToRatio(scale: number) {
        return Math.exp(scale / 20);
    }

    function ratioToScale(ratio: number) {
        return Math.log(ratio) * 20
    }

    function transformScreenToImageCoorPoint(screenCoordinate: number, transformAmount: number, ratio: number, canvasImageRatio: number) {
        // eskisi // return (screenCoordinate - transformAmount * canvasImageRatio) * ratio / unitPerPixel;
        return (screenCoordinate - transformAmount * canvasImageRatio) * ratio / canvasImageRatio;
    }


    function solveTransformPoint(expectedImageCoor: number, screenCoordinate: number, scaleToUse: number, canvasImageRatio: number) {
        const ratioToUse = scaleToRatio(scaleToUse);
        return (screenCoordinate - (expectedImageCoor * canvasImageRatio) / ratioToUse) / canvasImageRatio;
    }

    function transformScreenToImageCoor(x: number, y: number, trX: number, trY: number, givenScale: number, canvasImageRatio: number) {
        const ratioTouse = scaleToRatio(givenScale);

        return {
            x: transformScreenToImageCoorPoint(x, trX, ratioTouse, canvasImageRatio),
            y: transformScreenToImageCoorPoint(y, trY, ratioTouse, canvasImageRatio),
        };
    }

    function solveForNewTxTy(x: number, y: number, oldTrX: number, oldTrY: number, oldScale: number, newScale: number, canvasImageRatio: number) {
        const currentImageCoors = transformScreenToImageCoor(x, y, oldTrX, oldTrY, oldScale, canvasImageRatio);

        return {
            x: solveTransformPoint(currentImageCoors.x, x, newScale, canvasImageRatio),
            y: solveTransformPoint(currentImageCoors.y, y, newScale, canvasImageRatio),
        };
    }

    function myRender(tx: number, ty: number, sc: number, skipCircle: boolean) {
        const ctx = canvasRef.current?.getContext("2d");
        const myCanvas = canvasRef.current!;

        if (!ctx) return;

        const canvasW = myCanvas.clientWidth;
        const canvasH = myCanvas.clientHeight;

        // If it's resolution does not match, change it
        if (myCanvas.width !== canvasW || myCanvas.height !== canvasH) {
            myCanvas.width = canvasW;
            myCanvas.height = canvasH;
        }

        const mainImage = mainRef.current!;
        const canvasImageRatio = canvasRef.current!.width / mainImage.width;

        if (sc === 0) {
            const finalTransX = solveTransformPoint(mainImage.naturalWidth / 2, canvasW / 2, 1, canvasImageRatio);
            const finalTransY = solveTransformPoint(mainImage.naturalHeight / 2, canvasH / 2, 1, canvasImageRatio);

            setScale(1);
            setTransformX(finalTransX);
            setTransformY(finalTransY);
            myRender(finalTransX, finalTransY, 1, skipCircle);
            return;
        }

        const canvasWHRatio = canvasW / canvasH;
        const ratioTouse = scaleToRatio(sc);

        let sX = -tx * ratioTouse;
        let sY = -ty * ratioTouse;
        let sW = (mainImage.naturalWidth) * ratioTouse;
        let sH = (mainImage.naturalWidth / canvasWHRatio) * ratioTouse;

        let dX = 0;
        let dY = 0;
        let dW = canvasW;
        let dH = canvasH;

        if (sX < 0) {
            const horizontalPercent = -sX / sW;
            sW = sW * (1 - horizontalPercent);
            sX = 0;

            dX = dW * horizontalPercent;
            dW = dW * (1 - horizontalPercent);
        }

        if (sY < 0) {
            const horizontalPercent = -sY / sH;
            sH = sH * (1 - horizontalPercent);
            sY = 0;

            dY = dH * horizontalPercent;
            dH = dH * (1 - horizontalPercent);
        }

        ctx.fillStyle = "gray";
        ctx.fillRect(0, 0, canvasW, canvasH);

        ctx.drawImage(mainImage,
            sX, sY, sW, sH,
            dX, dY, dW, dH,
        );

        // ortaya 200 x 200 delik kare
        ctx.fillStyle = alpha(appColors.GRAY_LIGHT2, 0.8);

        const x1 = (canvasW / 2) - cropViewDelikWidth / 2;
        const x2 = (canvasW / 2) + cropViewDelikWidth / 2;
        const y1 = (canvasH / 2) - cropViewDelikHeight  / 2;
        const y2 = (canvasH / 2) + cropViewDelikHeight / 2;
        ctx.fillRect(0, 0, canvasW, y1);
        ctx.fillRect(0, y2, canvasW, y1);
        ctx.fillRect(0, y1, x1, cropViewDelikHeight);
        ctx.fillRect(x2, y1, x1, cropViewDelikHeight);

        if (!skipCircle) {

            ctx.fillStyle = appColors.BLACK;
            ctx.lineWidth = 4;
            ctx.beginPath();
            ctx.ellipse(canvasW / 2, canvasH / 2, 40, 65, 0, 0, 2 * Math.PI);
            //ctx.arc(canvasW / 2, canvasH / 2, 65, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.closePath();
        }
    }

    function handleMoveForTouch(e: TouchEvent<HTMLCanvasElement>): void {
        const newCoors: InnerLocationType[] = [];
        for (let i = 0; i < e.touches.length; i++)
            newCoors.push({ x: e.touches[i].clientX, y: e.touches[i].clientY, id: e.touches[i].identifier });

        const oldCoors: InnerLocationType[] = (window as any).cropTouchCoors;

        // eski koordinat yoksa, bu koordinatı eski olarak sakla
        if (!oldCoors || oldCoors.length === 0) {
            (window as any).cropTouchCoors = newCoors;
            return;
        }

        // tek hareket yaptığını sanıyorsa ama dokunuş id'leri farklı ise, eskisini temizle
        if (newCoors.length === 1 && oldCoors.length === 1)
            if (newCoors[0].id !== oldCoors[0].id) {
                (window as any).cropTouchCoors = newCoors;
                return;
            }

        (window as any).cropTouchCoors = newCoors;

        if (newCoors.length === 1 && oldCoors.length === 1)
            handleMoveWithParams(newCoors[0].x, newCoors[0].y, newCoors[0].x - oldCoors[0].x, newCoors[0].y - oldCoors[0].y);
        else if (newCoors.length > 1 && oldCoors.length > 1)
            handlePinchWithParams(
                newCoors[0].x, newCoors[0].y, newCoors[0].x - oldCoors[0].x, newCoors[0].y - oldCoors[0].y,
                newCoors[1].x, newCoors[1].y, newCoors[1].x - oldCoors[1].x, newCoors[1].y - oldCoors[1].y);
    }

    function startDragging() {
        (window as any).cropTouchCoors = undefined;
        setDragging(true);
    }

    function stopDragging() {
        setDragging(false);
        (window as any).cropTouchCoors = undefined;
    }

    function handleMove(e: MouseEvent<HTMLCanvasElement>): void {
        handleMoveWithParams(e.clientX, e.clientY, e.movementX, e.movementY);
    }

    function handlePinchWithParams(clientX1: number, clientY1: number, movementX1: number, movementY1: number, clientX2: number, clientY2: number, movementX2: number, movementY2: number,): void {
        const bounds = boundRef.current!.getBoundingClientRect();
        const deboundClientX1 = clientX1 - bounds.x;
        const deboundClientY1 = clientY1 - bounds.y;
        const deboundClientX2 = clientX2 - bounds.x;
        const deboundClientY2 = clientY2 - bounds.y;

        const xOldDiff = deboundClientX1 - deboundClientX2;
        const yOldDiff = deboundClientY1 - deboundClientY2;
        const xNewDiff = xOldDiff - movementX1 + movementX2;
        const yNewDiff = yOldDiff - movementY1 + movementY2;

        const oldHypotenuse = Math.sqrt(xOldDiff * xOldDiff + yOldDiff * yOldDiff);
        const newHypotenuse = Math.sqrt(xNewDiff * xNewDiff + yNewDiff * yNewDiff);

        const increasePercent = newHypotenuse / oldHypotenuse;

        const oldRatio = scaleToRatio(scale);
        const newRatio = oldRatio * increasePercent;
        const newScale = ratioToScale(newRatio);

        if (newRatio > 4 || newRatio < 0.2)
            return;

        const mainImage = mainRef.current!;
        const canvasImageRatio = canvasRef.current!.width / mainImage.width;

        const solved = solveForNewTxTy(deboundClientX1 - movementX1, deboundClientY1 - movementY1, transformX, transformY, scale, newScale, canvasImageRatio);

        const finalTransX = solved.x;
        const finalTransY = solved.y;

        // LogInfo(deboundClientX, deboundClientY, finalTransX, finalTransY, newScale, canvasImageRatio);

        setScale(newScale);
        setTransformX(finalTransX);
        setTransformY(finalTransY);
        myRender(finalTransX, finalTransY, newScale, false);
    }

    function handleMoveWithParams(_clientX: number, _clientY: number, _movementX: number, _movementY: number,): void {
        const mainImage = mainRef.current!;
        const canvasImageRatio = canvasRef.current!.width / mainImage.width;

        const finalTransX = transformX + _movementX / canvasImageRatio;
        const finalTransY = transformY + _movementY / canvasImageRatio;

        // const bounds = boundRef.current!.getBoundingClientRect();
        // const deboundClientX = _clientX - bounds.x;
        // const deboundClientY = _clientY - bounds.y;
        // LogInfo(deboundClientX, deboundClientY, finalTransX, finalTransY, scale, canvasImageRatio);

        if (!dragging)
            return;

        setTransformX(finalTransX);
        setTransformY(finalTransY);
        myRender(finalTransX, finalTransY, scale, false);
    }

    function handleWheel(e: WheelEvent<HTMLCanvasElement>): void {
        const bounds = boundRef.current!.getBoundingClientRect();

        const deboundClientX = e.clientX - bounds.x;
        const deboundClientY = e.clientY - bounds.y;

        performZoom(e.deltaY / 100, deboundClientX, deboundClientY);
    }

    function performZoomFromCenter(amount: number) {
        performZoom(amount, boundRef.current!.clientWidth / 2, boundRef.current!.clientHeight / 2);
    }

    function performZoom(amount: number, deboundClientX: number, deboundClientY: number) {
        const newScale = scale + (amount) + 0.01;
        const newRatio = scaleToRatio(newScale);

        if (newRatio > 4 || newRatio < 0.2)
            return;

        const mainImage = mainRef.current!;
        const canvasImageRatio = canvasRef.current!.width / mainImage.width;

        const solved = solveForNewTxTy(deboundClientX, deboundClientY, transformX, transformY, scale, newScale, canvasImageRatio);

        const finalTransX = solved.x;
        const finalTransY = solved.y;

        // LogInfo(deboundClientX, deboundClientY, finalTransX, finalTransY, newScale, canvasImageRatio);

        setScale(newScale);
        setTransformX(finalTransX);
        setTransformY(finalTransY);
        myRender(finalTransX, finalTransY, newScale, false);
    }

    function zoomDefault() {
        const myCanvas = canvasRef.current!;

        const canvasW = myCanvas.clientWidth;
        const canvasH = myCanvas.clientHeight;

        const mainImage = mainRef.current!;
        const canvasImageRatio = canvasRef.current!.width / mainImage.width;

        const finalTransX = solveTransformPoint(mainImage.naturalWidth / 2, canvasW / 2, 1, canvasImageRatio);
        const finalTransY = solveTransformPoint(mainImage.naturalHeight / 2, canvasH / 2, 1, canvasImageRatio);

        setScale(1);
        setTransformX(finalTransX);
        setTransformY(finalTransY);
        myRender(finalTransX, finalTransY, 1, false);
    }


    function LogInfo(deboundClientX: number, deboundClientY: number, transformX: number, transformY: number, scale: number, canvasImageRatio: number) {

        const t = transformScreenToImageCoor(deboundClientX, deboundClientY, transformX, transformY, scale, canvasImageRatio);
        const mainImage = mainRef.current!;
        const myCanvas = canvasRef.current!;
        const canvasW = myCanvas.clientWidth;
        const canvasH = myCanvas.clientHeight;

        console.log("--------------------------------------------------------");
        console.log(`Mouse X: ${deboundClientX.toFixed(0)} --- Mouse Y: ${deboundClientY.toFixed(0)}`);
        console.log(`Image X: ${t.x.toFixed(0)} --- Image Y: ${t.y.toFixed(0)}`);
        console.log(`Trans X: ${transformX.toFixed(0)} --- Trans Y: ${transformY.toFixed(0)}`);
        console.log(`Ratio: ${scaleToRatio(scale).toFixed(2)} Scale: ${scale.toFixed(2)}`);
        console.log(`Image natural W: ${mainImage.naturalWidth} Image natural H: ${mainImage.naturalHeight}`);
        console.log(`Canvas W: ${canvasW}, Canvas H: ${canvasH} `);
        console.log(`CanvasImageRatio: ${canvasImageRatio}`);
    }

    return (
        <Rows height="100%" flex dontGrowForChild style={{ paddingTop: 0 }}>
            <div ref={boundRef} style={{ height: "100%", minHeight: "350px", minWidth: "250px", flex: 1, overflow: "hidden", position: "relative", }}>
                <canvas
                    ref={canvasRef}
                    onMouseMove={e => { handleMove(e); }}
                    onTouchMove={e => { handleMoveForTouch(e); }}
                    onMouseDown={e => { startDragging(); }}
                    onTouchStart={e => { startDragging(); }}
                    onMouseUp={e => { stopDragging(); }}
                    onTouchEnd={e => { stopDragging(); }}
                    onMouseLeave={e => { stopDragging(); }}
                    onTouchCancel={e => { stopDragging(); }}
                    onWheel={e => { handleWheel(e); }}
                    style={{
                        touchAction: "none",
                        width: "100%",
                        left: 0,
                        top: 0,
                        height: "100%",
                        position: "relative",
                        zIndex: 10,
                        background: "rgba(55,0,0, 0.7)"
                    }} />
            </div>
            <div style={{ display: "none", height: 0 }}>
                <img ref={mainRef} src={base64ImageSource} onLoad={() => myRender(0, 0, 0, false)} />
                <canvas
                    ref={dummyRef}
                    width={finalImageWidth}
                    height={finalImageHeight}
                />
            </div>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button size="small" color="info" variant="outlined" onClick={() => performZoomFromCenter(-1)} style={{ flex: 1, margin: 10 }}>
                    {appIcons.Genel.zoomIn}</Button>
                <Button size="small" color="info" variant="outlined" onClick={() => performZoomFromCenter(1)} style={{ flex: 1, margin: 10 }}>
                    {appIcons.Genel.zoomOut}</Button>
                <Button size="small" color="info" variant="outlined" onClick={() => zoomDefault()} style={{ flex: 1, margin: 10 }}>
                    {appIcons.Genel.zoomDefault}</Button>
            </Columns>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={cropHataVar} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
            <input hidden ref={prevTouchRef} />
        </Rows >
    );

}

export default ImageCropPopupEditor;