import { CSSProperties, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { IconButton } from "@mui/material";
import Cell from "../../../library/components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import Columns from "../../../library/components/layout/Columns";
import appColors from "../../../app/appColors";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { appIcons } from "../../../app/appIcons";
import { KonserModel } from "../../../models/businessModels/KonserModel";
import AlertHelper from "../../../library/helpers/AlertHelper";
import KonserPopupViewer from "./KonserPopupViewer";
import KorocanImage from "../../../components/business/KorocanImage";
import StringHelper from "../../../library/helpers/StringHelper";

function KonserRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KonserRaporScreenInner genelDurumHelper={genelDurumHelper} />
}

function KonserRaporScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const sezonlar: SezonModel[] = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderBy(e => e.id === genelDurumHelper.AktifSezon.id ? "_" : e.isim);

  const genelRepSezonStyle: CSSProperties = {
    marginLeft: "5px",
    marginTop: "10px",
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    borderRightColor: appColors.LIGHTGRAY,
    alignItems: "start",
  };

  const genelRepSezonIsimTextStyle: CSSProperties = {
    alignSelf: "center",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
  };

  function linklerTamMi(konser: KonserModel) {
    return konser.konserSarkiModelList.all(e => StringHelper.isNotEmpty(e.videoUrl))
  }

  const konserler = genelDurumHelper.SeciliKoroModel.tumKonserler;

  function openKonser(konser: KonserModel): void {
    AlertHelper.ShowClosableContent<unknown>(`${konser.isim}`, closer => <KonserPopupViewer
      genelDurumHelper={genelDurumHelper}
      konser={konser}
      closer={closer} />)
  }

  return (
    <MainLayout
      location={mainScreenLocations.konserRapor}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows width="100%">
        <CsmAccordionSimple
          bigCenteredTitle
          style={{ width: "100%" }}
          title={genelDurumHelper.translate("Konserler")}
          alwaysOpen>
          <Rows width="100%" dontGrowForChild>
            <Columns style={{ maxHeight: "70%" }} id="osman" wrap dontGrowForChild>
              {sezonlar.map(sezon => {
                const sezonKonserleri = konserler.where(e => e.sezonModelId === sezon!.id).orderBy(e => e.tarih);

                return <Rows key={sezon?.id} style={genelRepSezonStyle}>
                  <Cell style={genelRepSezonIsimTextStyle}><CsmText bold>{sezon!.isim}</CsmText></Cell>
                  {sezonKonserleri.length === 0 && <Cell width={150} height={75} hasLightBottomBorder>
                    <CsmText italic>Bu sezonda konser verilmemiştir</CsmText>
                  </Cell>}
                  {sezonKonserleri.map(konser =>
                    <Rows width={200} height={75} hasLightBottomBorder key={konser.id} style={{ placeItems: "flex-start" }}>
                      <Columns style={{ marginRight: "5px" }}>
                        <Cell width={50} style={{ marginRight: "5px", marginTop: "5px" }}>
                          <KorocanImage
                            title={`${konser.isim} Afişi`}
                            icerikId={konser.resimIcerikId}
                            genelDurumHelper={genelDurumHelper}
                            style={{ maxHeight: "50px", maxWidth: "50px" }} />
                        </Cell>
                        <Columns>
                          <CsmText smallNote>{konser.isim}</CsmText>
                          <IconButton color={linklerTamMi(konser) ? "error" : "primary"} onClick={() => openKonser(konser)}>
                            {linklerTamMi(konser) ? appIcons.Sarki.youtube : appIcons.Genel.info}
                          </IconButton>
                        </Columns>
                      </Columns>
                    </Rows>
                  )}
                </Rows>
              })}
            </Columns>
          </Rows>
        </CsmAccordionSimple>
      </Rows>
    </MainLayout>
  );
}

export default KonserRaporScreen;