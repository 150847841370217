/* eslint-disable @typescript-eslint/no-explicit-any */
import Rows from '../../library/components/layout/Rows';
import { CsmText } from '../../library/components/mui/CsmText';
import { AxisValueFormatterContext, SeriesValueFormatterContext } from '@mui/x-charts/internals';
import { ScatterChart, ScatterValueType } from '@mui/x-charts';
import appColors from '../../app/appColors';
import StringHelper from '../../library/helpers/StringHelper';

export interface IVatanScatterChartData {
    seriesLabel: string,
    data: {
        x: number,
        y: number,
        id: string,
    }[]
}

export interface IVatanScatterChartProps {
    chartLabel?: string,
    xAxisValueFormatter: (key: number, c: AxisValueFormatterContext) => string,
    yAxisValueFormatter?: (key: number, c: AxisValueFormatterContext) => string,
    valueFormatter?: (value: ScatterValueType, c: SeriesValueFormatterContext) => string,
    data: IVatanScatterChartData[],
    height?: number,
    marginForLegend?: number,
    marginForYValues?: number,
    disableXAxisTicks?: boolean,
    disableYAxisTicks?: boolean,
}

export default function VatanScatterChart(props: IVatanScatterChartProps) {

    const { yAxisValueFormatter, disableXAxisTicks, disableYAxisTicks, chartLabel, xAxisValueFormatter, valueFormatter, data, ...rest } = props;

    const { marginForYValues, marginForLegend, height } = rest;

    return (
        <Rows>
            {StringHelper.isNotEmpty(chartLabel) && <CsmText header style={{ alignSelf: "center", marginBottom: "5px" }}>{chartLabel}</CsmText>}
            <ScatterChart
                grid={{ horizontal: true, vertical: true }}
                colors={data.length > 6 ? appColors.colors_LessBright : undefined}
                xAxis={[
                    {
                        disableTicks: disableXAxisTicks,
                        disableLine: true,
                        tickNumber: data.length,
                        tickMaxStep: 1,
                        tickMinStep: 1,
                        valueFormatter: xAxisValueFormatter,
                    },
                ]}
                yAxis={[{
                    disableTicks: disableYAxisTicks,
                    tickMinStep: 1,
                    tickMaxStep: 1,
                    valueFormatter: yAxisValueFormatter,

                }]}
                series={data.map((key) => {
                    return {
                        label: key.seriesLabel,
                        showMark: true,
                        data: key.data,
                        valueFormatter: valueFormatter,
                    }
                })}
                slotProps={{
                    legend: {
                        direction: "column",
                        hidden: false,
                        position: { horizontal: "right", vertical: "middle" }
                    }
                }}
                height={height ?? 300}
                margin={{ top: 5, right: marginForLegend ?? 250, left: marginForYValues ?? 50 }}
            />
        </Rows>
    );
}