/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import React from 'react';
import Columns from './Columns';
import Rows from './Rows';
import Cell from './Cell';

interface IScrollDividedControlProps {
    contentSolUst: React.ReactNode,
    contentUst: React.ReactNode,
    contentSol: React.ReactNode,
    contentOrta: React.ReactNode,
    innerWidth: number,
    innerHeight: number;
}

export default function ScrollDividedControl(props: IScrollDividedControlProps) {
    let sv01h: HTMLDivElement;
    let sv10v: HTMLDivElement;
    // let sv11h: HTMLDivElement;
    let sv11v: HTMLDivElement;
    // let verticalScrolling: boolean = false;
    // let horizontalScrolling: boolean = false;
    let isScrolling = false;

    // const currentHorizontalScroller: string = "";
    // const currentVerticalScroller: string = "";
    // const currentScroller: string = "";

    // const onVerticalScroll = (sender: string) => (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    //     if (verticalScrolling)
    //         return;

    //     if (sender !== currentVerticalScroller)
    //         return;

    //     verticalScrolling = true;
    //     const targetY = 0; //event.nativeEvent.contentOffset.y;

    //     if (sender !== "sv10v") {
    //         sv10v.scrollTo({ left: 0, top: targetY });
    //     }
    //     if (sender !== "sv11v") {
    //         sv11v.scrollTo({ left: 0, top: targetY });
    //     }

    //     verticalScrolling = false;
    // }

    // const onHorizontalScroll = (sender: string) => (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    //     if (horizontalScrolling)
    //         return;

    //     if (sender !== currentHorizontalScroller)
    //         return;

    //     horizontalScrolling = true;
    //     const targetX = 0; //event.nativeEvent.contentOffset.x;

    //     if (sender !== "sv01h")
    //         sv01h.scrollTo({ left: targetX, top: 0 });
    //     if (sender !== "sv11h")
    //         sv11h.scrollTo({ left: targetX, top: 0 });

    //     horizontalScrolling = false;
    // }

    const onVerticalAndHorizontalScroll = (sender: string) => (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (isScrolling) {
            console.log(`${sender} scroll istedi, izin vermedim`)
            return;
        }

        //  if (sender !== currentScroller)
        //      return;

        isScrolling = true;
        const myTarget = event.target as any;
        console.log(`scroll -- source: ${sender} height: ${myTarget.scrollHeight} left: ${myTarget.scrollLeft} width: ${myTarget.scrollWidth}  top: ${myTarget.scrollTop} `)

        const targetY = myTarget.scrollTop;
        const targetX = myTarget.scrollLeft;

        if (sender === "sv01h") // üstteki, yatay scroll
        {
            sv11v.scrollTo({ left: targetX });
        }
        else if (sender === "sv10v") // soldaki, dikey scroll
        {
            sv11v.scrollTo({ top: targetY });
        }
        else if (sender === "sv11v") {
            sv01h.scrollTo({ left: targetX });
            sv10v.scrollTo({ top: targetY });
        }

        isScrolling = false;
    }


    // // const setHorizontalScroller = (scroller: string) => {
    // //     if (!currentHorizontalScroller) {
    // //         currentHorizontalScroller = scroller;
    // //     }
    // // }

    // // const setVerticalScroller = (scroller: string) => {
    // //     if (!currentVerticalScroller) {
    // //         currentVerticalScroller = scroller;
    // //     }
    // // }

    // // const clearHorizontalScroller = (scroller: string) => {
    // //     currentHorizontalScroller = "";
    // // }

    // // const clearVerticalScroller = (scroller: string) => {
    // //     currentVerticalScroller = "";
    // // }

    const { contentSolUst, contentUst, contentSol, contentOrta, innerWidth, innerHeight } = props;

    return (
        <Rows id="ScrollDividedControlCotainer" flex dontGrowForChild>
            <Columns id="ScrollDividedControlTopPart" height={innerHeight} style={{ minHeight: innerHeight, maxHeight: innerHeight }} dontGrowForChild>
                <Cell id="col-sol-ust" width={innerWidth} height={innerHeight} style={{ minHeight: innerHeight, maxHeight: innerHeight, minWidth: innerWidth, maxWidth: innerWidth }}>
                    {contentSolUst}
                </Cell>
                <div id="col-sag-ust"
                    className='hiddenScrollBarContainer'
                    style={{ minWidth: 0, minHeight: innerHeight, maxHeight: innerHeight, height: innerHeight, overflowX: "scroll", overflowY: "hidden", marginRight: "17px" }}
                    onScroll={onVerticalAndHorizontalScroll("sv01h")}
                    ref={(ref) => sv01h = ref!}>
                    {contentUst}
                </div>
            </Columns>
            <Columns id="ScrollDividedBottomCotainer" style={{ overflow: "hidden" }} dontGrowForChild>
                <div id="col-sol-alt"
                    className='hiddenScrollBarContainer'
                    style={{ width: innerWidth, minWidth: innerWidth, maxWidth: innerWidth, minHeight: 0, overflowY: "scroll", overflowX: "hidden", marginBottom: "17px" }}
                    onScroll={onVerticalAndHorizontalScroll("sv10v")}
                    ref={(ref) => sv10v = ref!}>
                    {contentSol}
                </div>
                <div id="sv11vScroll"
                    style={{ minWidth: 0, minHeight: 0, overflowX: "scroll", overflowY: "scroll" }}
                    onScroll={onVerticalAndHorizontalScroll("sv11v")}
                    ref={(ref) => sv11v = ref!}>
                    {contentOrta}
                </div>
            </Columns>
        </Rows>
    );

}