/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import React from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { Badge, Button, IconButton, ListItem, MenuItem, Paper, Typography, alpha } from "@mui/material";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import appColors from "../../../app/appColors";
import { appIcons } from "../../../app/appIcons";
import { CsmText } from "../../../library/components/mui/CsmText";
import Cell from "../../../library/components/layout/Cell";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import { mainScreenLocations } from "../../../app/mainScreens";
import IcerikHelper from "../../../helpers/IcerikHelper";
import AlertHelper from "../../../library/helpers/AlertHelper";
import PdfDisplayer from "../../../components/media/PdfDisplayer";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import KoristSarkiYuklemePopupEditor from "./KoristSarkiYuklemePopupEditor";
import StringHelper from "../../../library/helpers/StringHelper";
import SarkiKunyeDisplayer from "./SarkiKunyeDisplayer";
import CsmIcerikHelper from "../../../library/helpers/CsmIcerikHelper";

function sesGrupYoksaAciklamaGetir(model: SarkiLinkModel, genelDurumHelper: GenelDurumHelper) {
    const result = genelDurumHelper.SeciliKoroModel.tumSesGruplari.singleOrDefault(e => e.id === model.sesGrupId)?.isim;
    return StringHelper.nullToEmpty(result);
}

function midiDinle(item: SarkiModel, navigate: NavigateFunction): void {
    navigate(mainScreenLocations.midiDinle_func(item.id));
}

function audioDinle(sarki: SarkiModel, sarkiLinkModel: SarkiLinkModel | null, navigate: NavigateFunction): void {
    navigate(mainScreenLocations.kayitDinle_func(StringHelper.nullToEmpty(sarkiLinkModel?.id)));
}

function createLinkButton(action: () => void, text: string, icon: React.ReactNode, enabled: boolean, linkId: string | null | undefined, additionalText?: string | null) {
    return <Button key={linkId} disabled={!enabled} sx={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: "5px", textTransform: "none", paddingY: "2px" }} onClick={action}>
        {icon}
        <Columns style={{ alignItems: "flex-start", marginLeft: "3px" }}>
            <CsmText style={{ marginTop: "0px", fontSize: 14 }}>{text}</CsmText>
            <CsmText small note style={{ marginTop: "3px", marginLeft: "3px", textAlign: "left", color: appColors.SECONDARY }}>{additionalText}</CsmText>
        </Columns>
    </Button>
}

function createDiretLink(aciklama: string, icon: React.ReactNode, linkUrl: string, orderNo: number): any {
    return <a key={orderNo} target="_blank" rel="noreferrer" color={appColors.PRIMARY} style={{ display: "flex", fontSize: 14, marginLeft: "10px" }} href={linkUrl}>{icon}{aciklama}</a>
}

async function pdfIndir(sarki: SarkiModel, pdfIcerikId: string, genelDurumHelper: GenelDurumHelper) {
    const base64Content = await IcerikHelper.IcerikGetirDirekt(pdfIcerikId, genelDurumHelper);

    CsmIcerikHelper.DownloadToClient(sarki.isim + ".pdf", CsmIcerikHelper.GetPdfSourceFromBase64Content(base64Content));
}

async function pdfGoster(sarki: SarkiModel, pdfIcerikId: string, genelDurumHelper: GenelDurumHelper) {
    AlertHelper.ShowContent(sarki.isim, <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={pdfIcerikId} />)
}

const kayitYukle = async (sarki: SarkiModel, genelDurumHelper: GenelDurumHelper) => {

    const koristMi = BusinessHelper.Genel.KoristMi(genelDurumHelper.SeciliHesapBilgiModel);
    const kisininSarkiKayitlari = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.sarkiModelId === sarki.id);
    const kisiKayitSonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kisininSarkiKayitlari);
    const eksikHataliKayitVarMi = koristMi && (kisiKayitSonDurum === null || kisiKayitSonDurum === EnmKayitDurum.Hatalı)

    const basariliKayitSesGruplar = BusinessHelper.SesKayit.KisininKayitYuklenemezSesGruplariniGetir(genelDurumHelper, kisininSarkiKayitlari);

    if (eksikHataliKayitVarMi === false) {
        const sesgrupIsimler = basariliKayitSesGruplar.select(e => e.isim).join(', ');
        const message = `Daha önce gönderilen ${sesgrupIsimler} kayıtlarınız onaylı/beklemede olduğu için, yeni kaydınızı ancak başka ses grupları için gönderebilirsiniz. Kayıt yükleme işlemine devam etmek istiyor musunuz?`;

        if (await AlertHelper.Confirm("Dikkat", message) === false)
            return;
    }

    const response = await AlertHelper.ShowClosableContent<WithContentRequestModel<HesapSarkiSesKayitModel>>(sarki.isim + " Kayıt Yükle", (closer) => (
        <KoristSarkiYuklemePopupEditor
            sarkiModel={sarki}
            basariliKayitSesGruplar={basariliKayitSesGruplar}
            hesapBilgiModel={genelDurumHelper.SeciliHesapBilgiModel}
            genelDurumHelper={genelDurumHelper}
            closer={closer}
        />
    ));

    if (response == null)
        return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
        (newGenelDurum) => {
            const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
            const hesapSarkiSesKayitModelList = seciliKoro.hesapSarkiSesKayitModelList;
            hesapSarkiSesKayitModelList.push(response!.model);
        });
}

function DisplayerGroup(props: { shouldRender: boolean, title: string, hasLightTopBorder: boolean, children: React.ReactNode }) {
    if (!props.shouldRender)
        return null;
    return <>
        <CsmText hasLightTopBorder={props.hasLightTopBorder} note italic style={{ marginTop: "5px", paddingTop: "5px", }}>{props.title}</CsmText>
        {props.children}
    </>
}

interface ISarkiKutuDisplayerProps {
    sarki: SarkiModel,
    guncelMi: boolean,
    siraNoGoster: boolean,
    genelDurumHelper: GenelDurumHelper,
    useExpand: boolean,
}

export default function SarkiKutuDisplayer(props: ISarkiKutuDisplayerProps) {
    const { sarki, guncelMi, siraNoGoster, genelDurumHelper } = props;

    const [expanded, setExpanded] = React.useState<boolean | null>(null);

    const koristMi = BusinessHelper.Genel.KoristMi(genelDurumHelper.SeciliHesapBilgiModel);
    const kisininSarkiKayitlari = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.sarkiModelId === sarki.id);
    const kayitArabirimiKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.kayitArabirimiKullanilsin;

    const kisiKayitSonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kisininSarkiKayitlari);
    const eksikHataliKayitVarMi = !kayitArabirimiKullanilsin && koristMi && (kisiKayitSonDurum === null || kisiKayitSonDurum === EnmKayitDurum.Hatalı)
    const guncelVeSikintiliMi = eksikHataliKayitVarMi && sarki.kayitGondermeSurecinde;

    const durumText = !koristMi ? "" : !kisiKayitSonDurum ? "Kayıt Eksik" : (kisiKayitSonDurum! === EnmKayitDurum.Onaylandı ? "Kayıt Onaylandı" : EnmKayitDurum[kisiKayitSonDurum!]);
    const durumRenk = !(koristMi && kayitArabirimiKullanilsin) ? alpha(appColors.GREEN, 1) : kisiKayitSonDurum ? BusinessHelper.SesKayit.KayitDurumRenkGetir(kisiKayitSonDurum, 1) : alpha(appColors.RED, 1);
    const backgroundRenk = durumRenk ? alpha(durumRenk, 0.3) : undefined;
    const backgroundRenkVeryLight = durumRenk ? alpha(durumRenk, 0.1) : undefined;

    const kayitYukleText = !koristMi ? "" : !guncelVeSikintiliMi ? "Kayıt Yükle" : (kisiKayitSonDurum === EnmKayitDurum.Hatalı) ? "Kayıt Yükle (KAYDINIZ HATALI)" : "Kayıt Yükle (KAYDINIZ EKSİK)";
    const durumStyle = !koristMi ? undefined : { color: durumRenk, fontWeight: (kisiKayitSonDurum === null && guncelMi) ? "bold" : undefined };

    const pdfKaydi = sarki.sarkiLinkModelList.firstOrDefault(e => e.linkTip === EnmSarkiLinkTip.PDF);
    const midiVar = sarki.sarkiLinkModelList.any(e => e.linkTip === EnmSarkiLinkTip["Midi Kaydı"]);
    const fullMidiKaydi = sarki.sarkiLinkModelList.firstOrDefault(e => e.linkTip === EnmSarkiLinkTip["Full Midi Kaydı"]);
    const sesKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Ses Kaydı"]).orderBy(e => genelDurumHelper.SeciliKoroModel.tumSesGruplari.singleOrDefault(t => t.id === e.sesGrupId)?.sira);
    const koroKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Konser Videosu"]);
    const baskaKoroKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Başka Koro Videosu"]);

    const sarkiIsim = (siraNoGoster ? `${sarki.sira}. ` : "") + sarki.isim;

    const actualExpanded = !props.useExpand || (expanded ?? false);

    const allKonserSarki = genelDurumHelper.SeciliKoroModel.tumKonserler.selectMany(e => e.konserSarkiModelList.where(t => t.sarkiModelId === sarki.id && StringHelper.isNotEmpty(t.videoUrl)));
    const alakaliKonserler = allKonserSarki.select(e => {
        return {
            konserSarki: e,
            konser: genelDurumHelper.SeciliKoroModel.tumKonserler.single(t => t.id === e.konserModelId),
        }
    }).orderByDesc(e => e.konser.tarih);

    function kunyeGoster(sarki: SarkiModel): void {
        AlertHelper.ShowClosableContent(`${sarki.isim} Şarkısının Künye Bilgileri`, (closer) => (
            <SarkiKunyeDisplayer
                genelDurumHelper={genelDurumHelper}
                closer={() => closer(null)}
                sarkiModel={sarki}
            />
        ));
    }

    return (
        <Paper key={sarki.id} style={{ margin: "10px" }}>
            <Badge color="error" badgeContent={(koristMi && guncelVeSikintiliMi && kayitArabirimiKullanilsin) ? "!" : null}>
                <Rows width={320}>
                    <ListItem divider style={{ justifyContent: "center", background: backgroundRenk }}>
                        <Columns flex>
                            <Cell width={20}>
                                <IconButton onClick={() => kunyeGoster(sarki)}>
                                    {appIcons.Genel.info}
                                </IconButton>
                            </Cell>
                            <Cell flex style={{ display: "flex", justifyContent: "center" }}>
                                <MenuItem component={Link}
                                    to={mainScreenLocations.sarkilarDetay_func(sarki.id)}
                                    onClick={() => genelDurumHelper.navigate(mainScreenLocations.sarkilarDetay_func(sarki.id))}
                                    sx={{ textDecoration: "underline", padding: "3px", textTransform: "none", fontSize: "16px", fontWeight: "bold" }}
                                >
                                    {sarkiIsim}
                                </MenuItem>
                                {/* <Button LinkComponent={Link} variant="text" onClick={() => sarkiKayitlari(sarki, genelDurumHelper.navigate)} sx={{ textDecoration: "underline", padding: "3px", textTransform: "none", fontSize: "16px", fontWeight: "bold" }}>{sarkiIsim}</Button> */}
                            </Cell>
                            {!props.useExpand ? null :
                                <IconButton onClick={() => setExpanded(!actualExpanded)}>{actualExpanded ? appIcons.Genel.expandMore : appIcons.Genel.expandLess}</IconButton>}
                        </Columns>
                    </ListItem>
                    {(!actualExpanded || !koristMi || kayitArabirimiKullanilsin === false) ? null :
                        <Columns hasLightBottomBorder style={{ background: backgroundRenkVeryLight }}>
                            <Typography style={{ marginLeft: "10px", marginTop: "5px", fontSize: "12px", fontStyle: "italic", ...durumStyle }}>Durum: {durumText}</Typography>
                            <Cell flex></Cell>
                            {kisininSarkiKayitlari.length === 0 ? null : <CsmText note style={{ marginTop: "5px", marginRight: "5px", fontSize: "12px" }} >{`Toplam ${kisininSarkiKayitlari.length} kayıt`}</CsmText>}
                        </Columns>
                    }
                    {!actualExpanded ? null :
                        <Rows style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                            <DisplayerGroup title={genelDurumHelper.translate("PDF")} hasLightTopBorder={false} shouldRender>
                                <Columns>
                                    <Cell flex>{createLinkButton(() => pdfGoster(sarki, pdfKaydi?.icerikId!, genelDurumHelper), genelDurumHelper.translate("PDF Göster"), appIcons.Sarki.pdf, !!pdfKaydi, pdfKaydi?.icerikId)}</Cell>
                                    <Cell flex>{createLinkButton(() => pdfIndir(sarki, pdfKaydi?.icerikId!, genelDurumHelper), genelDurumHelper.translate("PDF İndir"), appIcons.Genel.indir, !!pdfKaydi, pdfKaydi?.icerikId)}</Cell>
                                </Columns>
                            </DisplayerGroup>
                            <DisplayerGroup title={genelDurumHelper.translate("Midi Kayıtları")} hasLightTopBorder shouldRender>
                                <Columns>
                                    <Cell flex>{createLinkButton(() => midiDinle(sarki, genelDurumHelper.navigate), genelDurumHelper.translate("Midi Dinle"), appIcons.Sarki.equilazerMini, midiVar, sarki.id)}</Cell>
                                    <Cell flex>{createLinkButton(() => audioDinle(sarki, fullMidiKaydi, genelDurumHelper.navigate), genelDurumHelper.translate("Full Midi Dinle"), appIcons.Sarki.notaMini, !!fullMidiKaydi, fullMidiKaydi?.icerikId)}</Cell>
                                </Columns>
                            </DisplayerGroup>
                            <DisplayerGroup title={genelDurumHelper.translate("Örnek Ses Kayıtları")} hasLightTopBorder shouldRender={sesKayitlari.length > 0}>
                                {sesKayitlari.map(e => createLinkButton(() => audioDinle(sarki, e, genelDurumHelper.navigate), genelDurumHelper.translate("SESGRUP Ses Kaydı Dinle", { sesGrupIsim: sesGrupYoksaAciklamaGetir(e, genelDurumHelper) }), appIcons.Sarki.oynatMini, true, e.icerikId, StringHelper.isEmpty(e.sesGrupId) ? e.aciklama : ""))}
                            </DisplayerGroup>
                            <DisplayerGroup title={genelDurumHelper.translate("Konser Videoları")} hasLightTopBorder shouldRender={alakaliKonserler.length > 0}>
                                {alakaliKonserler.map((e, i) => createDiretLink(e.konser.isim, appIcons.Sarki.youtubeMini, e.konserSarki.videoUrl!, i))}
                            </DisplayerGroup>
                            <DisplayerGroup title={genelDurumHelper.translate("Konser Videoları (Diğer)")} hasLightTopBorder shouldRender={koroKayitlari.length > 0}>
                                {koroKayitlari.map((e, i) => createDiretLink(e.aciklama!, appIcons.Sarki.youtubeMini, e.linkUrl!, i))}
                            </DisplayerGroup>
                            <DisplayerGroup title={genelDurumHelper.translate("Başka Koro Kayıtları")} hasLightTopBorder shouldRender={baskaKoroKayitlari.length > 0}>
                                {baskaKoroKayitlari.map((e, i) => createDiretLink(e.aciklama!, appIcons.Sarki.youtubeMini, e.linkUrl!, i))}
                            </DisplayerGroup>
                            {kayitArabirimiKullanilsin &&
                                <DisplayerGroup title={genelDurumHelper.translate("Kayıt Yükleme")} hasLightTopBorder shouldRender={koristMi}>
                                    {createLinkButton(() => kayitYukle(sarki, genelDurumHelper), kayitYukleText, appIcons.Genel.fileUpload, true, "KAYITYUKLE")}
                                    {/* <Button size="small" variant="contained" color="info" onClick={() => kayitYukle(sarki, genelDurumHelper, requestHelper, dispatch, user)}>Kayıt Yükle</Button> */}
                                </DisplayerGroup>
                            }
                        </Rows>
                    }
                </Rows>
            </Badge>
        </Paper>
    );
}