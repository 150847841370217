/* eslint-disable no-prototype-builtins */
// cihan
import LocalStorageHelper, { IFileInfo } from "./LocalStorageHelper";

export default class StorageHelper {
    public static HasFreeSpace(writeToConsole?: boolean) {
        let _lsTotal = 0,
            _xLen, _x;
        for (_x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) {
                continue;
            }
            _xLen = ((localStorage[_x].length + _x.length) * 2);
            _lsTotal += _xLen;
            if (writeToConsole)
                console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
        }
        if (writeToConsole)
            console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");

        return _lsTotal < 4500000;
    }

    public static GetFileExtesion(fileName: string): string {
        if (!fileName)
            return "";

        const dotPoint = fileName.lastIndexOf(".");
        if (dotPoint === -1)
            return "";
        else
            return fileName.substring(dotPoint);
    }

    public static GetFileNameWithoutExtesion(fileName: string): string {
        if (!fileName)
            return "";

        const slashPoint = fileName.lastIndexOf("/");
        if (slashPoint !== -1)
            fileName = fileName.substring(slashPoint);

        const dotPoint = fileName.lastIndexOf(".");
        if (dotPoint === -1)
            return fileName;
        else
            return fileName.substring(0, dotPoint);
    }

    public static async DeleteFile(fileName: string): Promise<void> {
        const path = StorageHelper.GetDocumentPath(fileName);

        if (await LocalStorageHelper.exists(path))
            await LocalStorageHelper.unlink(path);
    }

    public static GetDocumentPath(fileName: string) {
        return LocalStorageHelper.DocumentDirectoryPath + "/" + fileName;
    }

    public static GetAllFileInfos(): IFileInfo[] {
        return LocalStorageHelper.readDir(LocalStorageHelper.DocumentDirectoryPath + "/");
    }

    public static SaveFile(fileName: string, content: string) {
        const path = StorageHelper.GetDocumentPath(fileName);

        return LocalStorageHelper.writeFile(path, content);
    }

    public static async SaveFileBase64(fileName: string, content: string) {
        const path = StorageHelper.GetDocumentPath(fileName);

        return LocalStorageHelper.writeFile(path, content);
    }

    public static LoadFile(fileName: string): string {
        const path = StorageHelper.GetDocumentPath(fileName);

        return LocalStorageHelper.readFile(path)!;
    }

    public static FileExists(fileName: string): boolean {
        const path = StorageHelper.GetDocumentPath(fileName);

        return LocalStorageHelper.exists(path);
    }

    public static async LoadFileBase64(fileName: string): Promise<string> {
        const path = StorageHelper.GetDocumentPath(fileName);

        return await StorageHelper.LoadFileBase64External(path);
    }

    public static async LoadFileBase64External(path: string): Promise<string> {
        return await LocalStorageHelper.readFile(path)!;
    }

    public static LoadFileBase64External_DirectForWeb(path: string): string | null {
        return LocalStorageHelper.readFile(path);
    }

    public static GetFiles(pattern: string): string[] {
        const result = LocalStorageHelper.readDir(LocalStorageHelper.DocumentDirectoryPath);
        return result.select(e => e.name).where(e => e.endsWith(pattern));
    }

    public static async GetUnexistingFiles(keys: string[]): Promise<string[]> {
        return new Promise<string[]>(response => {
            const promises = keys.map((a) => LocalStorageHelper.exists(StorageHelper.GetDocumentPath(a)));

            Promise.all(promises).then((allResponses) => {
                const result: string[] = [];
                for (let i = 0; i < allResponses.length; i++)
                    if (allResponses[i] === false)
                        result.push(keys[i]);

                response(result);
            });
        });
    }
    /*
        public static async StoreStorageItem(key: any, item: any) {
            try {
                var jsonOfItem = await LocalStorageHelper.setCacheItem(key, JSON.stringify(item), (error) => { debugger; });
                return jsonOfItem;
            } catch (error) {
                console.log(error.message);
            }
        }
    
        public static async RemoveAllCacheStorageItems(): Promise<void> {
            await LocalStorageHelper.clearCache();
        }
    
        public static async RemoveCacheStorageItem(key: any): Promise<void> {
            await LocalStorageHelper.removeCacheItem(key);
        }*/
}