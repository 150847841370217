import { Chip, FormControl, InputLabel, MenuItem, Select, SxProps, Theme } from "@mui/material";
import { CSSProperties, useState } from "react";
import { CsmText } from "../../library/components/mui/CsmText";
import Columns from "../../library/components/layout/Columns";
import { AltSezonModel } from "../../models/businessModels/AltSezonModel";
import StringHelper from "../../library/helpers/StringHelper";

export interface IVatanAltSezonSelectFieldProps {
    id?: string,
    value: string | null,
    label: string,
    tumAltSezonlar: AltSezonModel[],
    onChange: (e: string) => void,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
}

export default function VatanAltSezonSelectField(props: IVatanAltSezonSelectFieldProps) {
    const { id, value, label, tumAltSezonlar, onChange, error, fontSize, sx, disabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;
    const [open, setOpen] = useState(false);

    const list = (tumAltSezonlar ?? []).orderByTurkish(e => e.isim);

    const chipClicked = (newId: string) => {
        if (value !== newId)
            onChange(newId);
        handleClose();
    }

    const getChipMenuItem = (sezon: AltSezonModel, isSelected: boolean): React.ReactNode => {
        return <Chip
            key={sezon.id}
            label={sezon.isim}
            onClick={() => { chipClicked(sezon.id) }}
            color={isSelected ? "primary" : undefined}
            style={{ margin: "5px", cursor: "pointer", display: "flex" }}
        />;
        // return <MenuItem style={{ display: "flex" }} key={sezon.id} value={sezon.id}>
        //     <Chip style={{ display: "flex"}} key={sezon.id} label={sezon.isim} />
        // </MenuItem>
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            <Select
                defaultValue=""
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                native={false}
                disabled={disabled}
                sx={sx}
                inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                error={error}
                labelId={id}
                MenuProps={{ MenuListProps: { style: { display: "flex", flexWrap: "wrap" } } }}
                id={id}
                value={StringHelper.nullToEmpty(value)}
                label={label}
                renderValue={() => <CsmText>{list.singleOrDefault(e => e.id === value)?.isim}</CsmText>}
                onChange={(e) => { onChange(e.target.value); }}>
                {list.map(e => <MenuItem key={e.id} value={e.id} style={{ display: "none" }} />)}
                <Columns flex wrap>
                    {list.map(e => getChipMenuItem(e, e.id === value))}
                </Columns>
            </Select>
        </FormControl>
    );
}
