// AutoGenerated
import { EnmSarkiLinkTip } from "../enums/EnmSarkiLinkTip"

export class SarkiLinkModel {
    linkTip!: EnmSarkiLinkTip;
    sesGrupId!: string | null;
    linkUrl!: string | null;
    aciklama!: string | null;
    icerikId!: string | null;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}