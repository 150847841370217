/* eslint-disable react/no-children-prop */
// cihan
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { appIcons } from "../../../app/appIcons";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import YoklamaCalismaPopupEditor from "./YoklamaCalismaPopupEditor";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import appColors from "../../../app/appColors";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import { HesapCalismaModel } from "../../../models/businessModels/HesapCalismaModel";
import { EnmKatilimBildirmeDurum } from "../../../models/enums/EnmKatilimBildirmeDurum";
import { EnmKatilimDurum } from "../../../models/enums/EnmKatilimDurum";
import Columns from "../../../library/components/layout/Columns";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

function YoklamaYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <YoklamaYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function YoklamaYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const calismaYoklamaDoldur = async (model: CalismaModel) => {
    const response = await AlertHelper.ShowClosableContent<HesapCalismaModel[]>("Yoklama Doldur", (closer) => (
      <YoklamaCalismaPopupEditor
        calisma={model}
        kullanicilar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e))}
        hesapCalismaModelList={genelDurumHelper.SeciliKoroModel.hesapCalismaModelList}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        seciliKoro.hesapCalismaModelList = response!;
      });
  }

  const calismaRowGetirInner = (calismaList: CalismaModel[]) => {
    if (calismaList.length === 0)
      return null;

    return calismaList.map((item) => {
      const alakaliBildirimler = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.where(e => e.calismaModelId === item.id);

      const gelmesiGerekenler = BusinessHelper.Calisma.CalismayaUygunKisileriGetir(item, genelDurumHelper.SeciliKoroModel.hesapBilgiList, item.sezonModelId !== genelDurumHelper.AktifSezon.id);

      const girilmisYoklamaSayisi = alakaliBildirimler.count(e => e.derseGercekKatilimDurum !== null);
      const girilmemisYoklamaSayi = gelmesiGerekenler.length - girilmisYoklamaSayisi;

      const tahminiKatilimMinSayi = alakaliBildirimler.count(e => e.katilimDurum === EnmKatilimBildirmeDurum.Katılacağım);
      const tahminiKatilimMaxSayi = gelmesiGerekenler.length - alakaliBildirimler.count(e => e.katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum);
      const tahminiKatilimMinOran = ((tahminiKatilimMinSayi * 100) / (gelmesiGerekenler.length)).toFixed();
      const tahminiKatilimMaxOran = ((tahminiKatilimMaxSayi * 100) / (gelmesiGerekenler.length)).toFixed();

      const tarihTitle = `${DateTimeToStringHelper.toMonthDayAndDayOfWeekString(item.tarihBaslangic, genelDurumHelper.translate)} / ${DateTimeToStringHelper.toTimeString(item.tarihBaslangic)} - ${DateTimeToStringHelper.toTimeString(item.tarihBitis)}`;

      let finalMessage: string;
      if (girilmisYoklamaSayisi === 0) {
        const bildirimYapmayanlar = gelmesiGerekenler.where(e => alakaliBildirimler.singleOrDefault(t => t.hesapModelId === e.id) == null);
        if (tahminiKatilimMaxOran === tahminiKatilimMinOran)
          finalMessage = `Tahmini katılım oranı %${tahminiKatilimMinOran} (Bildirmeyen ${bildirimYapmayanlar.length} korist)`;
        else
          finalMessage = `Tahmini katılım oranı %${tahminiKatilimMinOran} - %${tahminiKatilimMaxOran} (Bildirmeyen ${bildirimYapmayanlar.length} korist)`;
      }
      else {
        const tumGercekBildirimler = alakaliBildirimler.where(e => e.derseGercekKatilimDurum !== null);
        const gelenSayi = tumGercekBildirimler.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı || e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]);

        finalMessage = `Katılım oranı %${((gelenSayi * 100) / tumGercekBildirimler.length).toFixed()}`;
        if (girilmemisYoklamaSayi > 0)
          finalMessage = `${finalMessage} (${girilmemisYoklamaSayi} yoklama girilmemiş)`;
      }

      const rightButtons: ICustomSwipeButton[] = [];
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => calismaYoklamaDoldur(item) });

      return (
        <CsmSwipableListItem hasLightBottomBorder key={item.id} leftButtons={rightButtons} style={{ width: "490px" }}>
          <Rows>
            <CsmText>{tarihTitle}</CsmText>
            <CsmText note style={{ color: appColors.PRIMARY }}>{`${genelDurumHelper.translate("Hedef Kitle")}: ` + BusinessHelper.Genel.HedefKitleTextGetir(item.kadroIdList, item.sesGrupIdList, genelDurumHelper.SeciliKoroModel.tumKadrolar, genelDurumHelper.SeciliKoroModel.tumSesGruplari)}</CsmText>
            <CsmText note>{item.icerik}</CsmText>
            <CsmText note>{finalMessage}</CsmText>
          </Rows>
        </CsmSwipableListItem>
      )
    });
  }

  const calismaListGetir = (calismalar: CalismaModel[]) => {
    if (calismalar.length === 0)
      return null;

    const ayBaslari = calismalar.select(e => e.tarihBaslangic).select(e => DateTimeHelper.getFirstOfMonth(e).valueOf()).distinct().select(e => new Date(e));

    return [<Rows key="onlyRow">
      {ayBaslari.map(tarih => <Rows key={tarih.toDateString()}>
        <CsmSwipableListItem hasLightBottomBorder style={{ fontStyle: "italic", width: "490px" }}>
          <CsmText italic>{DateTimeToStringHelper.getMonthName(tarih.getMonth() + 1)} - {tarih.getFullYear()}</CsmText>
        </CsmSwipableListItem>
        <Columns wrap>
          {calismaRowGetirInner(calismalar.where(e => e.tarihBaslangic.getFullYear() === tarih.getFullYear() && e.tarihBaslangic.getMonth() === tarih.getMonth()))}
        </Columns>
      </Rows>
      )}
    </Rows>];
  }

  const today = DateTimeHelper.today();

  const guncelCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, today)).orderBy(e => e.tarihBaslangic);
  const gecmisCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => DateTimeHelper.getDateWithoutTime(e.tarihBaslangic) < today).orderBy(e => e.tarihBaslangic);

  const aktifSezon = genelDurumHelper.SeciliKoroModel.tumSezonlar.single(e => e.id === genelDurumHelper.SeciliKoroModel.sezonModelId);

  const eskiCalismaSezonIdler = gecmisCalismalar
    .select(e => e.sezonModelId)
    .distinct()
    .orderByDesc(e => genelDurumHelper.SeciliKoroModel.tumSezonlar.singleOrDefault(t => t.id === e)?.isim)
    .where(e => e !== aktifSezon.id);

  const sezonTitleGetir = (sezonId: string) => {
    const sezon = genelDurumHelper.SeciliKoroModel.tumSezonlar.single(t => t.id === sezonId);
    const sezonCalismalar = gecmisCalismalar.where(e => e.sezonModelId === sezonId);

    const ilkTarih = sezonCalismalar.min(e => e.tarihBaslangic)!;
    const sonTarih = sezonCalismalar.max(e => e.tarihBaslangic)!;

    return `${sezon.isim} Çalışmaları (${DateTimeToStringHelper.toMonthAndYearString(ilkTarih, genelDurumHelper.translate)} - ${DateTimeToStringHelper.toMonthAndYearString(sonTarih, genelDurumHelper.translate)} / ${sezonCalismalar.length} Çalışma)`;
  }

  return (
    <MainLayout
      location={mainScreenLocations.yoklamaGiris}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <CsmAccordionSimple
          title="Güncel Çalışmalar"
          children={calismaListGetir(guncelCalismalar)}
          emptyNote="Güncel Çalışma bulunmamaktadır"
          alwaysOpen
        />
        <CsmAccordionSimple
          title={sezonTitleGetir(aktifSezon.id)}
          children={calismaListGetir(gecmisCalismalar.where(e => e.sezonModelId === aktifSezon.id).orderByDesc(e => e.tarihBaslangic))}
          emptyNote="Çalışma Bulunmamaktadır"
          alwaysOpen
        />
        {eskiCalismaSezonIdler.map(sezonId => (
          <CsmAccordionSimple
            key={sezonId}
            title={sezonTitleGetir(sezonId)}
            children={() => calismaListGetir(gecmisCalismalar.where(e => e.sezonModelId === sezonId).orderByDesc(e => e.tarihBaslangic))}
            emptyNote="Çalışma Bulunmamaktadır"
          />
        ))}
      </Rows>
    </MainLayout>
  );
}

export default YoklamaYonetimScreen;