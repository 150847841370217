import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";

export interface ICustomContentDialogProps {
  titleMessage: string;
  content: JSX.Element | null;
  open: boolean;
  onClose: (() => void) | null;
  useFlex: boolean,
}

export default function CustomContentDialog(props: ICustomContentDialogProps) {
  return props.open === true ? (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose != null ? props.onClose : (() => { })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.titleMessage}</DialogTitle>
      <DialogContent id="alert-dialog-description" style={props.useFlex ? { overflow: "hidden", display: "flex" } : undefined}>
        {props.content}
      </DialogContent>
      <DialogActions>
        <Button color="info" variant="contained" onClick={props.onClose != null ? props.onClose : (() => { })} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
          Tamam</Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
