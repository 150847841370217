const _charMapping: Record<string, string> = {
    "Ç": "C",
    "Ğ": "G",
    "İ": "I",
    "Ö": "O",
    "Ş": "S",
    "Ü": "U",
    "ç": "c",
    "ğ": "g",
    "ı": "i",
    "ö": "o",
    "ş": "s",
    "ü": "u",
}

const StringHelper = {
    isEmpty: (value: string | null | undefined) => {
        if (value === null || value === undefined || value.length === 0)
            return true;
        return false;
    },
    isNotEmpty: (value: string | null | undefined) => {
        return !StringHelper.isEmpty(value);
    },
    emptyToValue: (value: string | null | undefined, alternativeValue: string) => {
        if (value === null || value === undefined || value.length === 0)
            return alternativeValue;
        else
            return value!;
    },
    formatPhone: (value: string | null | undefined) => {
        if (value === null || value === undefined || value.length === 0)
            return "";

        if (value.length === 10)
            return `0 ${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(6, 10)}`;
        else if (value.length === 11 && value.startsWith("0"))
            return `0 ${value.substring(1, 3)} ${value.substring(4, 7)} ${value.substring(7, 11)}`;
        else
            return value;
    },
    emptyToDash: (value: string | number | null | undefined) => {
        if (value === null || value === undefined || value === "")
            return "-";
        else
            return value!;
    },
    nullToEmpty: (value: string | null | undefined) => {
        if (value) return value;
        else if (value === null || value === undefined || value.length === 0)
            return "";
        else
            return value!;
    },
    containsFilter: (value: string | null | undefined, filter: string | null | undefined) => {
        if (StringHelper.isEmpty(value) || StringHelper.isEmpty(filter))
            return false;

        const loweredValue = value!.toLocaleLowerCase("tr-tr");
        const loweredFilter = filter!.toLocaleLowerCase("tr-tr");
        return loweredValue.indexOf(loweredFilter) >= 0;
    },
    Turkish: {
        ConvertToEnglishLowerCase: (input: string) => {
            if (input == null) return "";
            const lowered = input.toLocaleLowerCase("tr-tr");
            let result = "";

            for (let i = 0; i < lowered.length; i++) {
                if (lowered[i] in _charMapping)
                    result = result + _charMapping[lowered[i]];
                else
                    result = result + lowered[i];
            }

            return result;
        },
        ToPascalCase: (input: string): string => {
            if (StringHelper.isEmpty(input))
                return "";

            if (input.indexOf(" ") === -1) {
                if (input.length === 1)
                    return input[0].toLocaleUpperCase("tr-tr");
                else
                    return input[0].toLocaleUpperCase("tr-tr").concat(input.substring(1, input.length).toLocaleLowerCase("tr-tr"));
            }
            const items = input.split(" ");

            return items.select(e => StringHelper.Turkish.ToPascalCase(e)).join(" ");
        }
    },

}

export default StringHelper;