import { Slider } from "@mui/material";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { EnmCinsiyet } from "../../models/enums/EnmCinsiyet";
import Rows from "../../library/components/layout/Rows";

type IVatanNotaSliderProps = {
    onChange?: (value: number | null) => void,
    value: number | null,
    disabled?: boolean,
    allowNull?: boolean,
    cinsiyet: EnmCinsiyet,
};

const marks = [
    { value: 0, label: 'Do 1', },
    { value: 12, label: 'Do 2', },
    { value: 24, label: 'Do 3', },
    { value: 36, label: 'Do 4', },
    { value: 48, label: 'Do 5', },
    { value: 60, label: 'Do 6', },
    { value: 72, label: 'Do 7', },
];


function VatanNotaSlider(props: IVatanNotaSliderProps) {

    return <Rows>
        <Slider
            disabled={props.disabled}
            min={props.allowNull ? -1 : BusinessHelper.Nota.MinNota}
            step={1}
            max={BusinessHelper.Nota.MaxNota}
            marks={marks}
            sx={{
                '& .MuiSlider-rail': {
                    background: props.cinsiyet === EnmCinsiyet.Kadın ? BusinessHelper.Nota.KadinGradient() : BusinessHelper.Nota.ErkekGradient(),
                    opacity: 1,
                    height: 3,
                },
                '& .MuiSlider-track': {
                    // background: props.cinsiyet === EnmCinsiyet.Kadın ? BusinessHelper.Nota.KadinGradient : BusinessHelper.Nota.ErkekGradient,
                    opacity: 0,
                    height: 3,
                },
            }}
            slotProps={{
                thumb: { style: { background: BusinessHelper.Nota.GetColorAtPoint(props.value, props.cinsiyet) } }
            }}
            value={(props.value === null) ? (props.allowNull ? -1 : 0) : props.value}
            onChange={(e, v) => { if (props.onChange) props.onChange((v as number === -1 ? null : v as number)); }}
        />
    </Rows>
}

export default VatanNotaSlider;