import React from "react";
import CssType from "csstype";
import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import { useTheme } from "@mui/material";

export type ColumnsCSSProperties = Omit<BaseCSSProperties, "height" | "width" | "flex">;
export type ColumnsProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "style"> &
{
    style?: ColumnsCSSProperties,
    width?: CssType.Property.Width<string | number>,
    height?: CssType.Property.Width<string | number>,
    flex?: boolean | CssType.Property.Width<string | number>,
    wrap?: boolean,
    hasLightBottomBorder?: boolean,
    hasLightRightBorder?: boolean,
    dontGrowForChild?: boolean
    behaveAsRows?: boolean,
    dbg_red?: boolean,
    dbg_blue?: boolean,
    dbg_yellow?: boolean,
    ignore?: boolean,
};

export default function Columns(props: ColumnsProps): JSX.Element {
    const { ignore, behaveAsRows, dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, hasLightRightBorder, hasLightBottomBorder, ...rest } = props;

    if (ignore) return <>{children}</>;

    const theme = useTheme();
    const color_GrayLight2 = theme.palette.grey.A400

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const givenStyle: React.CSSProperties = 
    {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        display: "flex",
        flexWrap: wrap ? "wrap" : "unset",
        flexDirection: behaveAsRows ? "column" : "row",
        borderBottom: hasLightBottomBorder ? ("1px solid " + color_GrayLight2) : undefined,
        borderRight: hasLightRightBorder ? ("1px solid " + color_GrayLight2) : undefined,
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
    }
    return (
        <div style={{ ...givenStyle, ...style }} {...rest}>
            {children}
        </div>
    );
}

export function Columns2(props: ColumnsProps): JSX.Element {
    const { ignore, behaveAsRows, dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, hasLightRightBorder, hasLightBottomBorder, ...rest } = props;

    if (ignore) return <>{children}</>;
    
    const theme = useTheme();
    const color_GrayLight2 = theme.palette.grey.A400

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const givenStyle: React.CSSProperties = 
    {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        display: "flex",
        flexWrap: wrap ? "wrap" : "unset",
        flexDirection: behaveAsRows ? "column" : "row",
        borderBottom: hasLightBottomBorder ? ("1px solid " + color_GrayLight2) : undefined,
        borderRight: hasLightRightBorder ? ("1px solid " + color_GrayLight2) : undefined,
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
    }
    return (
        <div style={{ ...givenStyle, ...style }} {...rest}>
            {children}
        </div>
    );
}
