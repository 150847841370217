import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button, Typography, FormControl, Switch } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import CsmNumericField from "../../../library/components/mui/CsmNumericField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import VatanSesGrupMultiSelectField from "../../../components/vatan/VatanSesGrupMultiSelectField";
import VatanSezonSarkiMultiSelectField from "../../../components/vatan/VatanSezonSarkiMultiSelectField";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { SezonSarkiModel } from "../../../models/businessModels/SezonSarkiModel";
import SezonSarkiKayitTarihPopupEditor from "./SezonSarkiKayitTarihPopupEditor";
import VatanTarzField from "../../../components/vatan/VatanTarzField";
import VatanDonemField from "../../../components/vatan/VatanDonemField";
import VatanSanatciField from "../../../components/vatan/VatanSanatciField";
import VatanDilField from "../../../components/vatan/VatanDilField";

interface ISarkiBilgileriPopupEditorProps {
    sarki: SarkiModel,
    guncellenebilir: boolean,
    silinebilir: boolean,
    guncellemeMi: boolean,
    tumSarkilar: SarkiModel[],
    genelDurumHelper: GenelDurumHelper
    closer: (model: SarkiModel | null) => void,
}

function SarkiBilgileriPopupEditor(props: ISarkiBilgileriPopupEditorProps) {
    const [sarkiModel, setSarkiModel] = useState<SarkiModel>(JsonHelper.deepCopy(props.sarki));

    const sarkiBilgiChanged = (action: (model: SarkiModel) => void): void => {
        const dummy = JsonHelper.deepCopy(sarkiModel);
        action(dummy);
        setSarkiModel(dummy);
    }

    let isimHata: string | undefined;
    if (sarkiModel.isim === "")
        isimHata = "Bu alan zorunludur";
    else if (props.tumSarkilar.any(e => e.id !== sarkiModel.id && e.isim === sarkiModel.isim))
        isimHata = "Bu isimde başka bir şarkı bulunmaktadır";

    const sarkiKaydet = async () => {
        if (props.guncellemeMi) {
            const webResponse = await props.genelDurumHelper.requestHelper.Sarki.SarkiGuncelle(sarkiModel, "Şarkı güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await props.genelDurumHelper.requestHelper.Sarki.SarkiEkle(sarkiModel, "Şarkı ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        props.closer(sarkiModel)
    }

    const onKayitTarihleriClick = async () => {
        const response = await AlertHelper.ShowClosableContent2<SezonSarkiModel[]>(`Şarkı Kayıt Tarihlerini Güncelle`, (closer) =>
            <SezonSarkiKayitTarihPopupEditor
                closer={closer}
                sezonSarkiModelList={sarkiModel.sezonSarkiModelList}
                genelDurumHelper={props.genelDurumHelper}
            />
        );

        if (response == null)
            return;

        sarkiBilgiChanged(e => e.sezonSarkiModelList = response);
    }

    return <Rows style={{ minHeight: 500, maxWidth: 1000 }}>
        <Typography style={{ fontWeight: "bold", alignSelf: "center", marginLeft: 5, marginBottom: 10 }}>Temel Bilgiler</Typography>
        <Columns wrap>
            <FormControl style={{ width: 295, marginRight: 5 }}>
                <CsmTextField
                    label="Şarkı İsmi"
                    disabled={!props.guncellenebilir}
                    error={isimHata !== undefined}
                    helperText={isimHata}
                    value={sarkiModel.isim}
                    onChange={(e) => sarkiBilgiChanged(t => t.isim = e)} />
            </FormControl>
            <Rows>
                <FormControl style={{ width: 95, marginRight: 5, display: "flex", flexDirection: "row", marginTop: "-5px" }}>
                    <Rows style={{ marginLeft: "5px" }}>
                        <Switch disabled={!props.guncellenebilir} checked={sarkiModel.kayitGondermeSurecinde} onChange={(e) => sarkiBilgiChanged(t => t.kayitGondermeSurecinde = e.target.checked)} />
                        <Typography sx={{ fontSize: 12, marginTop: "0px" }}>{sarkiModel.kayitGondermeSurecinde ? "Kayıt Sürecinde" : "Kayıt Sürecinde Değil"}</Typography>
                    </Rows>
                </FormControl>
            </Rows>
            <FormControl style={{ width: 100, marginRight: 5 }}>
                <CsmNumericField
                    label="Sıra"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.sira}
                    onChange={(e) => sarkiBilgiChanged(t => t.sira = e ?? 0)} />
            </FormControl>
            <FormControl style={{ marginRight: 5, flex: 1 }}>
                <VatanSezonSarkiMultiSelectField
                    label="Sezonları"
                    style={{ height: "55px" }}
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.sezonSarkiModelList}
                    tumSezonlar={props.genelDurumHelper.SeciliKoroModel.tumSezonlar}
                    onChange={(e) => sarkiBilgiChanged(t => t.sezonSarkiModelList = e)}
                    genelDurumHelper={props.genelDurumHelper}
                    id={sarkiModel.id} />
            </FormControl>
            <FormControl style={{ marginRight: 5, width: 95 }}>
                <Button onClick={() => onKayitTarihleriClick()} size="small" variant="contained" color="info">KAYIT TARİHLERi</Button>
            </FormControl>
        </Columns>
        <Typography style={{ fontWeight: "bold", alignSelf: "center", marginLeft: 5, marginTop: 10 }}>Müzikal Bilgiler</Typography>
        <Columns wrap>
            <VatanSesGrupMultiSelectField
                label="Ses Grupları"
                disabled={!props.guncellenebilir}
                sx={{ height: "90px", alignContent: "flex-start", justifyItems: "flex-start" }}
                style={{ marginTop: "10px" }}
                value={sarkiModel.sesGrupIdList}
                tumSesGruplari={props.genelDurumHelper.SeciliKoroModel.tumSesGruplari}
                onChange={(e) => sarkiBilgiChanged(t => t.sesGrupIdList = e)}
                id={sarkiModel.id} />

            <FormControl style={{ width: 395, marginRight: 5, marginTop: 15 }}>
                <VatanTarzField
                    small
                    label="Tarz"
                    sx={{ height: "55px" }}
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.tarzModelIdList}
                    onChange={e => sarkiBilgiChanged(t => t.tarzModelIdList = e)}
                    genelDurumHelper={props.genelDurumHelper}
                />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <CsmNumericField
                    label="Süre"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.sure}
                    onChange={(e) => sarkiBilgiChanged(t => t.sure = e ?? 0)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Tempo"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.tempo}
                    onChange={(e) => sarkiBilgiChanged(t => t.tempo = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <VatanDonemField
                    label="Dönem"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.donem}
                    onChange={(e) => sarkiBilgiChanged(t => t.donem = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Tarih"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.tarih}
                    onChange={(e: string) => sarkiBilgiChanged(t => t.tarih = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <VatanSanatciField
                    label="Söz Yazarı"
                    genelDurumHelper={props.genelDurumHelper}
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.sozYazari}
                    onChange={(e) => sarkiBilgiChanged(t => t.sozYazari = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <VatanSanatciField
                    label="Besteci"
                    genelDurumHelper={props.genelDurumHelper}
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.besteci}
                    onChange={(e) => sarkiBilgiChanged(t => t.besteci = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <VatanSanatciField
                    label="Düzenleyen"
                    genelDurumHelper={props.genelDurumHelper}
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.duzenleme}
                    onChange={(e) => sarkiBilgiChanged(t => t.duzenleme = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Ölçü Sayısı"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.olcuSayisi}
                    onChange={(e) => sarkiBilgiChanged(t => t.olcuSayisi = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <VatanDilField
                    label="Dil"
                    genelDurumHelper={props.genelDurumHelper}
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.dil}
                    onChange={(e) => sarkiBilgiChanged(t => t.dil = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Zorluk"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.zorluk}
                    onChange={(e) => sarkiBilgiChanged(t => t.zorluk = e)} />
            </FormControl>
            <FormControl style={{ width: 195, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Eşlik"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.eslik}
                    onChange={(e) => sarkiBilgiChanged(t => t.eslik = e)} />
            </FormControl>
            <FormControl style={{ width: 395, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Ek Yorumlar"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.ekYorum}
                    multiline
                    showStats
                    rows={2}
                    onChange={(e) => sarkiBilgiChanged(t => t.ekYorum = e)} />
            </FormControl>
        </Columns>
        <Typography style={{ fontWeight: "bold", alignSelf: "center", marginLeft: 5, marginTop: 10 }}>Şarkı Açıklamaları</Typography>
        <Columns wrap>
            <FormControl style={{ width: 395, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Türkçe Kısa Açıklama"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.kisaAciklama}
                    multiline
                    showStats
                    rows={2}
                    onChange={(e) => sarkiBilgiChanged(t => t.kisaAciklama = e)} />
            </FormControl>
            <FormControl style={{ width: 595, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="Türkçe Uzun Açıklama"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.uzunAciklama}
                    multiline
                    showStats
                    rows={2}
                    onChange={(e) => sarkiBilgiChanged(t => t.uzunAciklama = e)} />
            </FormControl>
            <FormControl style={{ width: 395, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="İngilizce Kısa Açıklama"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.kisaIngilizceAciklama}
                    multiline
                    showStats
                    rows={2}
                    onChange={(e) => sarkiBilgiChanged(t => t.kisaIngilizceAciklama = e)} />
            </FormControl>
            <FormControl style={{ width: 595, marginRight: 5, marginTop: 15 }}>
                <CsmTextField
                    label="İngilizce Uzun Açıklama"
                    disabled={!props.guncellenebilir}
                    value={sarkiModel.uzunIngilizceAciklama}
                    multiline
                    showStats
                    rows={2}
                    onChange={(e) => sarkiBilgiChanged(t => t.uzunIngilizceAciklama = e)} />
            </FormControl>
        </Columns>
        <Columns style={{ marginTop: 10 }}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" disabled={!props.guncellenebilir} onClick={() => sarkiKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
        ;

}

export default SarkiBilgileriPopupEditor;