// AutoGenerated
import { EnmCinsiyet } from "../enums/EnmCinsiyet"

export class KullaniciModel {
    kullaniciAdi!: string;
    cinsiyet!: EnmCinsiyet | null;
    ad!: string;
    soyad!: string;
    dogumTarihi!: Date | null;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}