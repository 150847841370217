// AutoGenerated
import { TatilTarihModel } from "../businessModels/TatilTarihModel"

export class TatilModel {
    isim!: string;
    aktif!: boolean;
    sabitTarihTekrarEdiyorMu!: boolean;
    tarihler!: TatilTarihModel[];
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}