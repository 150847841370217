import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import StringHelper from "../../library/helpers/StringHelper";
import CsmFreeAutoCompleteField, { ICsmFreeAutoCompleteFieldProps } from "../../library/components/mui/CsmFreeAutoCompleteField";

export type IVatanDonemFieldProps = Omit<ICsmFreeAutoCompleteFieldProps, "sourceList"> & {
    genelDurumHelper: GenelDurumHelper,
}

function VatanSanatciField(props: IVatanDonemFieldProps) {
    const { genelDurumHelper, ...rest } = props;

    const b = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => StringHelper.isNotEmpty(e.besteci)).select(e => e.besteci!).distinct();
    const d = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => StringHelper.isNotEmpty(e.duzenleme)).select(e => e.duzenleme!).distinct();
    const s = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => StringHelper.isNotEmpty(e.sozYazari)).select(e => e.sozYazari!).distinct();

    const sourceList = b.union(d).union(s).orderByTurkish(e=>e);
    return <CsmFreeAutoCompleteField
        sourceList={sourceList}
        {...rest}
    />
}

export default VatanSanatciField;