// AutoGenerated
import { EnmCinsiyet } from "../enums/EnmCinsiyet"
import { EnmKatilimBildirmeDurum } from "../enums/EnmKatilimBildirmeDurum"
import { SecmeBasvuruSonucModel } from "../businessModels/SecmeBasvuruSonucModel"

export class SecmeBasvuruModel {
    sezonModelId!: string;
    eposta!: string;
    ad!: string;
    soyad!: string;
    dogumYili!: number | null;
    cinsiyet!: EnmCinsiyet;
    telefon!: string | null;
    meslek!: string | null;
    egitimDurumu!: string | null;
    yasadigiIl!: string | null;
    bilinenSesGrubu!: string | null;
    koroTecrubesi!: string | null;
    resimIcerikId!: string | null;
    secmeTarihi!: Date | null;
    katilimBildirmeDurum!: EnmKatilimBildirmeDurum | null;
    katilmamaGerekce!: string | null;
    secmeNotu!: string | null;
    sonucModel!: SecmeBasvuruSonucModel | null;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}