/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';
import { CSSProperties } from "react";
import { TimePicker } from "@mui/x-date-pickers";

interface ICsmTimePickerProps {
    label: string,
    value: Date | null,
    onChange: (value: Date) => void,
    error?: boolean,
    helperText?: string,
    style?: CSSProperties,
    disabled?: boolean,
}

function CsmTimePicker(props: ICsmTimePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <div style={props.style}>
                <TimePicker
                    disabled={props.disabled}
                    label={props.label}
                    value={props.value}
                    onChange={(e) => props.onChange(new Date(e as any))}
                    renderInput={(params) =>
                        <TextField {...params}
                            error={props.error}
                            helperText={props.helperText} />}
                />
            </div>
        </LocalizationProvider>
    )
}

export default CsmTimePicker;