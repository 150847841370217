/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmGenericTwoBoxListMultiSelectField from "../../../library/components/mui/CsmGenericTwoBoxListMultiSelectField";
import { SezonAdaySarkiGuncelleRequestModel } from "../../../models/requestModels/SezonAdaySarkiGuncelleRequestModel";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import StringHelper from "../../../library/helpers/StringHelper";

interface IAdaySarkiListPopupEditorProps {
    adaySarkiList: SarkiModel[],
    sezonId: string,
    closer: (model: string[] | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function AdaySarkiListPopupEditor(props: IAdaySarkiListPopupEditorProps) {
    const { sezonId, closer, genelDurumHelper } = props;

    const [filter, setFilter] = useState<string>("");
    const [seciliIdList, setSeciliIdList] = useState<string[]>(props.adaySarkiList.select(e => e.id));

    const kaydet = async () => {
        const webResponse = await genelDurumHelper.requestHelper.Sarki.SezonAdaySarkiListGuncelle(new SezonAdaySarkiGuncelleRequestModel(sezonId, seciliIdList), "Aday Şarkı Listesi güncelleniyor");
        if (!webResponse.successfull)
            return;

        closer(seciliIdList);
    }

    const filterToUse = StringHelper.Turkish.ConvertToEnglishLowerCase(filter);
    const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.orderByTurkish(e => e.isim).where(e => StringHelper.Turkish.ConvertToEnglishLowerCase(e.isim).indexOf(filterToUse) >= 0);
    const degisiklikVar = seciliIdList.length !== props.adaySarkiList.length || props.adaySarkiList.any(e => seciliIdList.contains(e.id) === false);

    return (
        <Rows>
            <CsmTextField style={{ marginTop: "5px" }} label="Filtre" eventOnKeyPress size="small" value={filter} onChange={e => setFilter(e)} />
            <Rows flex style={{ overflowY: "scroll", maxHeight: "500px", marginTop: "10px" }}>
                <CsmGenericTwoBoxListMultiSelectField
                    value={seciliIdList}
                    label={"Şarkılar"}
                    itemLabel={"Şarkı"}
                    list={tumSarkilar}
                    labelGetter={e => e.isim}
                    valueGetter={e => e.id}
                    defaultOrderFunc={(e: string[]) => tumSarkilar.where(t => e.contains(t.id)).orderByTurkish(t => t.isim).select(t => t.id)}
                    onChange={e => setSeciliIdList(e)}
                    labels={{
                        secilmeyenler: genelDurumHelper.translate("Seçilmeyenler"),
                        secilenler: genelDurumHelper.translate("Seçilenler"),
                        listeyeEklemekIcinTıklayabilirsiniz: genelDurumHelper.translate("Listeye eklemek için tıklayabilirsiniz"),
                        siralarınıDegistirmekIcinTasiyabilirsinizListedenCikartmakIcinTiklayabilirsiniz: genelDurumHelper.translate("Sıralarını değiştirmek için taşıyabilirsiniz. Listeden çıkartmak için tıklayabilirsiniz"),
                    }}                     />
            </Rows>
            <Columns style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" disabled={!degisiklikVar} onClick={() => kaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );
}

export default AdaySarkiListPopupEditor;