// AutoGenerated
import { EnmKatilimBildirmeDurum } from "../enums/EnmKatilimBildirmeDurum"

export class HesapCalismaKatilimDurumGuncelleRequestModel {
    constructor(calismaId: string, katilimDurum: EnmKatilimBildirmeDurum, gerekce: string | null) {
        this.calismaId = calismaId;
        this.katilimDurum = katilimDurum;
        this.gerekce = gerekce;
    }

    calismaId: string;
    katilimDurum: EnmKatilimBildirmeDurum;
    gerekce: string | null;
}