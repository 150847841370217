/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import Columns from "../../../library/components/layout/Columns";
import { KullaniciModel } from "../../../models/businessModels/KullaniciModel";
import { KullaniciProfilSifreGuncelleRequestModel } from "../../../models/requestModels/KullaniciProfilSifreGuncelleRequestModel";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import CsmCstField from "../../../library/components/mui/CsmCstField";
import CsmPasswordField from "../../../library/components/mui/CsmPasswordField";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";

function GenelKullaniciYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <GenelKullaniciYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function GenelKullaniciYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [aramaKriter, setAramaKriter] = useState<string>("");
  const [bulunanKullanici, setBulunanKullanici] = useState<KullaniciModel | null>(null);
  const [model, setModel] = useState<KullaniciProfilSifreGuncelleRequestModel | null>(null);

  const kullaniciGuncelle = async (): Promise<void> => {
    const webResponse = await genelDurumHelper.requestHelper.Koro.KullaniciProfilSifreGuncelle(model!, "Kullanıcı profil şifresi güncelleniyor");
    if (!webResponse.successfull)
      return;

    if (webResponse == null)
      return;

    AlertHelper.ShowMessage("Dikat", "İşlem başarılır (ekranı tazele)");
  }

  function filterChanged(e: string): void {
    setAramaKriter(e);
    setBulunanKullanici(null);
    setModel(null);
  }

  const updateModel = (action: (model: KullaniciProfilSifreGuncelleRequestModel) => void) => {
    const result = JsonHelper.deepCopy(model!);
    action(result);
    setModel(result);
  }


  async function kullaniciAra() {
    const webResponse = await genelDurumHelper.requestHelper.Koro.KullaniciAra(aramaKriter, "Kullanıcı aranıyor");
    if (!webResponse.successfull)
      return;

    if (!webResponse.data) {
      setBulunanKullanici(null);
      setModel(null);
      AlertHelper.ShowMessage("Dikkat", "Kullanıcı Bulunamadı");
      return;
    }

    setBulunanKullanici(webResponse.data);
    setModel(new KullaniciProfilSifreGuncelleRequestModel(webResponse.data.ad, webResponse.data.soyad, webResponse.data.dogumTarihi, webResponse.data.cinsiyet!, webResponse.data.kullaniciAdi, ""));
  }

  const adHataVar = model != null && !model.ad;
  const soyadHataVar = model != null && !model.soyad;
  const dogumGunuHataVar = model != null && !model.dogumTarihi;
  const cinstiyetHataVar = model != null && !model.cinsiyet;

  const kullaniciBilgileriGuncellenebilir = bulunanKullanici != null;

  const kaydedilebilir = model != null && !adHataVar && !soyadHataVar && !dogumGunuHataVar && !cinstiyetHataVar;

  return (
    <MainLayout
      location={mainScreenLocations.genelKullaniciYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>

      <Rows width={"300px"} style={{ margin: "10px" }}>
        <Columns>
          <CsmTextField
            style={{ marginBottom: "10px", width: "250px" }}
            label="E-Posta Adresi Filtresi"
            onChange={e => filterChanged(e)}
            value={aramaKriter}
            eventOnKeyPress
          />
          <IconButton disabled={aramaKriter === ""} onClick={() => kullaniciAra()}>
            {appIcons.Genel.search}
          </IconButton>
        </Columns>
        <Rows>
          <CsmTextField
            style={{ marginBottom: "10px" }}
            fullWidth
            disabled={!kullaniciBilgileriGuncellenebilir}
            label="Ad"
            error={adHataVar}
            value={StringHelper.nullToEmpty(model?.ad)}
            onChange={e => updateModel(t => t.ad = e)}
            eventOnKeyPress />
          <CsmTextField
            style={{ marginBottom: "10px" }}
            fullWidth
            disabled={!kullaniciBilgileriGuncellenebilir}
            label="Soyad"
            error={soyadHataVar}
            value={StringHelper.nullToEmpty(model?.soyad)}
            onChange={e => updateModel(t => t.soyad = e)}
            eventOnKeyPress />
          <CsmDatePicker
            style={{ marginBottom: "10px" }}
            fullWidth
            disabled={!kullaniciBilgileriGuncellenebilir}
            label="Doğum Tarihi"
            error={dogumGunuHataVar}
            value={model?.dogumTarihi ?? new Date(1970, 1, 1)}
            onChange={e => updateModel(t => t.dogumTarihi = e)} />
          <CsmCstField
            style={{ marginBottom: "10px" }}
            disabled={!kullaniciBilgileriGuncellenebilir}
            label="Cinsiyet"
            error={cinstiyetHataVar}
            value={model?.cinsiyet ?? EnmCinsiyet.Erkek}
            cstType={EnmCinsiyet}
            onChange={e => updateModel(t => t.cinsiyet = e as any)} />
          <CsmPasswordField
            style={{ marginBottom: "10px" }}
            disabled={!kullaniciBilgileriGuncellenebilir}
            label="Yeni Şifre"
            fullWidth
            value={StringHelper.nullToEmpty(model?.sifre)}
            onChange={e => updateModel(t => t.sifre = e)} />

          <Button color="success" variant="contained" disabled={!kaydedilebilir} onClick={() => kullaniciGuncelle()} style={{ flex: 1 }}>
            {appIcons.Popup.tamam}
            {genelDurumHelper.translate("Kaydet")}</Button>
        </Rows>
      </Rows>
    </MainLayout>
  );
}

export default GenelKullaniciYonetimScreen;

