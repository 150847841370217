/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction, i18n } from "i18next";
import allkorocanTranslations, { transMatrixKeyType } from "./korocanTranslations";

export type KorocanTranslateFuncType = (a: transMatrixKeyType, v?: { [key: string]: string }) => string;

const TranslationHelper = {
    TransFunc: (t: TFunction<"translation", undefined, "translation">): KorocanTranslateFuncType =>
        (a: transMatrixKeyType, v?: { [key: string]: string }) => ((a as any) === "" ? "" : (v ? (t((a as any), v!) ?? "") : (t(a, { defaultValue: (a as any) + "-XXXXXX" }) ?? ""))),

    getCurrentLanguageItem: (i18n: i18n) => {
        const key = i18n.resolvedLanguage ?? "tr";
        return TranslationHelper.KorocanLanguages.singleOrDefault(e => e.lang === key) ?? TranslationHelper.KorocanLanguages.single(e => e.lang === "tr");
    },

    KorocanLanguages: Object.keys(allkorocanTranslations).filter(e => e === "en").length > 0 ? [
        { title: "Türkçe", img: "tr.png", lang: "tr" },
        { title: "English", img: "en.png", lang: "en" },
    ] : [
        { title: "Türkçe", img: "tr.png", lang: "tr" },
    ],
}

export default TranslationHelper;