/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { FilledInputProps, IconButton, InputAdornment, InputProps, OutlinedInputProps, TextField, TextFieldProps } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';
import { CSSProperties } from "react";

interface ICsmDatePickerProps {
    label: string,
    value: Date | null,
    onChange: (value: Date) => void,
    error?: boolean,
    helperText?: string,
    style?: CSSProperties,
    disabled?: boolean,
    fullWidth?: boolean,
    canClean?: boolean,
}

function CsmDatePicker(props: ICsmDatePickerProps) {

    const endAdorment = !(props.canClean && props.value != null) ? null :
        <InputAdornment sx={{ marginRight: "10px" }} position="end">
            <IconButton onClick={() => props.onChange(null as any)}>
                <ClearIcon />
            </IconButton>
        </InputAdornment>

    function calculateInputPropsFromParams(newEndAdorment: JSX.Element | null, params: TextFieldProps): Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps> | undefined {
        if (newEndAdorment == null)
            return params.InputProps;

        if (!params.InputProps)
            return { endAdornment: newEndAdorment };

        const { endAdornment: eskiEndAdornment, ...rest } = params.InputProps;

        if (endAdorment == null)
            return { endAdornment: newEndAdorment, ...rest };
        else
            return { endAdornment: [newEndAdorment, eskiEndAdornment], ...rest };
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <div style={{ ...props.style }}>
                <DatePicker
                    disabled={props.disabled}
                    label={props.label}
                    value={props.value}
                    onChange={(e) => props.onChange(new Date(e as any))}
                    renderInput={(params) =>
                        <TextField {...params}
                            InputProps={calculateInputPropsFromParams(endAdorment, params)}
                            fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
                            error={props.error}
                            helperText={props.helperText} />}
                />
            </div>
        </LocalizationProvider>
    )
}

export default CsmDatePicker;