// AutoGenerated
import { KonserSarkiModel } from "../businessModels/KonserSarkiModel"

export class KonserModel {
    isim!: string;
    resimIcerikId!: string | null;
    yer!: string;
    sehirUlke!: string;
    ortakKoro!: string | null;
    tarih!: Date;
    sezonModelId!: string;
    kadroModelId!: string;
    konserSarkiModelList!: KonserSarkiModel[];
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}