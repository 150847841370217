import { CSSProperties, useEffect, useState } from "react";
import IcerikHelper from "../../helpers/IcerikHelper";
import StorageHelper from "../../helpers/StorageHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

interface IKorocanImageProps {
    title: string,
    icerikId: string | null,
    style?: CSSProperties,
    saveToStorage?: "Normal" | "None" | "FullSize",
    genelDurumHelper: GenelDurumHelper,
}

function KorocanImage(props: IKorocanImageProps) {
    const { title, icerikId, genelDurumHelper, style, saveToStorage } = props;
    
    const [lastDownloadedIcerikId, setLastDownloadedIcerikId] = useState<string | null>(null);
    const [lastDownloadedContentWithUrl, setLastDownloadedContentWithUrl] = useState<string | null>(null);

    const currentState = determineCurrentState(icerikId);

    useEffect(() => {
        veriGetir();
    }, [props.icerikId]);

    function determineCurrentState(icerikId: string | null): { icerik: string | null, needsDownload: boolean } {
        if (icerikId == null)
            return { icerik: "", needsDownload: false };
        else {
            if (icerikId === lastDownloadedIcerikId)
                return { icerik: lastDownloadedContentWithUrl, needsDownload: false };

            else if (StorageHelper.FileExists(icerikId))
                return { icerik: StorageHelper.LoadFile(icerikId) ?? "", needsDownload: false };

            else
                return { icerik: null, needsDownload: true };
        }
    }

    const veriGetir = async () => {
        if (!currentState.needsDownload)
            return;

        // icerik id ile verilmiş, download edicez
        let icerik = await IcerikHelper.IcerikGetirDirekt(icerikId!, genelDurumHelper);

        if (icerik)
            icerik = `data:image/png;base64,${icerik}`;

        if (!!icerik && !!saveToStorage && saveToStorage !== "None") {
            if (StorageHelper.HasFreeSpace()) {
                if (saveToStorage === "FullSize")
                    resizedataURL(icerik, 0, icerikId!);
                else
                    resizedataURL(icerik, 100, icerikId!);
            }
        }

        setLastDownloadedIcerikId(icerikId);
        setLastDownloadedContentWithUrl(icerik!);
    }

    if (currentState.needsDownload)
        return <img alt={title} title={title} src='/loading.gif' style={style} />
    else if (currentState.icerik)
        return <img alt={title} title={title} src={`${currentState.icerik!}`} style={style} />        
    else
        return <img alt={title} title={title} src='/noimage.png' style={style} />
}

// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
function resizedataURL(baseUri: string, wantedWidth: number, icerikId: string) {
    // We create an image to receive the Data URI
    const img = document.createElement('img');

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
        // We create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const ratio = img.height / img.width;
        if (wantedWidth === 0)
            wantedWidth = img.width;

        const wantedHeight = Math.floor(wantedWidth * ratio);
        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx!.drawImage(img, 0, 0, wantedWidth, wantedHeight);

        const dataURI = canvas.toDataURL();
        StorageHelper.SaveFileBase64(icerikId, dataURI);
        /////////////////////////////////////////
        // Use and treat your Data URI here !! //
        /////////////////////////////////////////
    };

    // We put the Data URI in the image's src attribute
    img.src = baseUri;
}
// Use it like that : resizedataURL('yourDataURIHere', 50, 50);

/*
    if (loadingCompleted) {
        if (content)
            return <img alt={title} title={title} src={`data:image/png;base64,${content!}`} style={style} />;
        else
            return <img alt={title} title={title} src='/noimage.png' style={style} />;
    }
    else
        return <img alt={title} title={title} src='/loading.gif' style={style} />;
    */


export default KorocanImage;