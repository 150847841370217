import { createTheme } from "@mui/material";
import EnvironmentHelper from "../helpers/EnvironmentHelper";

const theme = createTheme({
  palette: {
    background: {
      default: EnvironmentHelper.isTest() ? "#f1f2da" : "#fafafa",
      paper: "#ccf0ea",
    },
    primary: {
      main: EnvironmentHelper.isTest() ? '#b6bc47' : '#004d40',
    },
  },
  components: {
    // // MuiToggleButtonGroup: {
    // // },
    // // MuiToggleButton: {
    // //   styleOverrides: {        
    // //     secondary: {
    // //       backgroundColor: "red",
    // //     },
    // //     selected: {        
    // //       color: "red",
    // //       backgroundColor: "red",
    // //     },
    // //   },
    // // },   
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "58px",
          minHeight: "58px !important",
          maxHeight: "58px"
        },
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: 0,
          marginBottom: 0,
        },
      }
    },
  }
});

// MuiAccordionSummary-content Mui-expanded MuiAccordionSummary-contentGutters css-o4b71y-MuiAccordionSummary-content

export const narrowTheme = createTheme({
  components: {
    // MuiTableCell: {
    //   styleOverrides: {
    //     head: {
    //       paddingLeft: "5px",
    //       paddingRight: "5px",
    //       fontSize: "12px",
    //       fontWeight: "bold",
    //     },
    //     sizeMedium: {
    //       fontSize: "10px",
    //       overflow: "hidden",
    //       height: "12px",
    //       wordWrap: "normal"
    //     },
    //     sizeSmall: {
    //       fontSize: "10px",
    //       overflow: "hidden",
    //       height: "12px",
    //       wordWrap: "normal"
    //     },
    //     root: {
    //       paddingLeft: "5px",
    //       paddingRight: "5px",
    //       fontSize: "10px",
    //       overflow: "hidden",
    //       height: "12px",
    //       wordWrap: "normal"
    //     }
    //   }
    // },
    MuiTextField: {
      defaultProps: {
        margin: "none",
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(14px, -8px) scale(0.75)",
          marginLeft: "0px",
        },
        root: {
          transform: "translate(4px, 6px) scale(1)",
          marginLeft: "5px",
        }
      }
    },
    MuiInputAdornment:
    {
      styleOverrides: {
        root: {
          marginLeft: "5px",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: "28px",
          paddingTop: "5px",
          paddingBottom: "1px",
          paddingLeft: "5px",
          paddingRight: "5px",

        },
        root: {
          // padding: "1px",
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingTop: "5px !important",
          paddingBottom: "1px !important",
        },
        root: {
          padding: "0px",
        },
        inputRoot: {
          padding: "0px",
        },
      },
    },
  },
  spacing: [0, 1, 2, 4, 8, 16],
});

export default theme;