/* eslint-disable @typescript-eslint/no-unused-vars */
import { ICustomChoicesDialogProps } from "../components/dialogs/CustomChoicesDialog";
import { ICustomContentDialogProps } from "../components/dialogs/CustomContentDialog";
import { ICustomTextInputDialogProps } from "../components/dialogs/CustomTextInputDialog";
import { ICustomYesNoDialogProps } from "../components/dialogs/CustomYesNoDialog";
import { IClosableCustomContentDialogProps } from "../components/dialogs/ClosableCustomContentDialog";
import { ICustomAlertDialogProps } from "../components/dialogs/CustomAlertDialog";

export default class AlertHelper {
    static setCustomYesNoDialogPropsMethod: React.Dispatch<React.SetStateAction<ICustomYesNoDialogProps>>;
    static setCustomChoicesDialogPropsMethod: React.Dispatch<React.SetStateAction<ICustomChoicesDialogProps>>;
    static setCustomTextInputDialogPropsMethod: React.Dispatch<React.SetStateAction<ICustomTextInputDialogProps>>;
    static setCustomAlertDialogPropsMethod: React.Dispatch<React.SetStateAction<ICustomAlertDialogProps>>;
    static setCustomContentDialogPropsMethod: React.Dispatch<React.SetStateAction<ICustomContentDialogProps>>;
    static setCustomContentDialogPropsMethod2: React.Dispatch<React.SetStateAction<ICustomContentDialogProps>>;
    static setClosableContentDialogPropsMethod: React.Dispatch<React.SetStateAction<IClosableCustomContentDialogProps>>;
    static setClosableContentDialogPropsMethod2: React.Dispatch<React.SetStateAction<IClosableCustomContentDialogProps>>;

    static ShowMessage(title: string, message: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            AlertHelper.setCustomAlertDialogPropsMethod({
                contentMessage: message,
                titleMessage: title,
                onClose: () => { closeAlertDialog(); resolve(true); },
                open: true
            })
        });
    }

    static ShowClosableContentFullHeight<T>(title: string, generator: (closer: (success: T | null) => void) => JSX.Element): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            const content = generator((success) => { closeClosableContentDiaog(); resolve(success); });
            AlertHelper.setClosableContentDialogPropsMethod({
                content: content,
                titleMessage: title,
                extraFullHeight: true,
                onClose: () => { closeClosableContentDiaog(); resolve(null); },
                open: true,
            })
        });
    }

    static ShowClosableContent<T>(title: string, generator: (closer: (success: T | null) => void) => JSX.Element): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            const content = generator((success) => { closeClosableContentDiaog(); resolve(success); });
            AlertHelper.setClosableContentDialogPropsMethod({
                content: content,
                titleMessage: title,
                extraFullHeight: false,
                onClose: () => { closeClosableContentDiaog(); resolve(null); },
                open: true,
            })
        });
    }

    static ShowClosableContent2FullHeight<T>(title: string, generator: (closer: (success: T | null) => void) => JSX.Element): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            const content = generator((success) => { closeClosableContentDiaog2(); resolve(success); });
            AlertHelper.setClosableContentDialogPropsMethod2({
                content: content,
                titleMessage: title,
                extraFullHeight: true,
                onClose: () => { closeClosableContentDiaog2(); resolve(null); },
                open: true,
            })
        });
    }

    static ShowClosableContent2<T>(title: string, generator: (closer: (success: T | null) => void) => JSX.Element): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            const content = generator((success) => { closeClosableContentDiaog2(); resolve(success); });
            AlertHelper.setClosableContentDialogPropsMethod2({
                content: content,
                titleMessage: title,
                extraFullHeight: false,
                onClose: () => { closeClosableContentDiaog2(); resolve(null); },
                open: true
            })
        });
    }

    static ShowContent(title: string, content: JSX.Element): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            AlertHelper.setCustomContentDialogPropsMethod({
                content: content,
                titleMessage: title,
                onClose: () => { closeContentDialog(); resolve(true); },
                open: true,
                useFlex: false,
            })
        });
    }

    static ShowContentWithFlex(title: string, content: JSX.Element): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            AlertHelper.setCustomContentDialogPropsMethod({
                content: content,
                titleMessage: title,
                onClose: () => { closeContentDialog(); resolve(true); },
                open: true,
                useFlex: true
            })
        });
    }

    static ShowContent2(title: string, content: JSX.Element): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            AlertHelper.setCustomContentDialogPropsMethod2({
                content: content,
                titleMessage: title,
                onClose: () => { closeContentDialog2(); resolve(true); },
                open: true,
                useFlex: false
            })
        });
    }

    static Confirm(title: string, message: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            AlertHelper.setCustomYesNoDialogPropsMethod({
                contentMessage: message,
                titleMessage: title,
                onClose: () => { closeYesNoDialog(); resolve(false); },
                onYesClick: () => { closeYesNoDialog(); resolve(true); },
                open: true
            })
        });
    }

    static ConfirmWithChoices(title: string, message: string, choices: string[]): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            AlertHelper.setCustomChoicesDialogPropsMethod({
                contentMessage: message,
                titleMessage: title,
                choices: choices,
                onClose: () => { closeChoicesDialog(); resolve(null); },
                onChoiceClick: (e) => { closeChoicesDialog(); resolve(e); },
                open: true
            })
        });
    }

    static ConfirmWithTextInput(title: string, message: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            AlertHelper.setCustomTextInputDialogPropsMethod({
                contentMessage: message,
                titleMessage: title,
                onClose: () => { closeTextInputDialog(); resolve(null); },
                onYesClick: (e) => { closeTextInputDialog(); resolve(e); },
                isMultiLine: false,
                open: true
            })
        });
    }

    static ConfirmWithTextInputMultiLine(title: string, message: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            AlertHelper.setCustomTextInputDialogPropsMethod({
                contentMessage: message,
                titleMessage: title,
                onClose: () => { closeTextInputDialog(); resolve(null); },
                onYesClick: (e) => { closeTextInputDialog(); resolve(e); },
                isMultiLine: true,
                open: true
            })
        });
    }
}

function closeAlertDialog() {
    AlertHelper.setCustomAlertDialogPropsMethod({
        contentMessage: "",
        titleMessage: "",
        onClose: null,
        open: false
    })
}

function closeYesNoDialog() {
    AlertHelper.setCustomYesNoDialogPropsMethod({
        contentMessage: "",
        titleMessage: "",
        onClose: null,
        onYesClick: null,
        open: false
    })
}

function closeChoicesDialog() {
    AlertHelper.setCustomChoicesDialogPropsMethod({
        contentMessage: "",
        titleMessage: "",
        choices: [],
        onClose: null,
        onChoiceClick: null,
        open: false
    })
}

function closeTextInputDialog() {
    AlertHelper.setCustomTextInputDialogPropsMethod({
        contentMessage: "",
        titleMessage: "",
        onClose: null,
        onYesClick: null,
        open: false,
        isMultiLine: false,
    })
}

function closeContentDialog() {
    AlertHelper.setCustomContentDialogPropsMethod({
        content: null,
        titleMessage: "",
        onClose: null,
        open: false,
        useFlex: false,
    })
}

function closeContentDialog2() {
    AlertHelper.setCustomContentDialogPropsMethod2({
        content: null,
        titleMessage: "",
        onClose: null,
        open: false,
        useFlex: false,
    })
}

function closeClosableContentDiaog() {
    AlertHelper.setClosableContentDialogPropsMethod({
        content: null,
        titleMessage: "",
        extraFullHeight: false,
        onClose: null,
        open: false
    })
}
function closeClosableContentDiaog2() {
    AlertHelper.setClosableContentDialogPropsMethod2({
        content: null,
        titleMessage: "",
        extraFullHeight: false,
        onClose: null,
        open: false
    })
}