// AutoGenerated
import { EnmCinsiyet } from "../enums/EnmCinsiyet"

export class KullaniciProfilSifreGuncelleRequestModel {
    constructor(ad: string | null, soyad: string | null, dogumTarihi: Date | null, cinsiyet: EnmCinsiyet, kullaniciAdi: string | null, sifre: string | null) {
        this.ad = ad;
        this.soyad = soyad;
        this.dogumTarihi = dogumTarihi;
        this.cinsiyet = cinsiyet;
        this.kullaniciAdi = kullaniciAdi;
        this.sifre = sifre;
    }

    ad: string | null;
    soyad: string | null;
    dogumTarihi: Date | null;
    cinsiyet: EnmCinsiyet;
    kullaniciAdi: string | null;
    sifre: string | null;
}