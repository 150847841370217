// AutoGenerated
export class SarkiZorlukModel {
    sarkiModelId!: string;
    koroyaUygunluk!: boolean | null;
    armoniTon!: number | null;
    melodi!: number | null;
    sesSiniri!: number | null;
    nuanslar!: number | null;
    duygu!: number | null;
    nefesTeknik!: number | null;
    yorumlar!: string | null;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}