import { useState, useRef, useEffect } from "react";


export const useObserveElementWidthGroup = <T extends HTMLElement, Z extends string>(breakpoints: { [point: number]: Z }) => {
    const ref = useRef<T>(null);
    const [sizeName, setSizeName] = useState<Z>(findBreakpointFromWidth(breakpoints, ref?.current?.clientWidth ?? 0));

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const newSizeName = findBreakpointFromWidth(breakpoints, entries[0].contentRect.width);
            setSizeName(newSizeName);
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            ref.current && observer.unobserve(ref.current);
        };
    }, []);

    return {
        sizeName,
        ref
    };
};

function findBreakpointFromWidth<Z extends string>(breakpoints: { [point: number]: Z; }, width: number): Z {
    const keys = Object.keys(breakpoints).select(e => parseInt(e));

    for (let i = 0; i < keys.length; i++)
        if (width < keys[i])
            return breakpoints[keys[i]];

    return breakpoints[keys[keys.length - 1]];
}
